import React, { useRef, useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import {
  createcentralstore,
  updatecentralstore,
  getallcentralStore
} from '../../apollo'
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar
} from '@mui/material'
import useStyles from './styles'
import useGlobalStyles from '../../utils/globalStyles'

const GET_CENTRAL_STORE = gql`
  ${getallcentralStore}
`
const CREATE_CENTRAL_STORE = gql`
  ${createcentralstore}
`
const EDIT_GET_CENTRAL_STORE = gql`
  ${updatecentralstore}
`

function Vendor(props) {
  const { onClose } = props
  const formRef = useRef()
  const mutation = props.vendor ? EDIT_GET_CENTRAL_STORE : CREATE_CENTRAL_STORE
  let [error, errorSetter] = useState('')
  // const [error, errorSetter] = useState('');
  const [success, successSetter] = useState('')
  const [openSnackk, setopenSnackk] = useState(false)
  const { t } = props
  const [formData, setFormData] = useState({
    id: props.vendor ? props.vendor._id : '',
    name: props.vendor ? props.vendor.name : '',
    address: props.vendor ? props.vendor.address : '',
    city: props.vendor ? props.vendor.city : '',
    code: props.vendor ? props.vendor.code : '',
    location: {
      latitude:
        props.vendor && props.vendor.location.coordinates[0]
          ? props.vendor.location.coordinates[0]
          : '',
      longitude:
        props.vendor && props.vendor.location.coordinates[1]
          ? props.vendor.location.coordinates[1]
          : ''
    }
  })

  const handleInputChange = e => {
    const { name, value } = e.target
    if (name === 'latitude' || name === 'longitude') {
      // Handle latitude and longitude separately
      const location = { ...formData.location, [name]: value }
      setFormData({ ...formData, location })
    } else {
      // Update other form fields
      setFormData({ ...formData, [name]: value })
    }
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' })
    }
  }

  const onCompleted = data => {
    if (!props.vendor) clearFields()
    const message = props.vendor
      ? t('Store Updated Successfully')
      : t('Store Added Successfully')
    errorSetter('')
    successSetter(message)
    setopenSnackk(true)
    props.refetch()
    setTimeout(hideAlert, 1000)
  }

  /* const onError = ({ graphQLErrors, networkError }) => {
    successSetter('')
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      // errorSetter(error);
      errorSetter(error)
    } else if (networkError) errorSetter(networkError.result.errors[0].message)
    else errorSetter('Something went wrong!')
    setTimeout(hideAlert, 3000)
    setopenSnackk(true)
    props.refetch()
  }*/
  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('')

    const errorMessage = graphQLErrors
      ? graphQLErrors.some(err => err.message.includes('duplicate key error'))
        ? `Store Code Conflict: '${formData.code}' is already in use. Please enter a unique code.`
        : graphQLErrors[0].message
      : networkError
      ? networkError.result.errors[0].message
      : 'Something went wrong!'

    errorSetter(errorMessage)
    setopenSnackk(true)
    setTimeout(hideAlert, 1000)
  }
  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_CENTRAL_STORE }],
    onError,
    onCompleted
  })

  const clearFields = () => {
    formRef.current.reset()
    setFormErrors({})
    setFormData({
      name: props.vendor ? props.vendor.name : '',
      address: props.vendor ? props.vendor.address : '',
      city: props.vendor ? props.vendor.city : '',
      code: props.vendor ? props.vendor.code : '',
      location: {
        latitude:
          props.vendor && props.vendor.location
            ? props.vendor.location.latitude
            : '',
        longitude:
          props.vendor && props.vendor.location
            ? props.vendor.location.longitude
            : ''
      }
    })
  }

  const hideAlert = () => {
    errorSetter('')
    successSetter('')
    setopenSnackk(false)
  }

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const inputTextStyle = { color: 'black' }
  const [formErrors, setFormErrors] = useState({})
  const validateFormData = () => {
    let errors = {}
    if (!formData.address) errors.address = 'Store Address is required'
    if (!formData.name) errors.name = 'Store Name is required'
    if (!formData.city) errors.city = 'City Name is required'
    if (!formData.code) errors.code = 'Pin Code is required'
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }
  const onSubmit = e => {
    e.preventDefault()
    if (!validateFormData()) {
      return
    }
    // if (onSubmitValidaiton() && !mutateLoading) {
    if (true) {
      !props.vendor
        ? mutate({
            variables: {
              centralStoreInput: {
                name: formData.name,
                address: formData.address,
                city: formData.city,
                code: formData.code,
                isActive: true,
                location: {
                  latitude: formData.location.latitude,
                  longitude: formData.location.longitude
                }
              }
            }
          })
        : mutate({
            variables: {
              storeId: formData.id,
              centralStoreUpdateInput: {
                name: formData.name,
                address: formData.address,
                city: formData.city,
                code: formData.code,
                isActive: true,
                location: {
                  latitude: formData.location.latitude,
                  longitude: formData.location.longitude
                }
              }
            }
          })
      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose() // Close the modal
        }
      }, 1000)
    }
  }

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography
            className={props.vendor ? classes.textWhite : classes.textWhite}>
            {props.vendor ? t('Edit Central Store') : t('Add Central Store')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            {!props.editModal ? (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label="Store Name"
                    helperText={formErrors.name}
                    error={Boolean(formErrors.name)}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="address"
                    helperText={formErrors.address}
                    error={Boolean(formErrors.address)}
                    label="Address"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="city"
                    label="City"
                    helperText={formErrors.city}
                    error={Boolean(formErrors.city)}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="code"
                    label="Store Code"
                    helperText={formErrors.code}
                    error={Boolean(formErrors.code)}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    name="latitude"
                    label="Latitude"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="longitude"
                    label="Longitude"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
              </>
            ) : (
              // Conditionally render the second form fields based on editModal prop
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    helperText={formErrors.name}
                    error={Boolean(formErrors.name)}
                    label="Store Name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="address"
                    helperText={formErrors.address}
                    error={Boolean(formErrors.address)}
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={formData.address}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="city"
                    label="City"
                    helperText={formErrors.city}
                    error={Boolean(formErrors.city)}
                    variant="outlined"
                    fullWidth
                    value={formData.city}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="code"
                    label="Store Code"
                    helperText={formErrors.code}
                    error={Boolean(formErrors.code)}
                    variant="outlined"
                    fullWidth
                    value={formData.code}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="latitude"
                    label="Latitude"
                    variant="outlined"
                    fullWidth
                    value={
                      props.vendor && props.vendor.location
                        ? formData.location.latitude
                        : ''
                    }
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="longitude"
                    label="Longitude"
                    variant="outlined"
                    fullWidth
                    value={
                      props.vendor && props.vendor.location
                        ? formData.location.longitude
                        : ''
                    }
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              className={globalClasses.dashbordBtn}
              disabled={mutateLoading}>
              {props.vendor ? t('Update') : t('Add')}
            </Button>
            <Button
              className={globalClasses.modalCancleBtn}
              onClick={() => {
                onClose()
              }}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      <Box mt={2}>
        {success && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  )
}
export default withTranslation()(Vendor)
