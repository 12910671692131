import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import useStyles from '../../components/AddUser/styles';
import useGlobalStyles from '../globalStyles';
import { getallcategories, getsubcategory } from '../../apollo';
import { gql, useQuery } from '@apollo/client';


const GET_SUBCATEGORY = gql`
  ${getsubcategory}
`;
const GET_ALLCATEGORIES = gql`
  ${getallcategories}
`;

const AllPRoductFIlter = ({ open, close, filterData, DefaultValue, handleResetFilters }) => {
  const formRef = useRef();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };
  const CustomPaperComponent = (props) => (
    <Paper {...props} style={{ background: 'white', color: 'black' }} />
  );
  const [formData, setFormData] = useState({
    lsName: (DefaultValue && DefaultValue.lsName !== undefined) ? DefaultValue.lsName : '',
    endDate: (DefaultValue && DefaultValue.endDate !== undefined) ? DefaultValue.endDate : '',
    orderStatus: (DefaultValue && DefaultValue.orderStatus !== undefined) ? DefaultValue.orderStatus : null,
    startDate: (DefaultValue && DefaultValue.startDate !== undefined) ? DefaultValue.startDate : '',
    workerEmail: (DefaultValue && DefaultValue.workerEmail !== undefined) ? DefaultValue.workerEmail : '',
    workerStatus: (DefaultValue && DefaultValue.workerStatus !== undefined) ? DefaultValue.workerStatus : null,
  });

  const { data: categories, } = useQuery(GET_ALLCATEGORIES);
  const options = categories ? categories.getCategories || [] : [];
  const { data: SubCategory, refetch: refetchSubCategory } = useQuery(GET_SUBCATEGORY, {
    variables: {
      categoryId: formData.orderStatus ? formData.orderStatus : null
    }
  });
  const optionsSubCategory = SubCategory ? SubCategory.getSubCategories || [] : [];

  useEffect(() => {
    refetchSubCategory()
  }, [formData.orderStatus])

  const [orderStatus, setorderStatus] = useState(DefaultValue ? (options && options.find(option => option._id === DefaultValue.orderStatus)) : null,);
  const [WorkerStatus, setWorkerStatus] = useState(DefaultValue ? (optionsSubCategory && optionsSubCategory.find(option => option._id === DefaultValue.workerStatus)) : null,);
  const handleSubmit = (event) => {
    event.preventDefault();
    filterData(formData);
  };
  const handleClose = () => {
    setFormData({
      lsName: '',
      endDate: '',
      orderStatus: null,
      startDate: '',
      workerEmail: '',
      workerStatus: null,
    });
    DefaultValue = {}

    close();
    handleResetFilters();
  };
  const handleReset = () => {
    setFormData({
      lsName: '',
      endDate: '',
      orderStatus: null,
      startDate: '',
      workerEmail: '',
      workerStatus: null,
    });

    setorderStatus(null);
    setWorkerStatus(null);

    // Optionally refetch data if needed or re-render components
    // refetchCentralStore(); 
  };

  return (
    <div>
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
        <form ref={formRef} onSubmit={handleSubmit}>
          <DialogTitle>
            <Box className={classes.heading}>
              <Typography className={classes.textWhite}>Filters</Typography>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="order-status-autocomplete"
                  options={options}
                  getOptionLabel={(option) => option.name}
                  value={orderStatus || null}  // Ensure value is an object with `label` and `value`
                  onChange={(event, newValue) => {
                    setorderStatus(newValue);
                    setFormData({ ...formData, orderStatus: newValue ? newValue._id : null });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Category"
                      variant="outlined"
                      inputProps={{ ...params.inputProps, style: inputTextStyle }}
                    />
                  )}
                  PaperComponent={CustomPaperComponent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="worker-status-autocomplete"
                  options={optionsSubCategory}
                  getOptionLabel={(option) => option.name}
                  value={WorkerStatus || null}  // Ensure value is an object with `label` and `value`
                  onChange={(event, newValue) => {
                    setWorkerStatus(newValue);
                    setFormData({ ...formData, workerStatus: newValue ? newValue._id : null });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Sub Category"
                      variant="outlined"
                      inputProps={{ ...params.inputProps, style: inputTextStyle }}
                    />
                  )}
                  PaperComponent={CustomPaperComponent}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" className={globalClasses.dashbordBtn}>
              Apply
            </Button>
            <Button className={globalClasses.modalResetBtn} onClick={handleReset}>
              Reset
            </Button>
            <Button className={globalClasses.modalCancleBtn} onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AllPRoductFIlter;
