import * as React from 'react'
import { Box,  useTheme } from '@mui/material'
import useStyles from './styles'
import { withTranslation } from 'react-i18next'


function AdminFooter(props) {
  const theme = useTheme();
  const classes = useStyles()

  return (
    <Box
      sx={{
        background: `linear-gradient(237.49deg, ${theme.palette.success.lightest} 0.63%, ${theme.palette.success.darkest} 85.49%)`
      }}
      className={classes.footer}>
     
    </Box>
  )
}

export default withTranslation()(AdminFooter)