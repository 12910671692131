
import { useContext } from 'react'
// import ConfigurationContext from '../context/Configuration'

const ConfigurableValues = () => {
  // const configuration = useContext(ConfigurationContext)
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  // const SERVER_URL = 'https://test01.rootsy.in'
  // const WS_SERVER_URL = 'wss://rootsy.in'
  const GOOGLE_MAPS_KEY = "AIzaSyB4TvRpmfExUIBMq-C6NZCwS3jYrrfXnlU"
  const FIREBASE_KEY = ""
  const APP_ID = ""
  const AUTH_DOMAIN = ""
  const STORAGE_BUCKET = ""
  const MSG_SENDER_ID = ""
  const MEASUREMENT_ID =""
  const PROJECT_ID = ""
  const SENTRY_DSN = ""
  const CLOUDINARY_UPLOAD_URL = ""
  const CLOUDINARY_FOOD = ""
  const VAPID_KEY =
    ''

  return {
    GOOGLE_MAPS_KEY,
    FIREBASE_KEY,
    APP_ID,
    AUTH_DOMAIN,
    STORAGE_BUCKET,
    MSG_SENDER_ID,
    MEASUREMENT_ID,
    PROJECT_ID,
    SERVER_URL,
    // WS_SERVER_URL,
    SENTRY_DSN,
    CLOUDINARY_UPLOAD_URL,
    CLOUDINARY_FOOD,
    VAPID_KEY
  }
}

export default ConfigurableValues
