import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { getcatlog, SearchCatalogs } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableSTyleFOrCatalog'
import { Icon } from '@iconify/react';
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper, Box, Switch
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import Alert from '../components/Alert'
import Catlog from '../components/Catalog/Catlog'
import SearchBar from '../components/TableHeader/SearchBar'

const GET_CATLOG = gql`
${getcatlog}
`
const GET_SEARCH_CATEGORIES = gql`
  ${SearchCatalogs}
`
const ManageCatlog = props => {
  const { t } = props;
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const golbalClasses = useGlobalStyles()
  const client = useApolloClient();
  const [searchQuery, setSearchQuery] = useState('')
  var [tabledata, setTabledata] = useState(null);
  const [relaod, setReload] = useState(false)
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(
    1
  );
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };

  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };
  const onChangeSearch = e => {
    setSearchQuery(e.target.value)
    setTimeout(() => {
      refetchSearch()
    }, 1000)

  }
  const onClickRefetch = () => {
    refetch()
  
    setReload(!relaod)
 
  }
  const variablesSearch = {
    text: searchQuery, // Assuming `text` is the variable used in your search query
    limit: perPage,
    skip: (page - 1) * perPage,
  };
  const { data: dataSearch, refetch: refetchSearch } = useQuery(
    GET_SEARCH_CATEGORIES, {
    variables: variablesSearch,

    onCompleted: (data) => {
      setTabledata(data && data.searchCatalogs.catalogs ? data.searchCatalogs.catalogs : [])
      setTotalRows(data.searchCatalogs.totalCount);
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  )
  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_CATLOG,
    {
      onCompleted: (data) => {
        setTabledata(data && data.getCatalogs ? data.getCatalogs : [])
        // setTotalRows(data.getCategories.totalCount);
      },
      onError: error => {
        const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

        if (isForbiddenError) {
          localStorage.removeItem('user-native');
          localStorage.removeItem('restaurant_id');
          client.clearStore();
          props.history.push('/auth/login');
        }
      },
    }
  )


  useEffect(() => {
    if (data) {
      var filtered =data.getCatalogs;
      setTabledata(filtered);
    }
  }, [data ,relaod]);
     


  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const parcelableStatus = row => {
    return (
      <>
        {row.parcelable}
        <Switch
          disabled
          size="small"
          defaultChecked={row.parcelable}
          onChange={_event => {
            // mutateToggle({ variables: { id: row._id } })
          }}
          style={{ color: 'black' }}
        />
      </>
    )
  }
  const columns = [


    {
      name: "Sl No.",
      cell: (row, index) => <>{index + 1}</>,
    },

    {
      name: " Name",
      sortable: true,
      selector: 'name',
      style: { cursor: 'pointer', minWidth: "300px" },
    },
    {
      name: "Discription",
      sortable: true,
      selector: 'description',
      style: { cursor: 'pointer', minWidth: "300px" },
      // cell: row => <>{row.getAllCentralStores.length}</>
    },

    {
      name: "Parcelable",
      cell: row => <>{parcelableStatus(row)}</>
    },

    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>
    }
  ]

  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(false)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  toggleModal(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
              <MenuItem
                disabled
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(true)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  // mutate({ variables: { id: row._id } });
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      {isOpen && (
        <Alert
          message={t('Deleted Successfully')}
          severity="warning"
        />
      )}

      <Container className={golbalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={openaAddVendorModal}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} />  {t('Add New Catalog')}
          </Button>
        </Box>
        {addVendorModal ? <Grid container>
          {/* <Grid item xs={12} order={{ xs: 2, lg: 1 }}> */}
          <Grid
            sx={{ display: { xs: 'none', lg: 'block' } }}
            item
            mt={5}
            ml={-2}
            order={{ xs: 1, lg: 2 }}
          >
            <Modal
              open={addVendorModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClose={closeAddVendorModal}
            >
              <Catlog onClose={closeAddVendorModal} refetch={refetch} />
            </Modal>
          </Grid>
          {/* </Grid> */}
        </Grid> : null
        }
        {errorQuery && errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
            subHeaderComponent={
              <>
                <SearchBar
                  value={searchQuery}
                  onChange={onChangeSearch}
                  onClick={() => onClickRefetch(refetch)}
                />
               
              </>
            }
            title={<TableHeader title={t('Manage Catalog')} />}
            // title={<TableHeader title={t('Vendors')} />}
            columns={columns}
            data={tabledata || []}
            pagination
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            sortFunction={customSort}
            // defaultSortField="email"
            customStyles={customStyles}
          // selectableRows
          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <Catlog vendor={vendors} editModal={editModal} onClose={closeEditModal} refetch={refetch} />
        </Modal>
      </Container>
    </>
  )
}

export default withTranslation()(ManageCatlog)
