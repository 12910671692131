/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useQuery, gql, useApolloClient } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import CustomLoader from '../components/Loader/CustomLoader'
// core components
import Header from '../components/Headers/Header'
import {   getallworker } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { Container, Button, useTheme, } from '@mui/material'
import { customStyles } from '../utils/tableStyleFOrAllOrders'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'

const GET_RIDERDETAILS = gql`
  ${getallworker}
`

const RiderList = props => {
  const { t } = props;
  const theme = useTheme();
  const client = useApolloClient();
  const globalClasses = useGlobalStyles()

  var [tableData, setTableData] = useState([])
  const [totalRows, setTotalRows] = useState(0)



  const { data, loading: loadingQuery, } = useQuery(GET_RIDERDETAILS, {
    fetchPolicy: 'network-only',

    onCompleted: (data) => {
      if (data && data.getAllWorkersForLocalStore) {
        const filteredData = data.getAllWorkersForLocalStore.filter(worker => worker.workerType === 2);
        setTableData(filteredData);
        setTotalRows(filteredData.length);
      
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });
  useEffect(() => {
    if (data) {
      const filteredData = data.getAllWorkersForLocalStore.filter(worker => worker.workerType === 2);
      setTableData(filteredData);
      setTotalRows(filteredData.length);
    }
  }, [data,]);



  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }


  const columns = [
    {
      name: 'Name',
      selector: 'name',
      style: { cursor: 'pointer' },
    },
    {
      name: 'Email',
      selector: 'email',
      style: { cursor: 'pointer' },
    
    },
    {
      name: 'Phone',
      selector: 'phone',
      style: { cursor: 'pointer' },
    
    },
  
    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>,
    },
  ];
  

  const actionButtons = row => {

    return (
      <>
        <Button
          size="20px"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.common.white,
            padding: 0,
            height: '15px',
            fontSize: '7px',
            '&:hover': {
              border: '2px solid #637BFE',
              color: theme.palette.warning.dark,
              backgroundColor: "white"
            }
          }}
          onClick={e => {
            e.preventDefault()
            localStorage.setItem('riderDetails', JSON.stringify(row))
            const userType = JSON.parse(localStorage.getItem('user-native')).userType;
            let routePath = '';
            switch (userType) {
              case 0:
                routePath = '/super_admin/OrderDetails';
                break;
              case 1:
                routePath = '/cs_admin/OrderDetails';
                break;
              case 2:
                routePath = '/local_admin/rideroutfordeliverydetails';
                break;
              default:
                routePath = '/super_admin/OrderDetails'; // Default to super admin if userType is not defined
                break;
            }
            props.history.push({
              pathname: routePath,
              state: { id: row._id }
            });
          }}
        >
          {t('ViewDetails')}
        </Button>
      </>
    )
  }


  return (
    <>
      <Header />
      <Container className={globalClasses.flex} fluid>
        {loadingQuery ? (
          <CustomLoader />
        ) :
          <DataTable
            customStyles={customStyles}
            subHeader={true}
            title={<TableHeader title={t('Rider List')} />}
            columns={columns}
            data={tableData || []}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            sortFunction={customSort}
          />
        }
      </Container>
    </>
  )
}
export default withTranslation()(RiderList)
