import React from 'react'
import Loader from 'react-loader-spinner'

const CustomLoader = () => {
  return (
    <div style={{ padding: '50px', alignSelf: 'center' }}>
      <Loader type="TailSpin" color= "#3E79F7" height={100} width={100} />
    </div>
  );
};

export default CustomLoader;
