

import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { updatesubcategory, getallcategories, createSubCategory } from '../../apollo';
import { Button, Box, Switch, Typography, Grid, Paper, TextField, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import useStyles from './styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useGlobalStyles from '../../utils/globalStyles';
import AWS from 'aws-sdk';
import { imageBaseUrl } from '../../utils/imageLink';
import S3 from 'aws-sdk/clients/s3';

const CREATE_SUBCATEORIES = gql`
  ${createSubCategory}
`;
const EDIT_SUBCATEGORY = gql`
  ${updatesubcategory}
`;
const GET_ALL_CATEGORIES = gql`
  ${getallcategories}`

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function AddSubCategory(props) {
  const { onClose} = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT_SUBCATEGORY : CREATE_SUBCATEORIES;
  let [error, errorSetter] = useState('');
  // const [error, errorSetter] = useState('');
  const fileInputRef = useRef(null);
  const [success, successSetter] = useState('');
  const { t } = props;
  const [formData, setFormData] = useState({
    _id: props.vendor ? props.vendor._id : "", // Use _id instead of id
    categoryid: props.vendor ? props.vendor.categoryId : localStorage.getItem("catagery_id"),
    name: props.vendor ? props.vendor.name : "",
    images: props.vendor ? props.vendor.images : [],
    isActive: props.vendor ? props.vendor.isActive : false,
    description: props.vendor ? props.vendor.description : ""

  });
  const folderStructure = process.env.REACT_APP_IMAGE_FOLDERSTRUCTURE;
  
  const [formErrors, setFormErrors] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [imageName, setImageName] = useState(null)
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false)
  const images = props.vendor ? props.vendor.images : [] 
  const [openSnackk, setopenSnackk] = useState(false);
  // Define a state to hold your query data
  const [selectedCategory, setSelectedCategory] = useState(props.vendor ? props.vendor.categoryid : localStorage.getItem("catagery_id"));
  const allowedTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'video/mp4',
    'video/quicktime',
    'audio/mpeg',
    'audio/wav',
    // Add more supported types as needed
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setImageName(selectedFile.name)
    if (allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);

      // Preview the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert('Invalid file type. Only images and PDFs are allowed.');
    }
  };

  const uploadFile = async () => {
    setUploading(true);
    const S3_BUCKET = "nativenest"; // Replace with your bucket name
    const REGION = "ap-south-1"; // Correct region code
    const getContentType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      const contentTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        pdf: 'application/pdf',
        mp4: 'video/mp4',
        mov: 'video/quicktime',
        mp3: 'audio/mpeg',
        wav: 'audio/wav',
        // Add more content types as needed
      };
   
  
      return contentTypes[extension] || 'application/octet-stream';
    };
    const contentType = getContentType(file.name);
    AWS.config.update({
      // accessKeyId: "AKIAVRUVQVTHJR36HMGG",
      // secretAccessKey: "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",
      accessKeyId: "AKIAVRUVQVTHIW2AHBGP",
      secretAccessKey: "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",
    });
    const folderStructure = process.env.REACT_APP_IMAGE_FOLDERSTRUCTURE;
    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folderStructure}/${file.name}`, // File path in S3 bucket
      Body: file,
      ContentType: contentType, // Add ContentType here
      // ACL: 'public-read' // Set ACL to public-read
    };
    try {
      const upload = await s3.putObject(params).promise();
      const imageUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folderStructure}/${file.name}`;

      setUploading(false);
      alert("File uploaded successfully.");
      setUploading(false);
      // Update formData with the uploaded image URL
      setFormData((prevState) => ({
        ...prevState,
        images: [...prevState.images, imageUrl],
      }));
    } catch (error) {
      setUploading(false);
      alert("Error uploading file: " + error.message); // Inform user about the error
    }
  };

  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Sub Category Updated Successfully') : t('Sub Category Added Successfully');
    errorSetter('');
    props.refetch()
    successSetter(message);
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      const errorMessage = graphQLErrors[0].message;
      error = graphQLErrors[0].message
      errorSetter(errorMessage);
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };

  const {  data, refetch: refetchCentralStore } = useQuery(GET_ALL_CATEGORIES);
  const [mutate] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetchCentralStore();
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Other code
  }, []);
  useEffect(() => {
    refetchCentralStore();
  }, [refetchCentralStore]);

  useEffect(() => {
    if (data && props.vendor && props.vendor.categoryid) {
      const selectedStore = data.getCategories.find(
        (store) => store._id === props.vendor.categoryid
      );


      setSelectedCategory(selectedStore || null);
    } else if (data) {
      const selectedStore = data.getCategories.find(
        (store) => store._id === formData.categoryid
      );

      setSelectedCategory(selectedStore || null);
    }
  }, [data, props.vendor]);

  const options = data ? data.getCategories || [] : [];


  const handleCategoryChange = (event, value) => {
    if (value) {
      setSelectedCategory(value); // Assuming value is an object from options array
      setFormData({ ...formData, categoryid: value._id });
      if(formData.categoryid !==undefined || formData.categoryid !== null){
        setCategoryError(false)
      }
    } else {
      setSelectedCategory(null);
      setFormData({ ...formData, categoryid: '' }); // Clear storeId if no option is selected
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update form data state
    setFormData({ ...formData, [name]: value });
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };
const [CategoryError,setCategoryError]=useState(false)

  const validateFormData = () => {
    let errors = {};
    if (!formData.name) errors.name = 'Product Name is required';
    if (!formData._id) errors._id = 'Product Id is required';
    if (!formData.description) errors.description = 'Product Name is required';
    if(!formData.categoryid || formData.categoryid === undefined || formData.categoryid === null ){
      setCategoryError(true)
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const clearFields = () => {
    formRef.current.reset();
    setFormErrors({});
    setFormData({
      _id: props.vendor ? props.vendor._id : "", // Use _id instead of id
      categoryid: props.vendor ? props.vendor.categoryId : "",
      name: props.vendor ? props.vendor.name : "",
      images: props.vendor ? props.vendor.images : [],
      isActive: props.vendor ? props.vendor.isActive : false,
      description: props.vendor ? props.vendor.description : ""

    })
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      // setOpenSnack(true);
      return;
    }

    if (true) {
      !props.vendor ?
        mutate({
          variables: {
            subCategoryInput: {
              _id: formData._id,
              categoryId: formData.categoryid,
              images: imageBaseUrl +  `${folderStructure}/${imageName}`, // Check if this is how your backend expects images
              isActive: formData.isActive,
              name: formData.name,
              description: formData.description
            }
          }
        }) :
        mutate({
          variables: {
            subCategoryUpdate: {
              _id: formData._id,
              categoryId: formData.categoryid,
              images: imageName ? imageBaseUrl +  `${folderStructure}/${imageName}` : formData.images, // Check if this is how your backend expects images
              isActive: formData.isActive,
              name: formData.name,
              description: formData.description
            }
          }

        })
        ;
      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose(); // Close the modal
        }
      }, 4000);
    }

  }

  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>

            {props.vendor ? t('Edit Sub Category') : t('Add Sub Category')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}
          onSubmit={onSubmit}
        >
          <Grid container spacing={2}>
            {!props.vendor ?
              <>
                <Grid item xs={12} sm={12}>
                  <TextField name="_id" label="Sub Category ID" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    helperText={formErrors._id}
                    error={Boolean(formErrors._id)}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    // options={data.getAllCentralStores || []}
                    options={options}
                    getOptionLabel={(option) => option.name}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={CategoryError}
                        helperText={CategoryError? 'Please select a category' : ''}

                        label="Select Category"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    // inputProps={{ style: inputTextStyle }}
                    PaperComponent={CustomPaperComponent} // Use the custom paper component to style options
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField name="name" label=" Sub-Category Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    helperText={formErrors.name}
                    error={Boolean(formErrors.name)} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField name="description" label="Description" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={Boolean(formErrors.description)}
                    helperText={formErrors.description}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  {(props.vendor && previewImage == null) ?
                    <>
                      {images.map((image, index) => (
                        <div key={index}>
                          <img src={image} alt={`Uploaded ${index}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                        </div>
                      ))}
                    </> :
                    <>
                      {previewImage && (
                        <div>
                          <img src={previewImage} alt="Preview" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                        </div>
                      )}

                    </>

                  }


                  <Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                    Choose Image
                    <input type="file" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
                  </Button>
                  <Button onClick={uploadFile} disabled={!file} className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                    {uploading ? 'Uploading...' : 'Upload  File'}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                      Status
                    </Typography>
                    <Switch name="isActive" checked={formData.isActive} onChange={handleSwitchChange} />
                  </div>
                </Grid>
              </>
              :
              ( // Conditionally render the second form fields based on editModal prop
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField name="_id" label=" Sub Category ID"
                      value={formData._id}
                      helperText={formErrors._id}
                      error={Boolean(formErrors._id)}
                      variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      // options={data.getAllCentralStores|| []}
                      options={options}
                      getOptionLabel={(option) => option.name}

                      onChange={handleCategoryChange}
                      value={selectedCategory}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Category"
                          error={CategoryError}
                          helperText={CategoryError ? 'Please select a category' : ''}

                          variant="outlined"
                          inputProps={{ ...params.inputProps, style: inputTextStyle }}
                        />
                      )}
                      // inputProps={{ style: inputTextStyle }}
                      PaperComponent={CustomPaperComponent} // Use the custom paper component to style options
                    /> </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField name="name" value={formData.name} helperText={formErrors.name}
                      error={Boolean(formErrors.name)} label=" Sub-Category Name " variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField name="description"
                      helperText={formErrors.description}
                      error={Boolean(formErrors.description)}
                      value={formData.description} label="Description" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {(props.vendor && previewImage == null) ?
                      <>
                        {images.map((image, index) => (
                          <div key={index}>
                            <img src={image} alt={`Uploaded ${index}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                          </div>
                        ))}
                      </> :
                      <>
                        {previewImage && (
                          <div>
                            <img src={previewImage} alt="Preview" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                          </div>
                        )}

                      </>

                    }


                    <Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                      Choose Image
                      <input type="file" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
                    </Button>
                    <Button onClick={uploadFile} disabled={!file} className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                      {uploading ? 'Uploading...' : 'Upload  File'}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                        Status
                      </Typography>
                      <Switch name="isActive" checked={formData.isActive} onChange={handleSwitchChange} />
                    </div>
                  </Grid>
                </>
              )
            }
          </Grid>
          <DialogActions>
            <Button
              className={globalClasses.dashbordBtn}
              type='submit'

            >
              {props.vendor ? t('Update') : t('Add')}
            </Button>
            <Button
              className={globalClasses.modalCancleBtn}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>

      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(AddSubCategory);
