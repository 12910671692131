import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import VendorComponent from '../components/Vendor/Vendor'
import CustomLoader from '../components/Loader/CustomLoader'
import { getallcentralStore, deletecentralstore } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableFOrManageCentralStore'
import { Icon } from '@iconify/react';
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper, Box,
  Snackbar
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import Alert from '../components/Alert'
import PageviewIcon from '@mui/icons-material/Pageview';
import ImageIcon from '@mui/icons-material/Image';
import LandingPage from '../components/Vendor/LandingPage'
import Banner from '../components/Vendor/Banner'

const GET_ALL_CENTRAL_STORE = gql`
${getallcentralStore}
`
const DELETE_CENTAL_STORE = gql`
  ${deletecentralstore}
`


const Vendors = props => {
  const { t } = props;
  const [editModal, setEditModal] = useState(false)
  const [landingPageModal, setLandingPageModal] = useState(false)
  const [bannerModal, setBannerModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const golbalClasses = useGlobalStyles()
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const client = useApolloClient();
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };
  
  const closeLandingModal = () => {
    setLandingPageModal(false);
 
  };
  
  const closeBannerModal = () => {
    setBannerModal(false);
 
  };

  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };
  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_ALL_CENTRAL_STORE, {
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  )
  useEffect(() => {
    refetch()
  }, [])
  const [mutate, { loading }] = useMutation(DELETE_CENTAL_STORE, {
    refetchQueries: [{ query: GET_ALL_CENTRAL_STORE }]
  })
 

  const filtered = data && data.getAllCentralStores


  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }
  const toggleModalLangingPage = vendor => {
    setLandingPageModal(!landingPageModal)
    setVendor(vendor)
  }
  const toggleModalBanner = vendor => {
    setBannerModal(!bannerModal)
    setVendor(vendor)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const columns = [
    {
      name: "Sl No.",
      cell: (row, index) => <>{index + 1}</>,
      
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Store Name",
      sortable: true,
      selector: 'name',
      cell: row => row.name || '-',
      
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Description",
      sortable: true,
      selector: 'email',
      cell: row => row.email || '-',
      style: { cursor: 'pointer', minWidth: "200px" },
    },

    {
      name: "Store Code",
      sortable: true,
      selector: 'code',
      cell: row => row.code || '-'
      ,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: "City",
      sortable: true,
      selector: 'city',
      cell: row => row.city || '-',
      style: { cursor: 'pointer', minWidth: "150px" },
    },

    // {
    //   name: "Zone",
    //   sortable: true,
    //   selector: 'zone',
    //   cell: row => row.zone || '-',
    //   style: { cursor: 'pointer', minWidth: "150px" },
    // },
    {
      name: "Address",
      sortable: true,
      selector: 'address',
      cell: row => row.address || '-',
      style: { cursor: 'pointer', minWidth: "150px" },
    },

    {
      name: "Action",
      cell: row => <>{actionButtons(row)}</>
    }
  ];

  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(false)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  toggleModal(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
           
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(false)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  toggleModalLangingPage(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <PageviewIcon fontSize="small" style={{ color: 'blue' }} />
                </ListItemIcon>
                <Typography color="blue">{t('Landing Page')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(false)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  toggleModalBanner(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                <ImageIcon fontSize="small" style={{ color: 'purple' }} />
                </ListItemIcon>
                <Typography color="purple">{t('Banner')}</Typography>
              </MenuItem>
              <MenuItem
                disabled
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(true)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  mutate({ variables: { storeId: row._id } });
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      {isOpen && (
        <Alert
          message={t('Deleted Successfully')}
          severity="error"
        />
      )}
      <Container className={golbalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button
            className={golbalClasses.dashbordBtn}
            onClick={openaAddVendorModal}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} />  {t('Add New Central Store')}
          </Button>
        </Box>
        {addVendorModal ? <Grid container>
          <Grid
            sx={{ display: { xs: 'none', lg: 'block' } }}
            item
            mt={5}
            ml={-2}
            order={{ xs: 1, lg: 2 }}
          >
            <Modal
              open={addVendorModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClose={closeAddVendorModal}
            >
              <VendorComponent onClose={closeAddVendorModal} refetch={refetch} />
            </Modal>
          </Grid>
          {/* </Grid> */}
        </Grid> : null
        }
        {errorQuery && errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}

            title={<TableHeader title={t('Manage Central Store')} />}
            columns={columns}
            data={filtered}
            pagination
            progressPending={loading}
            progressComponent={<CustomLoader />}
            sortFunction={customSort}
            customStyles={customStyles}
          

          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <VendorComponent vendor={vendors} editModal={editModal} onClose={closeEditModal} refetch={refetch} />
        </Modal>
         {/* landing page  */}
         <Modal
          open={landingPageModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <LandingPage vendor={vendors} editModal={landingPageModal} onClose={closeLandingModal} refetch={refetch} />
        </Modal>
        {/* Banner modal */}
        <Modal
          open={bannerModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <Banner vendor={vendors} editModal={bannerModal} onClose={closeBannerModal} refetch={refetch} />
        </Modal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // Adjust as needed
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity="success">
            Deleted Successfully!
          </Alert>
        </Snackbar>
      </Container>
    </>
  )
}

export default withTranslation()(Vendors)
