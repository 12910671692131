// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';

// export default function SimpleSnackbar({notification}) {
//  const [state, setState] = React.useState({
//     open: true,
//     vertical: 'top',
//     horizontal: 'right',
//   });
//   const { vertical, horizontal, open } = state;

//   const handleClick = () => {
//     setState({ ...state, open: true });
//   };

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }

//     setState({ ...state, open: false });
//   };

//   const action = (
//     <React.Fragment>
//       <Button color="secondary" size="small" onClick={handleClose}>
//         UNDO
//       </Button>
//       <IconButton
//         size="small"
//         aria-label="close"
//         color="inherit"
//         onClick={handleClose}
//       >
//         <CloseIcon fontSize="small" />
//       </IconButton>
//     </React.Fragment>
//   );

//   return (
//     <div>
//       <Button onClick={handleClick}>Open Snackbar</Button>
//       <Snackbar
//        anchorOrigin={{ vertical, horizontal }}
//         open={open}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         message="Note archived"
//         action={action}
//       />
//     </div>
//   );
// }

import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';

export default function SimpleSnackbar({ notification, open, handleClose }) {
  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      severity="success"
      autoHideDuration={6000}
      onClose={handleClose}
    //   message={notification}
      action={action}
    >
         <Alert
    onClose={handleClose}
    severity="success"
    variant="filled"
    sx={{ width: '100%' }}
  >
   {notification}
  </Alert>
        </Snackbar>
    </div>
  );
}

