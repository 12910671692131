import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import GoogleMapsLoader from './components/GoogleMapsLoader/GoogleMapsLoader.js';
import { Box, CircularProgress } from '@mui/material';
import AdminLayout from './layouts/Admin.jsx';
import RestaurantLayout from './layouts/Restaurant.jsx';
import AuthLayout from './layouts/Auth.jsx';
import SuperAdminLayout from './layouts/SuperAdmin.jsx';
import { PrivateRoute } from './views/PrivateRoute.jsx';
import { AdminPrivateRoute } from './views/AdminPrivateRoute.jsx';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ConfigurableValues from './config/constants.js';
import './global.css';
import PackageAdmin from './layouts/PackageAdmin.jsx';
import { AdminPackagePrivateRoute } from './views/AdminPackagePrivateRoute.jsx';
import { AdminBillingPrivateRoute } from './views/AdminBillingPrivateRoute.jsx';
import { AdminLocalPrivateRoutes } from './views/LocalAdminPrivateRoutes.jsx';
import LocalAdminLayout from './layouts/LocalAdminLayout.jsx';
import BillingAdminLayout from './layouts/BillingAdminLayout.jsx';
import CentralAdminLayout from './layouts/CentralAdminLayout.jsx';
import { CentralAdminPRivateRoute } from './views/CentralAdminPRivateRoute.jsx';
import { UpdateAdminWebPushToken } from './apollo/mutations.js';
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import Message from "./components/Message";
import SimpleSnackbar from './components/SimpleSnackbar.jsx';



require('./i18n.js');
const UPDATE_WORKER_WEB_PUSH_NOTIFICATION = gql`
  ${UpdateAdminWebPushToken}
`;
const App = () => {
  const {
    GOOGLE_MAPS_KEY,
    SERVER_URL
  } = ConfigurableValues();

  const [updateWorkerWebPushNotification] = useMutation(UPDATE_WORKER_WEB_PUSH_NOTIFICATION);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const userType = localStorage.getItem('user-native')
    ? JSON.parse(localStorage.getItem('user-native')).userType
    : null;

  const userRole = localStorage.getItem('userRole');

  const route =
    userRole === 'Admin' ?
      userType
        ? userType === 0
          ? '/super_admin/vendors'
          : userType === 1
            ? 'cs_admin/dashboard'
            : userType === 2
              ? '/local_admin/dashboard' : '/billing_admin/vendors' : '/auth/login' :
      userType
        ? userType === 0
          ? '/package_admin/dashboard'
          : userType === 1
            ? 'billing_admin/dashboard'
            : userType === 2
              ? '/local_admin/dashboard' : '/billing_admin/vendors' : '/auth/login';


              async function requestPermission() {
                //requesting permission using Notification API
                const permission = await Notification.requestPermission();
            
                if (permission === "granted") {
                  const token = await getToken(messaging, {
                    vapidKey:"BAfJSaZD1eTnL8QECP2_0QuPZQ-tBpl6HfO8FVrlSKFh6tbuT9NJgT1b-hvb69TJqsY78J_W3-ZOzHP88fJCo5Q",
                  });
            
                     const fetchTokenAndUpdateAPI = async () => {
      const tokenValue = token;

      // Update token in GraphQL API
      await updateWorkerWebPushNotification({
        variables: { token: tokenValue }, // Pass the token as a variable
      });
    };

    fetchTokenAndUpdateAPI();
                  //We can send token to server
                } else if (permission === "denied") {
                  //notifications are blocked
                  alert("You denied for the notification");
                }
              }
            
              useEffect(() => {
                requestPermission();
              }, []);
          
              onMessage(messaging, (payload) => {
                  setNotificationMessage(payload.notification.body);
                  setSnackbarOpen(true);
                // toast(<Message notification={payload.notification} />);
              });

              const handleClose = () => {
                setSnackbarOpen(false);
              };

              
  return (
    <Sentry.ErrorBoundary>
      {SERVER_URL ?
      
      (
        <GoogleMapsLoader GOOGLE_MAPS_KEY={GOOGLE_MAPS_KEY}>
          <HashRouter basename="/">
            <Switch>
              <AdminPrivateRoute path="/super_admin" component={props => <SuperAdminLayout {...props} />} />
              <AdminPackagePrivateRoute path="/package_admin" component={props => <PackageAdmin {...props} />} />
              <CentralAdminPRivateRoute path="/cs_admin" component={props => <CentralAdminLayout {...props} />} />
              <AdminLocalPrivateRoutes path="/local_admin" component={props => <LocalAdminLayout {...props} />} />
              <AdminBillingPrivateRoute path="/billing_admin" component={props => <BillingAdminLayout {...props} />} />
              <PrivateRoute path="/restaurant" component={props => <RestaurantLayout {...props} />} />
              <PrivateRoute path="/admin" component={props => <AdminLayout {...props} />} />
              <Route path="/auth" component={props => <AuthLayout {...props} />} />
              <Redirect from="/" to={route} />
            </Switch>
          </HashRouter>
        </GoogleMapsLoader> 
      ) : (
        <Box component="div" display="flex" alignItems="center" justifyContent="center" height="100vh" width="100vw">
          <CircularProgress color="primary" />
        </Box>
      )}
   <SimpleSnackbar
        notification={notificationMessage}
        open={snackbarOpen}
        handleClose={handleClose}
      />
    </Sentry.ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);