
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import Header from '../components/Headers/Header';
import CustomLoader from '../components/Loader/CustomLoader';
import {GetAllReplenishRequests} from '../apollo';
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { customStyles } from '../utils/tableForCSManageReplenation';
import useGlobalStyles from '../utils/globalStyles';
import {
  Container,
  Button,
  Paper,TextField,
  useTheme,
  Box,
  Autocomplete
} from '@mui/material';
import TableHeader from '../components/TableHeader';
import 'jspdf-autotable';


const GET_REPLENTION_PRODUCTS = gql`${GetAllReplenishRequests}`;

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);
const CSRepletion = props => {
  const theme = useTheme();
  const { t } = props;
  const [editMode, setEditMode] = useState(null);
  const golbalClasses = useGlobalStyles();
  const client = useApolloClient();
  const [page, setPage] = useState(1);
  const [tabledata, setTabledata] = useState(null);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState(6); // Default to ALL
  let newId;
  try {
    newId = JSON.parse(localStorage.getItem("newId"));
  } catch (e) {
    newId = null;
  }

  // Use optional chaining to safely access the _id property
  const highlightId = newId ? newId.initiateReplenishRequest._id : null;

  const inputTextStyle = {
      color: 'black', height: '30px', // Adjust as needed
      padding: '0 14px'
  };
  const statusOptions = [
      { label: 'REQUESTED', value: 0 },
      { label: 'APPROVED', value: 1 },
      { label: 'APPROVED WITH CHANGE', value: 2 },
      { label: 'RECEIVED', value: 3 },
      { label: 'RECEIVED WITH CHANGE', value: 4 },
      { label: 'CANCELLED', value: 5 },
      { label: 'ALL', value: 6 },
  ];

  const handleStatusChange = (event, newValue) => {
    if (newValue) {
        setSelectedStatus(newValue.value);
    }
};

  const variables = {
    status: selectedStatus,
    limit: perPage,
    skip: (page - 1) * perPage,
  };

  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_REPLENTION_PRODUCTS, {
      variables,
      onCompleted: (data, errors) => {
        setTabledata(data && data.getAllReplenishRequests.repleshishRequestSummary ? data.getAllReplenishRequests.repleshishRequestSummary : []);
        setTotalRows(data.getAllReplenishRequests.totalCount);
      },
      onError: error => {
        const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
        if (isForbiddenError) {
          localStorage.removeItem('user-native');
          localStorage.removeItem('restaurant_id');
          client.clearStore();
          props.history.push('/auth/login');
        }
      },
    }
  );

  useEffect(() => {
    if (data && data.getAllReplenishRequests.repleshishRequestSummary) {
      setTabledata(data && data.getAllReplenishRequests.repleshishRequestSummary ? data.getAllReplenishRequests.repleshishRequestSummary: []);
      setTotalRows(data.getAllReplenishRequests.totalCount);
    }
   
  }, [data]);

  useEffect(() => {
    refetch({
      variables: {
        status: selectedStatus,
        limit: perPage,
        skip: (page - 1) * perPage,
      },
    });
  }, [page, perPage, refetch]);

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page);
  };

  const handleSaveClick = (row) => {
    setEditMode(null);
  };

  const handleCancelClick = () => {
    setEditMode(null);
  };

const columns = useMemo(() => [

  {
    name: 'Order ID',
    selector: '_id', 
    cell: (row) => (
      <span style={{ color: row._id === highlightId ? 'blue' : 'inherit' }}>
        {row._id.slice(-5)}
      </span>
    ), 
    style: { cursor: 'pointer' },
  },
  {
      name: "Central Store Name",
      sortable: true,
      selector: 'centralStoreName',
      style: { cursor: 'pointer',  },
    },
    {
      name: "Local Store Name",
      sortable: true,
      selector: 'localStoreName',
      style: { cursor: 'pointer', },
    },
    {
      name: "Requested By",
      sortable: true,
      selector: 'requestedByEmail',
      style: { cursor: 'pointer',  },
    },
    {
      name: "Requested Date",
      sortable: true,
      selector: 'requestedDate',
      cell: row => new Date(row.requestedDate).toLocaleString(), // Format date
      style: { cursor: 'pointer',},
    },
    {
      name: "Status",
      sortable: true,
      selector: 'statusString',
      style: { cursor: 'pointer' },
    },
    {
      name: "Requested Count",
      sortable: true,
      selector: 'itemCount.requestedCount',
      style: { cursor: 'pointer', },
    },
    {
      name: "Approved Count",
      sortable: true,
      selector: 'itemCount.approvedCount',
      style: { cursor: 'pointer', },
    },
    {
      name: "Received Count",
      sortable: true,
      selector: 'itemCount.receivedCount',
      style: { cursor: 'pointer', },
    },
   
    {
        name: t('Action'),
        cell: row => <>{actionButtons(row)}</>,
      },
  ], [editMode, handleSaveClick, handleCancelClick]);

  const actionButtons = row => {

  
    return (
      <>
        <Button
          size="20px"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.common.white,
            padding: 0,
            height: '15px',
            fontSize: '7px',
            '&:hover': {
              border: '2px solid #637BFE',
              color: theme.palette.warning.dark,
              backgroundColor: "white"
            }
          }}
          onClick={e => {
            e.preventDefault()
            // handleReadyButtonClick(row)
            localStorage.setItem('ReplentionData',JSON.stringify(row) )
            localStorage.setItem('Tab',JSON.stringify(0) )
            props.history.push({
              pathname: '/cs_admin/detilerepletion',
              state: { id: row._id  , Tab : 0 }
            })
          }}>
          {t('View Details')}
          
        </Button>
      
      </>
    )
  }

  const customSort = useCallback((rows, selector, direction) => {
    const handleField = row => {
      if (typeof selector === 'function') {
        const data = selector(row);
        return data ? data.toString().toLowerCase() : "";
      }
      return "";
    };
    return orderBy(rows, handleField, direction);
  }, []);

  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <>
       
            <Box sx={{ textAlign: 'right', mb: 3 }}>
  
</Box>
            <DataTable
              subHeader={true}
              subHeaderComponent={
                <Box style={{ marginTop: -40 }}>
                <Autocomplete
                    options={statusOptions}
                    getOptionLabel={(option) => option.label}
                    value={statusOptions.find(option => option.value === selectedStatus)}
                    onChange={handleStatusChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Filter By Status"
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                                style: inputTextStyle
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: '40px' // Adjust height
                                },
                                '& .MuiAutocomplete-inputRoot': {
                                    padding: '0 0 0 14px !important' // Adjust padding
                                }
                            }}
                        />
                    )}
                    sx={{ width: 300, ml: 2 }}
                    PaperComponent={CustomPaperComponent}

                />
            </Box>
              }
              title={<TableHeader title={t(' CS Manage Replention   ')} />}
              columns={columns}
              data={tabledata || []}
              progressPending={loadingQuery}
              progressComponent={<CustomLoader />}
              paginationDefaultPage={currentPage}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowChange}
              onChangePage={handlePageChange}
              sortFunction={customSort}
              // selectableRows
              customStyles={customStyles}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default withTranslation()(CSRepletion);
