import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  loginImage: {
    height: '50%', // Default height for big screens
    width: '50%', // Default width for big screens
    // marginRight:"100px",
    [theme.breakpoints.down('md')]: {
      height: '25% !important', // Height for tablets and smaller screens
      width: '25% !important', // Width for tablets and smaller screens
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  container: {
    backgroundColor: theme.palette.background.primary,
    margin: '30px 0',
    borderRadius: 20,
    boxShadow: `0px 0px 38px ${theme.palette.common.blackShade}`,
    textAlign: 'center',
    paddingBottom: 5
  },
  heading: {
    backgroundColor: theme.palette.primary.main2,
    width: '50%',
    padding: 10,
    borderRadius: '20px 20px 20px 0',
    textAlign: 'center'
  },
  loggin_heading: {
    // backgroundColor: theme.palette.primary.main2,
    width: '100%',
    padding: 10,
    // borderRadius: '20px 20px 20px 0',
    textAlign: 'center'
  },
  form: {
    margin: 25,
    alignItems: 'center'
  },
  text: {
    color: theme.palette.common.black,
    fontWeight: 'bold'
  },
  labelText: {
    textAlign: 'left',
    marginLeft: '4%',
    fontSize: '15px',
    fontWeight: '500',
    marginTop: '10px'
  }
}))

export default useStyles
