
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'
import CustomLoader from '../components/Loader/CustomLoader'
import { getOrderDetailsforworker  } from '../apollo'
import DataTable from 'react-data-table-component'
import { customStyles } from '../utils/orderTable'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Box
} from '@mui/material'
import TableHeader from '../components/TableHeader'
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Icon } from '@iconify/react';
import Divider from '@mui/material/Divider';


const GET_ORDERDETAILS = gql`
  ${getOrderDetailsforworker}
`
// const GET_RIDERDETAILS = gql`
//   ${GetRiderAllotmentReport}
// `


const RiderReport = props => {
  const golbalClasses = useGlobalStyles()
  const { t } = props;
  const globalClasses = useGlobalStyles();
  const orderDetails = JSON.parse(localStorage.getItem("riderReportDetails"))
  const userType = JSON.parse(localStorage.getItem('user-native')); // Assuming you have a function to get the user type
  let routePath = '';
  switch (userType.userType) {
    case 0:
      routePath = '/super_admin/allorders';
      break;
    case 1:
      routePath = '/cs_admin/allorders';
      break;
    case 2:
      routePath = '/local_admin/riderreport';
      break;
    default:
      routePath = '/super_admin/allorders'; // Default to super admin if userType is not defined
      break;
  }
  const history = useHistory();
 
  const handleBackClick = () => {
    history.push(routePath); // Navigate back based on user type
  };

  const { loading: loadingQuery, data, } = useQuery(
    GET_ORDERDETAILS, {
    variables: {
      orderId: orderDetails._id
    }
  }
  )

  // const {  data:riderData,  } = useQuery(
  //   GET_RIDERDETAILS, {
  //   variables:{  "lsId":userType.storeId,
  //       "skip": 0,
  //       "limit": 1
  //     }
  // }
  // )


  const orderList =orderDetails && orderDetails.orders
  const riderList =orderDetails && orderDetails.riders
  
  // const [getWorkerDetails, { loading: loadingWorker, data: workerData }] = useLazyQuery(GET_WORKERDETAILS);


  useEffect(() => {
    if (data && data.getOrderDetailsById) {
      const { riderAssigned, ppWorkerAssigned, billingWorkerAssigned } = data.getOrderDetailsById.orderState;
      const workerId = ppWorkerAssigned || billingWorkerAssigned || riderAssigned;
      if (workerId) {
        // getWorkerDetails({ variables: { workerId: workerId } });
      }
    }
  }, [data,
    //  getWorkerDetails
  ]);

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])



  const transformedData = riderList.map(email => ({ email }));
  const transformedDataORder = orderList.map(orderId => ({ orderId }));
  const columnsForRiders = [
    {
      name: "Sl No",
      selector: (row, index) => index + 1,
      width: "50px" // Adjust the width if necessary
    },
    {
      name: "Email",
      selector: "email",
      style: { cursor: 'pointer', minWidth: "200px" }
    }
  ];

  
  const columnsForoders = [
    {
      name: "Sl No",
      selector: (row, index) => index + 1,
      
      width: "50px" // Adjust the width if necessary
    },

    {
      name: "Order ID",
      selector: "orderId",

      style: { cursor: 'pointer', minWidth: "300px" },
    },


  ];


const columnsForOrders = [
    {
      name: "Order ID",
      selector: "_id",
      style: { cursor: 'pointer' }
    },
    {
      name: "Customer ID",
      selector: "customerId",
      style: { cursor: 'pointer' }
    },
    {
      name: "Delivery Date",
      selector: "deliveryDate",
      style: { cursor: 'pointer' }
    }
  ];
  



  const handlePrint = () => {
    const doc = new jsPDF();
    let currentY = 20;
  
    // Helper function to add a table
    const addTable = (title, columns, data) => {
      doc.setFontSize(16);
      doc.text(title, 14, currentY);
      currentY += 10;
      doc.autoTable({
        head: [columns.map(col => col.name)],
        body: data.map(row => columns.map(col => row[col.selector])),
        startY: currentY,
        theme: 'grid',
        styles: { fontSize: 10 },
      });
      currentY = doc.lastAutoTable.finalY + 10; // Update currentY to position next table correctly
    };
  
    // Add Riders List Table
    addTable('Riders List', columnsForRiders, transformedData);
  
    // Add Orders List Table
    addTable('Orders List', columnsForoders, transformedDataORder);
  
    // Add Orders Table for each assigned item
    (orderDetails.assigned || []).forEach((item, index) => {
      addTable(`Orders for ${item.riderEmail}`, columnsForOrders, item.orders || []);
    });
  
    // Save the PDF as a blob and open it in a new tab
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank');
  };
  
 
  return (
    <>


      <Container className={globalClasses.flex} fluid>
        <Box sx={{ textAlign: 'right' }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={handlePrint}
          >
            <Icon icon="gridicons:print" style={{ color: "white" }} />  {t('Print')}
          </Button>
          <Button className={golbalClasses.dashbordBtn} onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
         <Grid container spacing={1} mt={1} >
              <Grid container item xs={12} md={12}>
              <Grid item xs={12} md={6} style={{padding:"10px"}}>
              <DataTable
          subHeader={true}

          title={<TableHeader title={t('Riders List')} />}
          columns={columnsForRiders}
          data={transformedData}
        //   pagination
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          customStyles={customStyles}
        />
                </Grid>
                <Grid item xs={12} md={6}>
                <DataTable
          subHeader={true}

          title={<TableHeader title={t('Orders List')} />}
          columns={columnsForoders}
          data={transformedDataORder}
        //   pagination
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          customStyles={customStyles}
        />
                </Grid>
             

              </Grid>

              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4, // Increase thickness
                    // borderColor: 'blue' // Change color
                  }} />
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
  {orderDetails && orderDetails.assigned && orderDetails.assigned.map((item, index) => (
    <React.Fragment key={index}>
      <DataTable
        subHeader={true}
        title={<TableHeader title={item.riderEmail} />}
        columns={columnsForOrders}
        data={item.orders || []}
        // pagination
        progressPending={loadingQuery}
        progressComponent={<CustomLoader />}
        customStyles={customStyles}
      />
       <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4, // Increase thickness
                    // borderColor: 'blue' // Change color
                    // width: '100%', // Ensure full width
                  }} />
              </Grid>
    </React.Fragment>
  ))}
</Grid>
            </Grid>
     </Container>

    </>
  )
}

export default withTranslation()(RiderReport)
