/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useQuery, gql, useApolloClient, useMutation } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import CustomLoader from '../components/Loader/CustomLoader';
// core components
import Header from '../components/Headers/Header';
import { AdminAssignOrdersToRiders, getallworker, getorderFilter } from '../apollo';
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { Container, Button, Checkbox, useTheme, Box, Snackbar, Alert } from '@mui/material';
import { customStyles } from '../utils/tableStyleFOrAllOrders';
import useGlobalStyles from '../utils/globalStyles';
import TableHeader from '../components/TableHeader';
import { orderCustomStyles } from '../utils/tableForRiderOutForDeliveryTable';

const GET_RIDERDETAILS = gql`${getallworker}`;
const GET_ALL_ORDER_filter = gql`${getorderFilter}`;
const ASSIGN_RIDER_FOR_ORDERS = gql`${AdminAssignOrdersToRiders}`;

const AssignRider = (props) => {
  const { t } = props;
  const theme = useTheme();
  const client = useApolloClient();
  const globalClasses = useGlobalStyles();
  const [alertMessage, setAlertMessage] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedRiders, setSelectedRiders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [page, setPage] = useState(1);
  const [ordertableData, setORderTableData] = useState([]);
  const [assignedOrders, setAssignedOrders] = useState([]);
  const [unassignedOrders, setUnassignedOrders] = useState([]);
  const [orderTotalRows, setOrderTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(page);

  const { data, loading: loadingQuery } = useQuery(GET_RIDERDETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.getAllWorkersForLocalStore) {
        const filteredData = data.getAllWorkersForLocalStore.filter(worker => worker.workerType === 2);
        setTableData(filteredData);
        setTotalRows(filteredData.length);
      }
    },
    onError: handleQueryError,
  });

  const { data: orderData, loading: loadingOrder } = useQuery(GET_ALL_ORDER_filter, {
    fetchPolicy: 'network-only',
    variables: {
      filterInput: { orderStatus: 4 },
      limit: perPage,
      skip: (page - 1) * perPage,
    },
    onCompleted: (data) => {
      const orders = data.getAllOrdersByFilter.orders || [];
      setORderTableData(orders);
      setOrderTotalRows(data.getAllOrdersByFilter.totalCount || 0);
    },
    onError: handleQueryError,
  });

  const [assignRidersMutation] = useMutation(ASSIGN_RIDER_FOR_ORDERS);

  function handleQueryError(error) {
    const isForbiddenError = error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
    if (isForbiddenError) {
      localStorage.removeItem('user-native');
      localStorage.removeItem('restaurant_id');
      client.clearStore();
      props.history.push('/auth/login');
    }
  }

  const handleRiderSelection = (email) => {
    setSelectedRiders(prev =>
      prev.includes(email) ? prev.filter(selectedEmail => selectedEmail !== email) : [...prev, email]
    );
  };


  const handleOrderSelection = (orderId) => {
    setSelectedOrders(prev =>
      prev.includes(orderId) ? prev.filter(id => id !== orderId) : [...prev, orderId]
    );
  };
  const handleAssignRiders = () => {
    const payload = {
      assignOrdersInput: {
        orderIds: selectedOrders,
        riders: selectedRiders,
      },
    };
    assignRidersMutation({ variables: payload })
      .then((response) => {
        const { assigned, unassigned } = response.data.adminAssignOrdersToRiders;
        // Fallback to empty array if orders or assigned are null
        setAssignedOrders(assigned ? assigned.orders || [] : []);
        setUnassignedOrders(unassigned && unassigned.orders ? unassigned.orders : []);

        setAlertOpen(true);
        setAlertMessage('Riders successfully assigned to orders.');
        setAlertSeverity('success');
        localStorage.setItem("assignOrderDetails", JSON.stringify(response.data.adminAssignOrdersToRiders))
        props.history.push('/local_admin/assignriderdetails');
      })
      .catch((error) => {
        setAlertOpen(true);
        setAlertMessage('Something went wrong!');
        setAlertSeverity('error');
      });
  };


  const columns = [

    { name: 'Name', selector: 'name' },
    { name: 'Email', selector: 'email' },
    { name: 'Phone', selector: 'phone' },
    {
      name: 'Select',
      cell: (row) => (
        <Checkbox
          checked={selectedRiders.includes(row.email)}
          onChange={() => handleRiderSelection(row.email)}
        />
      ),
    },
  ];

  // Function to convert the deliveryDate into ISO format
  function convertToISO(deliveryDateString) {
    // Remove ordinal suffix using regex
    const cleanedDate = deliveryDateString.replace(/(\d+)(st|nd|rd|th)/, "$1");

    // Extract the date and time portions
    const [datePart, timeRange] = cleanedDate.split(",");
    const [startTime, endTime] = timeRange.split(" - ");

    // Parse the date and start time into a Date object
    const parsedDate = new Date(`${datePart} ${startTime}`);

    // Return the ISO string
    return parsedDate.toISOString();
  }
  const orderColumns = [

    { name: 'Order ID', selector: '_id', cell: (row) => <>{row._id.slice(-5)}</> },
    {
      name: 'Customer Name',
      selector: 'customerName', // Use customerName from data
      cell: (row) => <>{row.customerName}</>, // Display the customer name
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Customer Phone',
      selector: 'customerPhone',
      cell: (row) => <>{row.customerPhone}</>, // Display customer phone
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    { name: 'Order Date', selector: 'orderDate', cell: (row) => <>{new Date(row.orderDate).toLocaleDateString()}</> },
    {
      name: 'Delivery Date', selector: 'deliveryDate', cell: (row) => <>{new Date(convertToISO(row.deliveryDate)).toLocaleDateString()}</>, // Convert and format Delivery Date
    },
    {
      name: "Delivery Address",
      sortable: false,
      cell: row => {
        const deliveryAddress = row.deliveryAddress;
        // Safely access details and delivery address
        const details = deliveryAddress ? deliveryAddress.details : "NA";
        const address = deliveryAddress ? deliveryAddress.deliveryAddress : "NA";
        // Concatenate details and delivery address
        return <>{`${details}, ${address}`}</>;
      },
      style: { cursor: 'pointer', minWidth: "300px", textAlign: 'left' }, // Set text alignment to left
    },
    { name: 'Items', selector: 'itemsCount' },
    { name: 'Amount', selector: 'orderAmount' },
    {
      name: 'Select',
      cell: (row) => (
        <Checkbox
          checked={selectedOrders.includes(row._id)}
          onChange={() => handleOrderSelection(row._id)}
        />
      ),
    },
  ];

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page);
  };



  return (
    <>
      <Header />
      <Container className={globalClasses.flex} fluid>
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
          <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
            {alertMessage}
          </Alert>
        </Snackbar>
        {loadingQuery || loadingOrder ? (
          <CustomLoader />
        ) : (
          <>

            <DataTable
              customStyles={customStyles}
              title={<TableHeader title={t('Rider List')} />}
              columns={columns}
              data={tableData || []}
              pagination
              paginationServer
              paginationTotalRows={totalRows}

            />
            <br />
            <DataTable
              customStyles={orderCustomStyles}
              title={<TableHeader title={'Order List'} />}
              columns={orderColumns}
              data={ordertableData || []}
              paginationDefaultPage={currentPage}
              pagination
              paginationServer
              paginationTotalRows={orderTotalRows}
              onChangeRowsPerPage={handlePerRowChange}
              onChangePage={handlePageChange}

            />
            <br />

          </>
        )}

        <Box sx={{ textAlign: 'right', mb: 3 }}>

          {(selectedOrders.length === 0 && selectedRiders.length === 0) ?
            <Button

              className={globalClasses.dashbordBtn}
              disabled// Disable if selected orders are less than selected riders
            >
              Assign Rider
            </Button>
            :
            (selectedOrders.length != 0 && selectedRiders.length === 0) ?
              <Button

                className={globalClasses.dashbordBtn}
                disabled// Disable if selected orders are less than selected riders
              >
                Assign Rider
              </Button>
              :
              <Button
                onClick={handleAssignRiders}
                className={globalClasses.dashbordBtn}
                disabled={selectedOrders.length < selectedRiders.length} // Disable if selected orders are less than selected riders
              >
                Assign Rider
              </Button>
          }

        </Box>
      </Container>
    </>
  );
};

export default withTranslation()(AssignRider);
