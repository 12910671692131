import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import DataTable from 'react-data-table-component'
import { customStyles } from '../utils/customerTableStyle'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Typography,
  Box,
  Switch,
  Modal
} from '@mui/material'
import TableHeader from '../components/TableHeader'
import useStyles from '../components/Section/styles'
import { useHistory } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Icon } from '@iconify/react'
import ChangeCustomerType from '../components/ChangeCustomerType/ChangeCustomerType'

import { gql } from 'apollo-boost'
import { useApolloClient, useQuery } from '@apollo/client'
import ViewWallet from '../components/ViewWallet/ViewWallet'
import { SentimentVeryDissatisfiedTwoTone } from '@mui/icons-material'
import ViewNestCash from '../components/ViewWallet/ViewNestCash'
import { GetAllCustomerTypes, GetCustomerDetailsById, GetCustomerWallet } from '../apollo'
import AddNestCash from '../components/AddNestCash/AddNestCash'
const GET_CUSTOMER_DETAILS = gql`
  ${GetCustomerDetailsById}
`

const GET_CUSTOMER_TYPE = gql`
  ${GetAllCustomerTypes}
`
const GET_CUSTOMER_WALLET = gql`
  ${GetCustomerWallet}
`
const ViewCustomerDetails = props => {
  //   const { t } = props;
  const golbalClasses = useGlobalStyles()
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { t } = props
  const history = useHistory()

  const userType = JSON.parse(localStorage.getItem('user-native')).userType
  const rowDataId = JSON.parse(localStorage.getItem('customerDetails'))
  const handleBackClick = () => {
    history.push('/cs_admin/allcustomers') // Navigate back to ManageCategory component
    localStorage.removeItem('customerDetails')
    localStorage.removeItem('customerTypeLabel')
  }
  const client = useApolloClient()
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [viewWalletModal, setViewWalletModal] = useState(false)
  const [viewNestCashModal, setNestCashModal] = useState(false)
  const [addNestCashModal, setaddNestCashModal] = useState(false)

  const { data: customerDetails, refetch } = useQuery(GET_CUSTOMER_DETAILS, {
    skip: !rowDataId, // Skip query if rowDataId is not available
    variables: { customerId: rowDataId ? rowDataId._id : null },
    onError: error => {
      const isForbiddenError =
        error &&
        error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN')
      if (isForbiddenError) {
        localStorage.removeItem('user-native')
        localStorage.removeItem('restaurant_id')
        client.clearStore()
        props.history.push('/auth/login')
      }
    }
  })
  const { data: customerWalletDetails } = useQuery(GET_CUSTOMER_WALLET, {
    onError: error => {
      const isForbiddenError =
        error &&
        error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN')
      if (isForbiddenError) {
        localStorage.removeItem('user-native')
        localStorage.removeItem('restaurant_id')
        client.clearStore()
        props.history.push('/auth/login')
      }
    }
  })
  const { data: workerStatus } = useQuery(GET_CUSTOMER_TYPE, {
    onError: error => {
      const isForbiddenError =
        error &&
        error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN')
      if (isForbiddenError) {
        localStorage.removeItem('user-native')
        localStorage.removeItem('restaurant_id')
        client.clearStore()
        props.history.push('/auth/login')
      }
    }
  })
  const optionsworkerStatus = workerStatus
    ? workerStatus.getAllCustomerTypes.map(type => ({
        label: type.name,
        customerType: type.customerType
      }))
    : []
  useEffect(() => {}, [optionsworkerStatus])
  const getCustomerTypeName = customerTypeId => {
    const customerType = optionsworkerStatus.find(
      type => type.customerType === customerTypeId
    )
    return customerType ? customerType.label : 'N/A'
  }
  const rowData = customerDetails
    ? customerDetails.getCustomerDetailsById
    : null
  const openaAddVendorModal = () => {
    setAddVendorModal(true)
  }
  const closeAddVendorModal = () => {
    setAddVendorModal(false)
  }
  const openaVeiwWalletModal = () => {
    setViewWalletModal(true)
  }
  const closeViewWalletModal = () => {
    setViewWalletModal(false)
  }
  const openaVeiwNestCahsModal = () => {
    setNestCashModal(true)
  }
  const closeViseNestCashModal = () => {
    setNestCashModal(false)
  }
  const openaAddNestCahsModal = () => {
    setaddNestCashModal(true)
  }
  const closeAddNestCashModal = () => {
    setaddNestCashModal(false)
   
  }
  // Example of using the state data
  useEffect(() => {
    if (rowData) {
      // Perform actions with rowData as needed
    }
  }, [addVendorModal])

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  if (!rowData) {
    return <div>Loading...</div>
  }
  const columns = [
    {
      name: 'Label',
      selector: 'label'
    },
    {
      name: 'Delivery Address',
      selector: 'deliveryAddress',
      wrap: true, // Enable text wrapping
      style: {
        textAlign: 'left' // align to the left
      }
    },
    {
      name: 'Details',
      selector: 'details'
    },

    {
      name: 'Selected',
      cell: row => <>{availableStatus(row)}</>
    }
  ]
  const availableStatus = row => {
    return (
      <>
        {row.selected}
        <Switch
          size="small"
          defaultChecked={row.selected}
          onChange={_event => {
            // mutateToggle({ variables: { id: row._id } })
          }}
          style={{ color: 'black' }}
        />
      </>
    )
  }

  return (
    <>
      <Header />

      <Container className={globalClasses.flex}>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            className={golbalClasses.dashbordBtn}
            onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
        <Box container className={classes.container}>
          <Box className={classes.flexRow}>
            <Box item className={classes.heading}>
              <Typography variant="h6" className={classes.textWhite}>
                {t('Customer Details')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.form} style={{ height: '237px' }}>
            <Grid container spacing={1} mt={1} className={classes.section}>
              <Grid item xs={6} md={6}>
                <Typography className={classes.headingText}>
                  Name : {rowData.name ? rowData.name : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.headingText}>
                  Phone Number : {rowData.phone ? rowData.phone : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}></Grid>
              {/* <Grid item xs={12} md={6} > <Typography  className={classes.headingText} >
       Order Count :{rowData.orderCount  ? rowData.orderCount    :"N/A"}
       </Typography>
     
       </Grid > */}
              <Grid item xs={12} md={6} container alignItems="center">
                {' '}
                <Typography
                  className={classes.headingText}
                  style={{ marginRight: '8px' }}>
                  Customer Type:{getCustomerTypeName(rowData.customerType)}
                </Typography>
                {userType === 1 ? (
                  <Box>
                    <Button
                      className={golbalClasses.dashbordBtn}
                      onClick={openaAddVendorModal}>
                      <Icon
                        icon="ic:baseline-edit"
                        style={{ color: 'white' }}
                      />{' '}
                      {t('Change')}
                    </Button>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={12} md={12} container alignItems="center">
                {' '}
             
                {userType === 1 ? (
                  <Box>
                    <Button
                      className={golbalClasses.dashbordBtn}
                      onClick={openaVeiwWalletModal}>
                      <Icon
                      width="20px" height="20px"
                        icon="flowbite:wallet-solid"
                        style={{ color: 'white' }}
                      />{' '}
                      {t('View Wallet')}
                    </Button>
                    <Button
                      className={golbalClasses.dashbordBtn}
                      onClick={openaVeiwNestCahsModal}>
                      <Icon
                      width="20px" height="20px"
                        icon="flowbite:wallet-solid"
                        style={{ color: 'white' }}
                      />{' '}
                      {t('View Nest Cash')}
                    </Button>
                    <Button
                      className={golbalClasses.dashbordBtn}
                      onClick={openaAddNestCahsModal}>
                      <Icon
                      width="20px" height="20px"
                        icon="flowbite:wallet-solid"
                        style={{ color: 'white' }}
                      />{' '}
                      {t('Add Nest Cash')}
                    </Button>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} md={12}>
                
              </Grid>
            </Grid>
          </Box>
        </Box>

        {addVendorModal ? (
          <Grid container>
            <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
              <Grid
                sx={{ display: { xs: 'none', lg: 'block' } }}
                item
                mt={5}
                ml={-2}
                order={{ xs: 1, lg: 2 }}>
                <Modal
                  open={addVendorModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClose={closeAddVendorModal}>
                  <ChangeCustomerType
                    data={rowData}
                    onClose={closeAddVendorModal}
                    reload={addVendorModal}
                    refetch={refetch}
                  />
                </Modal>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

{viewWalletModal ? (
          <Grid container>
            <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
              <Grid
                sx={{ display: { xs: 'none', lg: 'block' } }}
                item
                mt={5}
                ml={-2}
                order={{ xs: 1, lg: 2 }}>
                <Modal
                  open={viewWalletModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClose={closeViewWalletModal}>
                  <ViewWallet
                    data={rowData}
                    onClose={closeViewWalletModal}
                    reload={viewWalletModal}
                    refetch={refetch}
                  />
                </Modal>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

{viewNestCashModal ? (
          <Grid container>
            <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
              <Grid
                sx={{ display: { xs: 'none', lg: 'block' } }}
                item
                mt={5}
                ml={-2}
                order={{ xs: 1, lg: 2 }}>
                <Modal
                  open={viewNestCashModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClose={closeViseNestCashModal}>
                  <ViewNestCash
                    data={rowData}
                    onClose={closeViseNestCashModal}
                    reload={viewNestCashModal}
                    refetch={refetch}
                  />
                </Modal>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

{addNestCashModal ? (
          <Grid container>
            <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
              <Grid
                sx={{ display: { xs: 'none', lg: 'block' } }}
                item
                mt={5}
                ml={-2}
                order={{ xs: 1, lg: 2 }}>
                <Modal
                  open={addNestCashModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClose={closeAddNestCashModal}>
                  <AddNestCash
                  vendor={rowData}
                    data={rowData}
                    onClose={closeAddNestCashModal}
                    reload={addNestCashModal}
                    refetch={refetch}
                  />
                </Modal>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

<DataTable
                  subHeader={true}
                  title={<TableHeader title={t('Address Details')} />}
                  columns={columns}
                  data={rowData.addresses ? rowData.addresses : []}
                  pagination
                  // progressPending={loading}
                  progressComponent={<CustomLoader />}
                  // sortFunction={customSort}
                  // defaultSortField="email"
                  customStyles={customStyles}
                  // selectableRows
                />
      </Container>
    </>
  )
}

export default withTranslation()(ViewCustomerDetails)

