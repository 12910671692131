import React, { useRef, useState } from 'react';
import { useMutation, gql, useQuery, useApolloClient } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { GetWorkersByOrderStatus ,AdminAssignWorkerToOrder} from '../../apollo';
import { Button, Box, Typography, Grid, Paper, TextField, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';



const GET_WORKERSTATUS = gql`
  ${GetWorkersByOrderStatus}
`;

const ASSIGN_WORKER = gql`
  ${AdminAssignWorkerToOrder}
`;

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function AssignWorker(props) {
  const { onClose,  } = props;
  const formRef = useRef();
  const mutation =  ASSIGN_WORKER;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  // State to store uploaded images
  const [selectedWorker, setSelectedWorker] = useState(props.vendor ? props.vendor.storeName : null);
  const [formErrors, setFormErrors] = useState({});
  
  const client = useApolloClient();
const [workerError,setWorkerError]=useState(false)
  const [formData, setFormData] = useState({
    workerEmail: props.vendor ? props.vendor.workerEmail : '',
 
  }
);

  const [openSnackk, setopenSnackk] = useState(false);

  const {  data: workerStatus } = useQuery(GET_WORKERSTATUS, {
    variables: {
      orderId: props.data && props.data.getOrderDetailsById._id
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
  
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });
  const optionsworkerStatus = workerStatus ? workerStatus.getWorkersByOrderStatus || [] : [];

  const getOrderWorkerEmail = (orderState) => {
    switch (orderState.workerStatus) {
      case 1:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 2:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 3:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 4:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 5:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 6:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 7:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 8:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 9:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      default:
        return 'No Worker Assigned';
    }
  };

  const validateFormData = () => {
    let errors = {};
  
    if (!formData.workerEmail ||  formData.workerEmail === null  || formData.workerEmail === undefined ) {
      setWorkerError(true)
    }
   
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Worker Updated Successfully') : t('Worker Assigned Successfully');
    errorSetter('');
    successSetter(message);
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
    props.refetch()
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      errorSetter(error);
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)

  };
  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
   
    onError,
    onCompleted,
  });
  const clearFields = () => {
    formRef.current.reset();
    setFormErrors({});

    setFormData({
   
      workerEmail: '',
  
    });
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const handleWorkerChange = (event, value) => {
    setSelectedWorker(value);
    setFormData({ ...formData, workerEmail: value.email });
    if(formData.workerEmail !== undefined || formData.workerEmail !== null  ){
      setWorkerError(false)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      // setOpenSnack(true);
      return;
    }
    if (true) {
    
        mutate({
          variables:{
            orderId:props.data && props.data.getOrderDetailsById._id  ,
            workerEmail: formData.workerEmail
          }
        })

      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose(); // Close the modal
        }
      }, 2000);
    }
  };
  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>

      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Edit Product') : t('Assign Worker ')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>

          <Grid container spacing={2}>
            {!props.vendor ?
              <>
         <Grid item xs={12} md={12} container direction="row" alignItems="center">
  <Grid item xs={3} md={3}>
    <Typography className={classes.typographyOrder} style={{ color:"black"}}>
      Worker Email:
    </Typography>
  </Grid>
  <Grid item xs={9} md={9} container alignItems="center">
    <Typography className={classes.typographyOrder} style={{ marginRight: '8px', color:"black" }}>
      {props.data && props.data.getOrderDetailsById ? getOrderWorkerEmail(props.data.getOrderDetailsById.orderState) : "N/A"}
    </Typography>
   
  </Grid>
</Grid>

    
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={optionsworkerStatus}
                    getOptionLabel={(option) => option.email}
                    onChange={handleWorkerChange}
                    value={selectedWorker}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Worker List"
                        error={workerError}
                        helperText={workerError ? 'Please select a Worker' : ''}

                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />

                    )}
                    PaperComponent={CustomPaperComponent}
                    helperText={formErrors.workerEmail}
                    error={Boolean(formErrors.workerEmail)}
                  />
                </Grid>
               
              </>
              :
              ( // Conditionally render the second form fields based on editModal prop
                <>
          
                </>
              )
            }
          </Grid>

        </DialogContent>





        <DialogActions>
          <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
            {props.vendor ? 'Update Product' : 'Save'}
          </Button>

          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(AssignWorker);