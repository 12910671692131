import React from 'react'
import { Box, useMediaQuery,  } from '@mui/material'
function Header() {
  const matches = useMediaQuery('(min-width:1350px)')
  return (
    <>
      <Box
        sx={{
          height: 100, // Set the height of the header
          display: { xs: 'none', sm: 'block' },
          marginTop: { sm: -17, md: -14, lg: -12 },
          background: "transparent",
          marginLeft: matches ? 0 : -3.5,
          marginBottom: 2,
          position: 'fixed', // Make the header fixed
          top: 0, // Position it at the top
          width: '100%', // Make it span the full width
          zIndex: 1000, // Ensure it's above other content
        }}> </Box>
    </>
  )
}

export default Header
