const ar = {
    translation: {
        'Sign in credentials': 'بيانات تسجيل الدخول',
        'Sign in': 'تسجيل الدخول',
        'Add Category': 'إضافة فئة',
        Title: 'العنوان',
        Description: 'الوصف',
        Add: 'إضافة',
        Remove: 'إزالة',
        'Add/Remove': 'إضافة/إزالة',
        Success: 'نجاح',
        Danger: 'خطر',
        Categories: 'الفئات',
        Image: 'صورة',
        Header: 'رأس',
        Loading: 'جار التحميل',
        Error: 'خطأ',
        Edit: 'تحرير',
        Delete: 'حذف',
        'Edit Category': 'تحرير الفئة',
        Save: 'حفظ',
        'OrderID prefix': 'بادئة رقم الطلب',
        Order: 'الطلب',
        Email: 'البريد الإلكتروني',
        Password: 'كلمة المرور',
        Enable: 'تمكين',
        Disable: 'تعطيل',
        'Enable/Disable': 'تمكين/تعطيل',
        Saving: 'جار الحفظ',
        Paypal: 'بايبال',
        'Client ID': 'معرف العميل',
        'Client Secret': 'السر الخاص بالعميل',
        Sandbox: 'صندوق الرمل',
        'Publishable Key': 'مفتاح النشر',
        'Secret Key': 'المفتاح السري',
        'Delivery Charges': 'رسوم التوصيل',
        Price: 'السعر',
        'Add Food': 'إضافة طعام',
        Category: 'الفئة',
        Select: 'اختيار',
        Variations: 'تغييرات',
        Type: 'النوع',
        'Food Image': 'صورة الطعام',
        Foods: 'الأطعمة',
        Actions: 'الإجراءات',
        'Edit Food': 'تحرير الطعام',
        OrderID: 'رقم الطلب',
        Orders: 'الطلبات',
        Name: 'الاسم',
        Items: 'العناصر',
        Payment: 'الدفع',
        Status: 'الحالة',
        Review: 'المراجعة',
        'Mark as': 'وسم كـ',
        Users: 'المستخدمين',
        Phone: 'الهاتف',
        Address: 'العنوان',
        2019: '2019',
        'About Us': 'عنا',
        Blog: 'المدونة',
        Welcome: 'مرحبًا',
        Logout: 'تسجيل الخروج',
        Dashboard: 'لوحة التحكم',
        Configuration: 'التكوين',
        Login: 'تسجيل الدخول',
        Food: 'طعام',
        'Character limit of max length 50': 'الحد الأقصى للحروف (50)',
        'Character limit of max length 15': 'الحد الأقصى للحروف (15)',
        'Character limit of max length 60': 'الحد الأقصى للحروف (60)',
        'Character limit of max length 20': 'الحد الأقصى للحروف (20)',
        'Character limit of max length 140': 'الحد الأقصى للحروف (140)',
        Currency: 'العملة',
        Delivery: 'التوصيل',
        'Currency Code': 'رمز العملة',
        'Delivery Rate': 'سعر التوصيل',
        'Currency Symbol': 'رمز العملة',
        enterYourDetailsBelow: 'أدخل تفاصيلك أدناه',
        LogintoEnatega: 'تسجيل الدخول إلى NativeNest',
        RememberMe: 'تذكرني',
        ForgotYourPassword: 'نسيت كلمة المرور؟',
        errorWhileSaving: 'حدث خطأ أثناء الحفظ، حاول مرة أخرى',
        Saved: 'تم الحفظ',
        Addons: 'إضافات',
        AddRemoveAddon: 'إضافة أو إزالة الإضافة',
        MinQuantity: 'الحد الأدنى للكمية',
        MinimumQuantity: 'الحد الأدنى للكمية',
        MaxQuantity: 'الحد الأقصى للكمية',
        MaximumQuantity: 'الحد الأقصى للكمية',
        Options: 'الخيارات',
        NewOption: 'خيار جديد',
        CategoryUpdatedSuccessfully: 'تم تحديث الفئة بنجاح',
        CategoryAddedSuccessfully: 'تمت إضافة الفئة بنجاح',
        ActionFailedTryAgain: 'فشلت العملية. يرجى المحاولة مرة أخرى',
        PHCategory: 'الفئة، مثل وجبة الإفطار',
        ChooseCurrency: 'اختيار العملة',
        ChooseSymbol: 'اختيار الرمز',
        PHMail: 'على سبيل المثال، something@email.com',
        EmailName: 'اسم البريد الإلكتروني',
        PHEnatega: 'على سبيل المثال، Enatega',
        PHFood: 'على سبيل المثال، FOOD',
        ClientSecretKey: 'مفتاح العميل السري',
        Stripe: 'شريط',
        PublishKey: 'مفتاح النشر',
        CouponUpdated: 'تم تحديث القسيمة',
        CouponAdded: 'تمت إضافة القسيمة',
        Code: 'الرمز',
        PHCode: 'مثل SALE50',
        PHDiscount: 'نسبة الخصم، مثل 1-99',
        FoodUpdatedSuccessfully: 'تم تحديث الطعام بنجاح',
        FoodAddedSuccessfully: 'تمت إضافة الطعام بنجاح',
        SelectCategory: 'اختيار الفئة',
        UploadAnImage: 'تحميل صورة',
        UniqueTitle: 'العنوان (يجب أن يكون فريداً)',
        Discounted: 'بخصم',
        LoadingDots: 'جار التحميل ...',
        ErrorDots: 'خطأ ...',
        NewAddon: 'إضافة جديدة',
        EnategaMultivendor: 'Enatega Multivendor',
        ResetPassword: 'إعادة تعيين كلمة المرور',
        EnategaDashboard: 'لوحة تحكم Enatega',
        UpdateOption: 'تحديث الخيار',
        AddOption: 'إضافة الخيار',
        OrderStatusUpdated: 'تم تحديث حالة الطلب',
        SpecialInstructions: 'تعليمات خاصة',
        Charges: 'الرسوم',
        Subtotal: 'المجموع الفرعي',
        DeliveryFee: 'رسوم التوصيل',
        TaxCharges: 'رسوم الضرائب',
        Tip: 'بقشيش',
        Total: 'الإجمالي',
        PaymentMethod: 'طريقة الدفع',
        PaidAmount: 'المبلغ المدفوع',
        Accepted: 'تم القبول',
        Accept: 'قبول',
        Cancelled: 'تم الإلغاء',
        Cancel: 'إلغاء',
        PHReasonIfRejected: 'السبب إذا تم رفضه',
        Datetime: 'التاريخ والوقت',
        PasswordChangedSuccessfully: 'تم تغيير كلمة المرور بنجاح',
        ConfirmPassword: 'تأكيد كلمة المرور',
        Reset: 'إعادة تعيين',
        RestaurantAdded: 'تمت إضافة المطعم',
        NetworkError: 'خطأ في الشبكة',
        FieldsRequired: 'الحقول مطلوبة',
        Username: 'اسم المستخدم',
        RestaurantUsername: 'اسم مستخدم المطعم',
        RestaurantPassword: 'كلمة مرور المطعم',
        RestaurantName: 'اسم المطعم',
        RestaurantAddress: 'عنوان المطعم',
        DeliveryTime: 'وقت التوصيل',
        MinOrder: 'الحد الأدنى للطلب',
        SalesTax: 'ضريبة المبيعات',
        RiderUpdatedSuccessfully: 'تم تحديث الراكب بنجاح',
        RiderAddedSuccessfully: 'تمت إضافة الراكب بنجاح',
        EditRider: 'تحرير الراكب',
        AddRider: 'إضافة الراكب',
        Available: 'متاح',
        PHRiderName: 'اسم الراكب',
        Number: 'الرقم',
        PhoneNumber: 'رقم الهاتف',
        RiderZone: 'منطقة الراكب',
        SectionUpdatedSuccessfully: 'تم تحديث القسم بنجاح',
        SectionAddedSuccessfully: 'تمت إضافة القسم بنجاح',
        EditSection: 'تحرير القسم',
        AddSection: 'إضافة القسم',
        SectionName: 'اسم القسم',
        Update: 'تحديث',
        GENERAL: 'عام',
        MANAGEMENT: 'الإدارة',
        RESTAURANT: 'المطعم',
        BackToAdmin: 'العودة إلى الإدارة',
        Search: 'بحث',
        TippingUpdated: 'تم تحديث البقشيش',
        EditTipping: 'تحرير البقشيش',
        AddTipping: 'إضافة البقشيش',
        Tip1: 'البقشيش 1',
        Tip2: 'البقشيش 2',
        Tip3: 'البقشيش 3',
        PHTip1: 'البقشيش 1، مثل 10',
        PHTip2: 'البقشيش 2، مثل 20',
        PHTip3: 'البقشيش 3، مثل 30',
        EditVendor: 'تحرير البائع',
        AddVendor: 'إضافة بائع',
        PHNumber: 'مثل 123',
        ZoneUpdatedSuccessfully: 'تم تحديث المنطقة بنجاح',
        ZoneAddedSuccessfully: 'تمت إضافة المنطقة بنجاح',
        SetZoneOnMap: 'تحديد المنطقة على الخريطة',
        EditZone: 'تحرير المنطقة',
        AddZone: 'إضافة المنطقة',
        Minimum: 'الحد الأدنى',
        Maximum: 'الحد الأقصى',
        Action: 'الإجراء',
        AvailableAfterPurchasing: 'ستكون هذه الميزة متاحة بعد شراء المنتج',
        CommissionRates: 'أسعار العمولة',
        PHCommission: 'نسبة العمولة',
        Discount: 'خصم %',
        Coupons: 'قسائم',
        StartDate: 'تاريخ البداية',
        GraphFilter: 'مرشح الرسم البياني',
        EndDate: 'تاريخ الانتهاء',
        Apply: 'تطبيق',
        UpdatingLocationError: 'حدث خطأ أثناء تحديث الموقع والحدود',
        LocationMarkerRequired: 'مطلوب علامة الموقع',
        DeliveryAreaRequired: 'منطقة التوصيل مطلوبة',
        LocationUpdatedSuccessfully: 'تم تحديث الموقع والحدود بنجاح',
        SetLocation: 'تعيين الموقع',
        DrawDeliveryBounds: 'رسم حدود التوصيل',
        SetRestaurantLocation: 'تعيين موقع المطعم',
        RemoveDeliveryBounds: 'إزالة حدود التوصيل',
        RemoveRestaurantLocation: 'إزالة موقع المطعم',
        Reject: 'رفض',
        Delivered: 'تم التوصيل',
        OrderInformation: 'معلومات الطلب',
        RestaurantCol: 'مطعم',
        Rider: 'سائق',
        OrderTime: 'وقت الطلب',
        Dispatch: 'إرسال',
        PasswordUpdated: 'تم تحديث كلمة المرور',
        ForgotPassword: 'نسيت كلمة المرور',
        NotificationSentAuccessfully: 'تم إرسال الإشعار بنجاح',
        Notifications: 'الإشعارات',
        Body: 'المحتوى',
        Zones: "المناطق",
        RequestID: 'رقم الطلب',
        Amount: 'المبلغ',
        Date: 'التاريخ',
        REQUESTED: 'طُلب',
        TRANSFERRED: 'تم التحويل',
        CANCELLEDStatus: 'ملغاة',
        WithdrawRequests: 'طلبات السحب',
        TotalRestaurants: 'إجمالي المطاعم',
        Restaurants: 'مطاعم',
        Vendors: 'بائعون',
        RestaurantUpdatedSuccessfully: 'تم تحديث المطعم بنجاح',
        UpdateProfile: 'تحديث الملف الشخصي',
        PHRestaurantPassword: "كلمة مرور المطعم",
        PHRestaurantName: "اسم المطعم",
        PHRestaurantAddress: "عنوان المطعم",
        OrderPrefix: 'بادئة الطلب',
        Days: 'أيام',
        OpenTimes: "أوقات العمل",
        TimeSavedSuccessfully: 'تم حفظ الوقت بنجاح',
        ErrorWhileSavingTime: 'حدث خطأ أثناء حفظ الوقت',
        SavingDots: 'جاري الحفظ...',
        January: 'يناير',
        February: 'فبراير',
        March: 'مارس',
        April: 'إبريل',
        May: 'مايو',
        June: 'يونيو',
        July: 'يوليو',
        MainPageText: 'برمجيات متعددة المنصات',
        MainPageText1: 'حلا كاملا يصلح تمامًا لإنشاء أي مطعم.',
        ViewSite: 'عرض الموقع',
        TotalUsers: 'إجمالي المستخدمين',
        TotalVendors: 'إجمالي البائعين',
        TotalRiders: 'إجمالي السائقين',
        RestaurantSections: 'أقسام المطعم',
        ImageMenu: 'قائمة الصور',
        Vendor: 'بائع',
        AddNewRestaurant: 'إضافة مطعم جديد',
        Ratings: 'التقييمات',
        StripeDetailsAttached: 'تم إرفاق تفاصيل Stripe',
        EditStripeDetails: 'تحرير تفاصيل Stripe',
        SubmitStripeDetails: 'إرسال تفاصيل Stripe',
        "Restaurant Sections": 'أقسام المطعم',
        Riders: 'الفرسان',
        Tipping: 'البقشيش',
        Zone: 'المنطقة',
        "Commission Rates": "أسعار العمولة",
        "Withdraw Requests": "طلبات السحب",
        Home: 'الرئيسية',
        Profile: 'الملف الشخصي',
        Option: 'خيار',
        Timings: 'أوقات العمل',
        Location: 'الموقع',
        "Back to Admin": "العودة إلى لوحة التحكم",
        TotalOrders: 'إجمالي الطلبات',
        TotalSales: 'إجمالي المبيعات',
        ClosedAllDay: 'مغلق طوال اليوم',
        AddRestaurant: 'إضافة مطعم',
        EditCoupon: 'تعديل الكوبون',
        AddCoupon: 'إضافة كوبون',
        SalesAmount: 'مبلغ المبيعات',
        OrderCount: 'عدد الطلبات',
        MON: "الاثنين",
        TUE: "الثلاثاء",
        WED: "الأربعاء",
        THU: "الخميس",
        FRI: "الجمعة",
        SAT: "السبت",
        SUN: "الأحد",
        Open: "مفتوح"
    }
}

export default ar
