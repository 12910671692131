import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { Button, Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar, TextField } from '@mui/material';
import useStyles from '../components/AddLocalAdmin/styles';
import useGlobalStyles from '../utils/globalStyles';
import DataTable from 'react-data-table-component';
import { InitiateReplenishRequest } from '../apollo';
import TableHeader from '../components/TableHeader';
import { customStyles } from '../utils/tableForVerifyReplenish';
import { useHistory } from 'react-router-dom';

const CREATE_STOCKREQUEST = gql`
  ${InitiateReplenishRequest}
`;

const VerifyStockRequestModal = ({ open, onClose, editedRows, t, refetch, selectedRows }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [comment, setComment] = useState('');

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const reasonOptions = [
    { label: "NEED_REPLENISH", id: 0 },
    { label: "QTY_SAME_CS", id: 1 },
    { label: "QTY_CHANGE_CS", id: 2 },
    { label: "QTY_NOT_AVAILABLE", id: 3 },
    { label: "QTY_DAMAGED_TRANSIT", id: 4 },
    { label: "QTY_MISSING_TRANSIT", id: 5 },
    { label: "ITEM_REMOVED", id: 6 },
    { label: "REPLENISHED", id: 7 }
  ];

  const hideAlert = () => {
    setSuccessMessage('');
    setOpenSnackbar(false);
    onClose();
    localStorage.removeItem('selectedRowIds'); // Remove selectedRowIds from local storage
    localStorage.removeItem('editedRows');
    history.push('/billing_admin/repletion')
  };
  const [mutate, { loading: mutateLoading }] = useMutation(CREATE_STOCKREQUEST, {
    onCompleted: (data) => {
      setSuccessMessage('Requested Successfully');
      setOpenSnackbar(true);
      setTimeout(hideAlert, 2000);
      localStorage.removeItem('selectedRowIds'); // Remove selectedRowIds from local storage
      localStorage.removeItem('editedRows');

      refetch();
      localStorage.setItem('newId', JSON.stringify(data));
      // Close the modal after successful API request
    },
    onError: (error) => {
      setErrorMessage(error.message || 'Something went wrong!');
      setOpenSnackbar(true);
      refetch();
    },
  });

  const handleOpenCommentModal = () => {
    setOpenCommentModal(true);
  };

  const handleCloseCommentModal = () => {
    setOpenCommentModal(false);
  };

  const handleConfirm = () => {
    const payload = {
      replenishRequestInput: {
        comments: comment,
        requestedItems: editedRows.map((item) => ({
          productId: item._id,
          reason: item.reason ? item.reason : 0,
          requestedQty: parseFloat(item.requestedQty), // Assuming localQty is the requested quantity
          skuId: item.skuId,
          stockId: item.stockId,
        })),
      },
    };

    mutate({ variables: payload });
    handleCloseCommentModal();
  };


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getReasonLabel = (reasonId) => {
    const reason = reasonOptions.find(option => option.id === reasonId);
    return reason ? reason.label : 'NEED REPLENISH';
  };

  const columns = [
    {
      name: "Product Name",
      selector: 'name',
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Require Quantity",
      cell: row => row.requestedQty !== undefined ? row.requestedQty : 0,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: "Local Quantity",
      selector: 'localQty',
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: "Minimum Quantity",
      selector: 'minimumQty',
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: "Default Quantity",
      selector: 'defaultLSQty',
      style: { cursor: 'pointer', minWidth: "150px" },
    },

    {
      name: "SKU ID",
      selector: 'skuId',
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: "Reason",
      cell: row => getReasonLabel(row.reason),
      style: { cursor: 'pointer', minWidth: "200px" },
    },
  ];
  const inputTextStyle = { color: 'black' };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{ '& .MuiDialog-paper': { minWidth: '80vw', minHeight: '80vh' } }} // Increase width and height
      >
        <DialogTitle>
          <Box className={classes.heading}>
            <Typography className={classes.textWhite}>
              {t('Requesting  Stock')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <DataTable
            subHeader={true}
            title={<TableHeader title={t(' Replention List ')} />}
            columns={columns}
            data={editedRows}
            customStyles={customStyles}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={globalClasses.dashbordBtn}
            onClick={handleOpenCommentModal}
            disabled={mutateLoading}
          >
            {t('Submit')}
          </Button>
          <Button
            className={globalClasses.modalCancleBtn}
            disabled={mutateLoading}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
        </DialogActions>
        <Box mt={2}>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {successMessage ? (
              <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                {successMessage}
              </Alert>
            ) : (
              <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                {errorMessage}
              </Alert>
            )}
          </Snackbar>
        </Box>
      </Dialog>

      <Dialog
        open={openCommentModal}
        onClose={handleCloseCommentModal}
      >
        <DialogTitle>  <Box className={classes.heading}>
          <Typography className={classes.textWhite}>
            {t('Write Your   Comment')}
          </Typography>
        </Box></DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            multiline
            rows={4}
            inputProps={{ style: inputTextStyle }}
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary" disabled={!comment || mutateLoading} className={globalClasses.dashbordBtn} >
            {t('Confirm')}
          </Button>
          <Button onClick={handleCloseCommentModal} color="primary" disabled={mutateLoading} className={globalClasses.modalCancleBtn} >
            {t('Cancel')}
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
};

export default withTranslation()(VerifyStockRequestModal);
