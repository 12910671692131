import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { gql, useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import Header from '../components/Headers/Header';
import CustomLoader from '../components/Loader/CustomLoader';
import { GetAllCustomerTypes, GetOrderDeliveryItemStats, GetOrderDeliveryStats } from '../apollo';
import DataTable from 'react-data-table-component';
import { customStyles } from '../utils/tableStyleForOrderStatusByItem';
import {
  Container, Button, Grid, Box, Typography, TextField
} from '@mui/material';
import { Icon } from '@iconify/react';
import useGlobalStyles from '../utils/globalStyles';
import TableHeader from '../components/TableHeader';

// GraphQL queries
const GET_ALL_ORDER_STATS = gql`${GetOrderDeliveryItemStats}`;
const GET_CUSTOMER_TYPE = gql`
  ${GetAllCustomerTypes}
`
const OrderSTatusByIems = (props) => {
  const { t } = props;
  const [orderStatsData, setOrderStatsData] = useState([]); // To hold order stats data
  const golbalClasses = useGlobalStyles();
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [EndDateError, setEndDateError] = useState(''); // State to hold end date error
  const client = useApolloClient();

  const { data: workerStatus } = useQuery(GET_CUSTOMER_TYPE, {
    onError: error => {
      const isForbiddenError =
        error &&
        error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN')
      if (isForbiddenError) {
        localStorage.removeItem('user-native')
        localStorage.removeItem('restaurant_id')
        client.clearStore()
        props.history.push('/auth/login')
      }
    }
  })
  // Fetch order stats based on date
  const [fetchOrderStats, { loading: statsLoading, data: statsData }] = useLazyQuery(GET_ALL_ORDER_STATS, {
    variables: {
      start: StartDate,
      end: EndDate,
    },
    onCompleted: (data) => {
      setOrderStatsData(data && data.getOrderDeliveryItemStats || []); // Update the table data
    },
    onError: (error) => {
      console.error("Error fetching stats:", error);
    }
  });


  const optionsworkerStatus = workerStatus
    ? workerStatus.getAllCustomerTypes.map(type => ({
      label: type.name,
      customerType: type.customerType
    }))
    : []

  const getCustomerTypeName = customerTypeId => {
    const customerType = optionsworkerStatus.find(
      type => type.customerType === customerTypeId
    )
    return customerType ? customerType.label : 'N/A'
  }
  const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    const isoString = date.toISOString(); // This will give you the date in "2024-09-04T00:00:00.000Z" format
    return isoString.replace('Z', '+00:00'); // Replacing 'Z' with '+00:00' to match your required format
  }
  const handleFetchStats = () => {
    let startDate = formatDateToISO(StartDate);
    let endDate = formatDateToISO(EndDate);
    endDate = endDate.split('T')[0] + 'T23:59:00.000+00:00';

    if (StartDate && EndDate) {
      fetchOrderStats(
        {
          variables: {
            start: startDate,
            end: endDate,
          }
        }
      );
    } else {
      alert('Please select both start and end dates.');
    }
  };

  const processOrderData = (orders) => {
    const flattenedData = [];

    orders.forEach((order) => {
      const { _id: orderId, paymentMethod, customerType, orderAmount, items } = order;

      items.forEach((item, index) => {
        // Find the selling price that matches the customerType
        const priceInfo = item.prices.find(price => price.customerType === customerType);
        const sellingPrice = priceInfo ? priceInfo.sellingPrice : null;

        flattenedData.push({
          ...item,
          orderId: index === 0 ? orderId : "", // Show orderId only with the first item
          paymentMethod: index === 0 ? paymentMethod : "", // Show paymentMethod only with the first item
          customerType: index === 0 ? getCustomerTypeName(customerType) : "", // Show customerType only with the first item
          orderAmount: index === 0 ? orderAmount : "", // Show orderAmount only with the first item
          price: sellingPrice // Set the calculated selling price based on customerType
        });
      });
    });

    return flattenedData;
  };

  // Define columns for the table
  const columns = [
    { name: "Sl No.", cell: (row, index) => <>{index + 1}</> },
    {
      name: "Order ID", selector: 'orderId',
      cell: (row) => <>{row.orderId.slice(-5)}</>,
      sortable: true, style: { cursor: 'pointer', minWidth: "200px" }
    },
    {
      name: "Payment Method",
      cell: (row) => <>{row.paymentMethod === 0 ? 'COD' : row.paymentMethod === 1 ? 'Online' : ''}</>,
      sortable: true
    },
    { name: "Customer Type", selector: 'customerType', sortable: true },
    { name: "Order Amount", selector: 'orderAmount', sortable: true },
    { name: "Product Name", selector: 'name', sortable: true, style: { cursor: 'pointer', minWidth: "200px" } },
    { name: "Product ID", selector: 'productId', sortable: true, style: { cursor: 'pointer', minWidth: "200px" } },
    { name: "SKU ID", selector: 'skuId', sortable: true, style: { cursor: 'pointer', } },
    { name: "Total Quantity", selector: 'quantity', sortable: true },
    { name: "MRP", selector: 'mrp', sortable: true },
    { name: "Seeling Price", selector: 'price', sortable: true } // New column for calculated selling price
  ];

  const orderData = processOrderData(orderStatsData);


  const processOrderDataCSV = (orders) => {
    const flattenedData = [];

    orders.forEach((order) => {
      const { _id: orderId, paymentMethod, customerType, orderAmount, items } = order;

      items.forEach((item) => {
        // Find the selling price that matches the customerType
        const priceInfo = item.prices.find(price => price.customerType === customerType);
        const sellingPrice = priceInfo ? priceInfo.sellingPrice : null;

        flattenedData.push({
          ...item,
          orderId,                // Assign orderId to each item
          paymentMethod,          // Assign paymentMethod to each item
          customerType,           // Assign customerType to each item
          orderAmount,            // Assign orderAmount to each item
          price: sellingPrice      // Set calculated selling price
        });
      });
    });

    return flattenedData;
  };
  const orderDataCSV = processOrderDataCSV(orderStatsData);
  const downloadCSV = () => {
    const csvRows = [];

    // Adding the start and end date range as the first row
    const dateRange = `Start Date: ${StartDate}, End Date: ${EndDate}`;
    csvRows.push(dateRange);  // Add the date range to the CSV

    // Adding a blank row for spacing between date range and column headers
    csvRows.push('');

    // Extracting column titles (excluding the "Sl No." column)
    const headers = columns
      .filter(col => col.name !== "Sl No.")  // Filter out the "Sl No." column
      .map((col) => col.name);
    csvRows.push(headers.join(','));  // Add headers

    // Extracting rows of data with processed values
    orderDataCSV.forEach(row => {
      const rowData = columns
        .filter(col => col.name !== "Sl No.")  // Filter out the "Sl No." column
        .map(col => {
          if (col.name === "Order ID") {
            // Use full Order ID for each row
            return row.orderId || '';
          } else if (col.name === "Payment Method") {
            // Format Payment Method for each row
            return row.paymentMethod === 0 ? 'COD' : row.paymentMethod === 1 ? 'Online' : '';
          } else if (col.name === "Customer Type") {
            // Use customerType for each row
            return getCustomerTypeName(row.customerType) || '';
          } else if (col.selector) {
            // Default: Use selector if available
            return row[col.selector] || '';
          }
          return '';
        });
      csvRows.push(rowData.join(','));  // Add processed row data
    });

    // Creating the CSV Blob and triggering the download
    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'order_status_by_orderId.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  // Handle End Date change with validation
  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (new Date(newEndDate) < new Date(StartDate)) {
      setEndDateError('End date cannot be earlier than start date.');
    } else {
      setEndDateError('');
      setEndDate(newEndDate);
    }
  };

  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
        <Box sx={{ mb: 3, mt: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <TextField
                label="Select Start Date"
                type="date"
                value={StartDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{
                  width: '100%',
                  input: { color: 'black' },
                  '.MuiInputBase-root': { color: 'black' },
                  '& label.Mui-focused': { color: 'black' },
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Select End Date"
                type="date"
                value={EndDate}
                onChange={handleEndDateChange}
                error={!!EndDateError}
                helperText={EndDateError}
                InputLabelProps={{ shrink: true }}
                sx={{
                  width: '100%',
                  input: { color: 'black' },
                  '.MuiInputBase-root': { color: 'black' },
                  '& label.Mui-focused': { color: 'black' },
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Button onClick={handleFetchStats} className={golbalClasses.dashbordBtn}>
                <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('Fetch Status')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button onClick={downloadCSV} className={golbalClasses.dashbordBtn}>
                <Icon icon="ic:round-download" style={{ color: "white" }} /> {t('Download CSV')}
              </Button>
            </Grid>
          </Grid>

        </Box>

        {statsLoading ? (
          <CustomLoader />
        ) : (
          <DataTable
            title={<TableHeader title={t('Order Status By Order ID')} />}
            columns={columns}
            data={orderData || []}
            pagination
            customStyles={customStyles}
          />
        )}
      </Container>
    </>
  );
};

export default withTranslation()(OrderSTatusByIems);



