
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import Header from '../components/Headers/Header';
import CustomLoader from '../components/Loader/CustomLoader';
import { GetAllReplenishRequests } from '../apollo';
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { customStyles } from '../utils/tableForLSManageReplenation';
import useGlobalStyles from '../utils/globalStyles';
import {
    Container,
    Paper,
    Box,
    Autocomplete,
    TextField
} from '@mui/material';
import TableHeader from '../components/TableHeader';
import 'jspdf-autotable';


const GET_REPLENTION_PRODUCTS = gql`${GetAllReplenishRequests}`;


const CustomPaperComponent = (props) => (
    <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

const LSRepletion = props => {

    const { t } = props;

    const [editMode, setEditMode] = useState(null);
    const golbalClasses = useGlobalStyles();
    const client = useApolloClient();
    const [page, setPage] = useState(1);
    const [tabledata, setTabledata] = useState(null);
    const [currentPage, setCurrentPage] = useState(page);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [selectedStatus, setSelectedStatus] = useState(6); // Default to ALL
    const inputTextStyle = {
        color: 'black', height: '30px', // Adjust as needed
        padding: '0 14px'
    };
    const statusOptions = [
        { label: 'REQUESTED', value: 0 },
        { label: 'APPROVED', value: 1 },
        { label: 'APPROVED WITH CHANGE', value: 2 },
        { label: 'RECEIVED', value: 3 },
        { label: 'RECEIVED WITH CHANGE', value: 4 },
        { label: 'CANCELLED', value: 5 },
        { label: 'ALL', value: 6 },
    ];


    const variables = {
        status: selectedStatus,
        limit: perPage,
        skip: (page - 1) * perPage,
    };


    const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
        GET_REPLENTION_PRODUCTS, {
        variables,
        onCompleted: (data, errors) => {
            setTabledata(data && data.getAllReplenishRequests.repleshishRequestSummary ? data.getAllReplenishRequests.repleshishRequestSummary : []);
            setTotalRows(data.getAllReplenishRequests.totalCount);
        },
        onError: error => {
            const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
            if (isForbiddenError) {
                localStorage.removeItem('user-native');
                localStorage.removeItem('restaurant_id');
                client.clearStore();
                props.history.push('/auth/login');
            }
        },
    }
    );



    useEffect(() => {
        if (data && data.getAllReplenishRequests.repleshishRequestSummary) {
            setTabledata(data && data.getAllReplenishRequests.repleshishRequestSummary ? data.getAllReplenishRequests.repleshishRequestSummary : []);
            setTotalRows(data.getAllReplenishRequests.totalCount);
        }

    }, [data]);

    useEffect(() => {
        refetch({
            variables: {
                status: selectedStatus,
                limit: perPage,
                skip: (page - 1) * perPage,
            },
        });
    }, [page, perPage, refetch]);

    const handlePerRowChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handlePageChange = (page) => {
        setPage(page);
        setCurrentPage(page);
    };

    const handleSaveClick = (row) => {
        // Add logic to save the edited values here
        // For example, make an API call to save the edited data
        setEditMode(null);
    };

    const handleCancelClick = () => {
        setEditMode(null);
    };
    const handleStatusChange = (event, newValue) => {
        if (newValue) {
            setSelectedStatus(newValue.value);
        }
    };

    const columns = useMemo(() => [
        {
            name: "Central Store Name",
            sortable: true,
            selector: 'centralStoreName',
            style: { cursor: 'pointer', minWidth: "150px" },
        },
        {
            name: "Local Store Name",
            sortable: true,
            selector: 'localStoreName',
            style: { cursor: 'pointer', minWidth: "150px" },
        },
        {
            name: "Requested By",
            sortable: true,
            selector: 'requestedByEmail',
            style: { cursor: 'pointer', minWidth: "150px" },
        },
        {
            name: "Requested Date",
            sortable: true,
            selector: 'requestedDate',
            cell: row => new Date(row.requestedDate).toLocaleString(), // Format date
            style: { cursor: 'pointer', minWidth: "150px" },
        },
        {
            name: "Status",
            sortable: true,
            selector: 'statusString',
            style: { cursor: 'pointer', minWidth: "150px" },
        },
        {
            name: "Requested Count",
            sortable: true,
            selector: 'itemCount.requestedCount',
            style: { cursor: 'pointer', minWidth: "150px" },
        },
        {
            name: "Approved Count",
            sortable: true,
            selector: 'itemCount.approvedCount',
            style: { cursor: 'pointer', minWidth: "150px" },
        },
        {
            name: "Received Count",
            sortable: true,
            selector: 'itemCount.receivedCount',
            style: { cursor: 'pointer', minWidth: "150px" },
        },

    ], [editMode, handleSaveClick, handleCancelClick]);

    const customSort = useCallback((rows, selector, direction) => {
        const handleField = row => {
            if (typeof selector === 'function') {
                const data = selector(row);
                return data ? data.toString().toLowerCase() : "";
            }
            return "";
        };
        return orderBy(rows, handleField, direction);
    }, []);


    return (
        <>
            <Header />
            <Container className={golbalClasses.flex}>
                {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
                {loadingQuery ? (
                    <CustomLoader />
                ) : (
                    <>

                        <Box sx={{ textAlign: 'right', mb: 3 }}>



                        </Box>

                        <DataTable
                            subHeader={true}
                            subHeaderComponent={
                                <Box style={{ marginTop: -40 }}>
                                    <Autocomplete
                                        options={statusOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={statusOptions.find(option => option.value === selectedStatus)}
                                        onChange={handleStatusChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Filter By Status"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    style: inputTextStyle
                                                }}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px' // Adjust height
                                                    },
                                                    '& .MuiAutocomplete-inputRoot': {
                                                        padding: '0 0 0 14px !important' // Adjust padding
                                                    }
                                                }}
                                            />
                                        )}
                                        sx={{ width: 300, ml: 2 }}
                                        PaperComponent={CustomPaperComponent}

                                    />
                                </Box>

                            }
                            title={<TableHeader title={t(' LS Manage Replention   ')} />}
                            columns={columns}
                            data={tabledata || []}
                            progressPending={loadingQuery}
                            progressComponent={<CustomLoader />}
                            paginationDefaultPage={currentPage}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowChange}
                            onChangePage={handlePageChange}
                            sortFunction={customSort}

                            customStyles={customStyles}
                        />
                    </>
                )}
            </Container>
        </>
    );
};

export default withTranslation()(LSRepletion);
