import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";

//Firebase Config values imported from .env file
const firebaseConfig = {
  

    apiKey: "AIzaSyB4TvRpmfExUIBMq-C6NZCwS3jYrrfXnlU",
  authDomain: "nativenest-df7bc.firebaseapp.com",
  projectId: "nativenest-df7bc",
  storageBucket: "nativenest-df7bc.appspot.com",
  messagingSenderId: "48490142042",
  appId: "1:48490142042:web:c261f992298bbeb52ffb63",
  measurementId: "G-BFM0P2RX2Q"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);