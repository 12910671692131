const fr = {
  translation: {
    "Sign in credentials": "Identifiez-vous",
    "Sign in": "se connecter",
    "Add Category": "ajouter une catégorie",
    Title: "Titre",
    Description: "La description",
    Add: "Ajouter",
    Remove: "Retirer",
    "Add/Remove": "Ajouter enlever",
    Success: "Succès",
    Danger: "Danger",
    Categories: "Les catégories",
    Image: "Image",
    Header: "Entête",
    Loading: "Chargement",
    Error: "Erreur",
    Edit: "modifier",
    Delete: "Effacer",
    "Edit Category": "Modifier la catégorie",
    Save: "sauvegarder",
    "OrderID prefix": "Préfixe OrderID",
    Order: "Ordre",
    Email: "Email",
    Password: "Mot de passe",
    Enable: "Activer",
    Disable: "Désactiver",
    "Enable/Disable": "Activer désactiver",
    Saving: "Économie",
    Paypal: "Pay Pal",
    "Client ID": "identité du client",
    "Client Secret": "Secret du client",
    Sandbox: "bac à sable",
    "Publishable Key": "Clé publiable",
    "Secret Key": "Clef secrète",
    "Delivery Charges": "Frais de livraison",
    Price: "Prix",
    "Add Food": "Ajouter de la nourriture",
    Category: "Catégorie",
    Select: "Sélectionner",
    Variations: "Variations",
    Type: "Type",
    "Food Image": "Image de nourriture",
    Foods: "nourriture",
    Actions: "actes",
    "Edit Food": "Modifier un aliment",
    OrderID: "Numéro de commande",
    Orders: "Ordres",
    Name: "prénom",
    Items: "Articles",
    Payment: "Paiement",
    Status: "Statut",
    Review: "La revue",
    "Mark as": "Marquer comme",
    Users: "Utilisateurs",
    Phone: "Téléphone",
    Address: "Adresse",
    2019: "2019",
    "About Us": "À propos de nous",
    Blog: "Blog",
    Welcome: "Bienvenue",
    Logout: "Connectez - Out",
    Dashboard: "Tableau de bord",
    Configuration: "Configuration",
    Login: "S'identifier",
    Food: "Aliments",
    "Character limit of max length 50": "Limite de caractères de longueur maximale 50",
    "Character limit of max length 15": "Limite de caractères de longueur maximale 15",
    "Character limit of max length 60": "Limite de caractères de longueur maximale 60",
    "Character limit of max length 20": "Limite de caractères de longueur maximale 20",
    "Character limit of max length 140": "Limite de caractères de longueur maximale 140",
    Currency: "Devise",
    Delivery: "Livraison",
    "Currency Code": "Code de devise",
    "Delivery Rate": "Frais de livraison",
    "Currency Symbol": "Symbole de devise",
    enterYourDetailsBelow: "Entrez vos détails ci-dessous",
    LogintoEnatega: "Connectez-vous à NativeNest",
    RememberMe: "Souviens-toi de moi",
    ForgotYourPassword: "Mot de passe oublié ?",
    errorWhileSaving: "Une erreur s'est produite lors de l'enregistrement, réessayez",
    Saved: "Enregistré",
    Addons: "Modules complémentaires",
    AddRemoveAddon: "Ajouter ou supprimer un module complémentaire",
    MinQuantity: "Quantité minimale",
    MinimumQuantity: "Quantité minimale",
    MaxQuantity: "Quantité maximale",
    MaximumQuantity: "Quantité maximale",
    Options: "Options",
    NewOption: "Nouvelle option",
    CategoryUpdatedSuccessfully: "Catégorie mise à jour avec succès",
    CategoryAddedSuccessfully: "Catégorie ajoutée avec succès",
    ActionFailedTryAgain: "Action échouée. Veuillez réessayer",
    PHCategory: "Catégorie, par exemple le petit déjeuner",
    ChooseCurrency: "Choisissez la devise",
    ChooseSymbol: "Choisissez le symbole",
    PHMail: "par exemple quelquechose@email.com",
    EmailName: "Nom de l'email",
    PHEnatega: "par exemple Enatega",
    PHFood: "par exemple NOURRITURE",
    ClientSecretKey: "Clé secrète du client",
    Stripe: "Stripe",
    PublishKey: "Clé de publication",
    CouponUpdated: "Coupon mis à jour",
    CouponAdded: "Coupon ajouté",
    Code: "Code",
    PHCode: "Code, par exemple SALE50",
    PHDiscount: "Remise %, c'est-à-dire 1-99",
    FoodUpdatedSuccessfully: "Nourriture mise à jour avec succès",
    FoodAddedSuccessfully: "Nourriture ajoutée avec succès",
    SelectCategory: "Sélectionnez une catégorie",
    UploadAnImage: "Téléchargez une image",
    UniqueTitle: "Titre (doit être unique)",
    Discounted: "Remisé",
    LoadingDots: "Chargement...",
    ErrorDots: "Erreur...",
    NewAddon: "Nouveau module complémentaire",
    EnategaMultivendor: "Enatega Multivendor",
    ResetPassword: "Réinitialiser le mot de passe",
    EnategaDashboard: "Tableau de bord Enatega",
    UpdateOption: "Mettre à jour l'option",
    AddOption: "Ajouter une option",
    OrderStatusUpdated: "Statut de la commande mis à jour",
    SpecialInstructions: "Instructions spéciales",
    Charges: "Frais",
    Subtotal: "Sous-total",
    DeliveryFee: "Frais de livraison",
    TaxCharges: "Frais de taxe",
    Tip: "Pourboire",
    Total: "Total",
    PaymentMethod: "Moyen de paiement",
    PaidAmount: "Montant payé",
    Accepted: "Acceptée",
    Accept: "Accepter",
    Cancelled: "Annulée",
    Cancel: "Annuler",
    PHReasonIfRejected: "Raison si rejetée",
    Datetime: "Date et heure",
    PasswordChangedSuccessfully: "Mot de passe changé avec succès",
    ConfirmPassword: "Confirmer le mot de passe",
    Reset: "Réinitialiser",
    RestaurantAdded: "Restaurant ajouté",
    NetworkError: "Erreur réseau",
    FieldsRequired: "Champs requis",
    Username: "Nom d'utilisateur",
    RestaurantUsername: "Nom d'utilisateur du restaurant",
    RestaurantPassword: "Mot de passe du restaurant",
    RestaurantName: "Nom du restaurant",
    RestaurantAddress: "Adresse du restaurant",
    DeliveryTime: "Temps de livraison",
    MinOrder: "Commande minimum",
    SalesTax: "Taxe de vente",
    RiderUpdatedSuccessfully: "Livreur mis à jour avec succès",
    RiderAddedSuccessfully: "Livreur ajouté avec succès",
    EditRider: "Modifier le livreur",
    AddRider: "Ajouter un livreur",
    Available: "Disponible",
    PHRiderName: "Nom du livreur",
    Number: "Numéro",
    PhoneNumber: "Numéro de téléphone",
    RiderZone: "Zone du livreur",
    SectionUpdatedSuccessfully: "Section mise à jour avec succès",
    SectionAddedSuccessfully: "Section ajoutée avec succès",
    EditSection: "Modifier la section",
    AddSection: "Ajouter une section",
    SectionName: "Nom de la section",
    Update: "Mettre à jour",
    GENERAL: "GÉNÉRAL",
    MANAGEMENT: "GESTION",
    RESTAURANT: "RESTAURANT",
    BackToAdmin: "Retour à l'administration",
    Search: "Recherche",
    TippingUpdated: "Pourboire mis à jour",
    EditTipping: "Modifier le pourboire",
    AddTipping: "Ajouter un pourboire",
    Tip1: "Pourboire 1",
    Tip2: "Pourboire 2",
    Tip3: "Pourboire 3",
    PHTip1: "Pourboire 1, par exemple 10",
    PHTip2: "Pourboire 2, par exemple 20",
    PHTip3: "Pourboire 3, par exemple 30",
    EditVendor: "Modifier le vendeur",
    AddVendor: "Ajouter un vendeur",
    PHNumber: "par exemple 123",
    ZoneUpdatedSuccessfully: "Zone mise à jour avec succès",
    ZoneAddedSuccessfully: "Zone ajoutée avec succès",
    SetZoneOnMap: "Définir la zone sur la carte",
    EditZone: "Modifier la zone",
    AddZone: "Ajouter une zone",
    Minimum: "Minimum",
    Maximum: "Maximum",
    Action: "Action",
    AvailableAfterPurchasing: "Cette fonctionnalité sera disponible après l'achat du produit",
    CommissionRates: "Taux de commission",
    PHCommission: "Pourcentage de commission",
    Discount: "Remise %",
    Coupons: "Coupons",
    StartDate: "Date de début",
    GraphFilter: "Filtre graphique",
    EndDate: "Date de fin",
    Apply: "Appliquer",
    UpdatingLocationError: "Une erreur s'est produite lors de la mise à jour de l'emplacement et des limites",
    LocationMarkerRequired: "Le marqueur d'emplacement est requis",
    DeliveryAreaRequired: "La zone de livraison est requise",
    LocationUpdatedSuccessfully: "Emplacement et limites mis à jour avec succès",
    SetLocation: "Définir l'emplacement",
    DrawDeliveryBounds: "Dessiner les limites de livraison",
    SetRestaurantLocation: "Définir l'emplacement du restaurant",
    RemoveDeliveryBounds: "Supprimer les limites de livraison",
    RemoveRestaurantLocation: "Supprimer l'emplacement du restaurant",
    Reject: "Rejeter",
    Delivered: "Livré",
    OrderInformation: "Informations sur la commande",
    RestaurantCol: "Restaurant",
    Rider: "Livreur",
    OrderTime: "Heure de commande",
    Dispatch: "Expédition",
    PasswordUpdated: "Mot de passe mis à jour",
    ForgotPassword: "Mot de passe oublié",
    NotificationSentAuccessfully: "Notification envoyée avec succès",
    Notifications: "Notifications",
    Body: "Corps",
    Zones: "Zones",
    RequestID: "ID de demande",
    Amount: "Montant",
    Date: "Date",
    REQUESTED: "DEMANDÉ",
    TRANSFERRED: "TRANSFÉRÉ",
    CANCELLEDStatus: "ANNULÉE",
    WithdrawRequests: "Demandes de retrait",
    TotalRestaurants: "Total des restaurants",
    Restaurants: "Restaurants",
    Vendors: "Vendeurs",
    RestaurantUpdatedSuccessfully: "Restaurant mis à jour avec succès",
    UpdateProfile: "Mettre à jour le profil",
    PHRestaurantPassword: "Mot de passe du restaurant",
    PHRestaurantName: "Nom du restaurant",
    PHRestaurantAddress: "Adresse du restaurant",
    OrderPrefix: "Préfixe de commande",
    Days: "Jours",
    OpenTimes: "Heures d'ouverture",
    TimeSavedSuccessfully: "Heure sauvegardée avec succès",
    ErrorWhileSavingTime: "Erreur lors de la sauvegarde de l'heure",
    SavingDots: "Enregistrement...",
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    MainPageText: "Un logiciel multiplateforme",
    MainPageText1: "Une solution complète parfaitement adaptée pour construire n'importe quel restaurant.",
    ViewSite: "Voir le site",
    TotalUsers: "Utilisateurs totaux",
    TotalVendors: "Vendeurs totaux",
    TotalRiders: "Livreurs totaux",
    RestaurantSections: "Sections du restaurant",
    ImageMenu: "Menu image",
    Vendor: "Vendeur",
    AddNewRestaurant: "Ajouter un nouveau restaurant",
    Ratings: "Évaluations",
    StripeDetailsAttached: "Détails Stripe attachés",
    EditStripeDetails: "Modifier les détails Stripe",
    SubmitStripeDetails: "Soumettre les détails Stripe",
    "Restaurant Sections": "Sections du restaurant",
    Riders: "Cavaliers",
    Tipping: "Pourboire",
    Zone: "Zone",
    "Commission Rates": "Taux de commission",
    "Withdraw Requests": "Demandes de retrait",
    Home: "Accueil",
    Profile: "Profil",
    Option: "Option",
    Timings: "Horaires",
    Location: "Emplacement",
    "Back to Admin": "Retour à l'administration",
    TotalOrders: "Total des commandes",
    TotalSales: "Total des ventes",
    ClosedAllDay: "Fermé toute la journée",
    AddRestaurant: "Ajouter un restaurant",
    EditCoupon: "Modifier le coupon",
    AddCoupon: "Ajouter un coupon",
    SalesAmount: "Montant des ventes",
    OrderCount: "Nombre de commandes",
    MON: "Lun",
    TUE: "Mar",
    WED: "Mer",
    THU: "Jeu",
    FRI: "Ven",
    SAT: "Sam",
    SUN: "Dim",
    Open: "Ouvert"
  }
}

export default fr
