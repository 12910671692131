
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { getproductsforsa, SearchProducts } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableForProduct'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper,
  Box,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import AddProduct from '../components/AddProduct/AddProduct'
import { Icon } from '@iconify/react'
import SearchBar from '../components/TableHeader/SearchBar'

const GET_PRODUCTS = gql`
  ${getproductsforsa}
`

const GET_SEARCH_PRODUCTS = gql`
  ${SearchProducts}
`

const Products = props => {
  const { t } = props
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const golbalClasses = useGlobalStyles()

  const [searchQuery, setSearchQuery] = useState('')
  const [tabledata, setTabledata] = useState([])
  const [relaod, setReload] = useState(false)
  
  const [page, setPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(page)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  
  const client = useApolloClient()

  // Configure query variables
  const queryVariables = {
    limit: perPage,
    skip: (page - 1) * perPage,
    ...(searchQuery && { text: searchQuery })
  }

  // Single query that handles both search and regular products
  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    searchQuery ? GET_SEARCH_PRODUCTS : GET_PRODUCTS,
    {
      variables: queryVariables,
      // skip: false, // Don't skip the query
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const products = searchQuery 
          ?data &&  data.searchProducts.products 
          :data &&  data.getProducts.products
        const totalCount = searchQuery 
          ? data && data.searchProducts.totalCount 
          :data &&  data.getProducts.totalCount
        
        setTabledata(products || [])
        setTotalRows(totalCount || 0)
      },
      onError: error => {
        const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN')
        if (isForbiddenError) {
          localStorage.removeItem('user-native')
          localStorage.removeItem('restaurant_id')
          client.clearStore()
          props.history.push('/auth/login')
        }
      }
    }
  )

  // Debounced search handler
  const onChangeSearch = e => {
    const value = e.target.value
    setSearchQuery(value)
  }

  // Reset search and filters
  const onClickRefetch = () => {
    setSearchQuery('')
    setPage(1)
    refetch({
      variables: {
        limit: perPage,
        skip: 0
      }
    })
  }

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
    setPage(1)
localStorage.setItem("perPageLocal" ,newPerPage)
localStorage.setItem("pageLocal" ,1)
  }

  const handlePageChange = (page) => {
    setPage(page)
    setCurrentPage(page)
  
localStorage.setItem("pageLocal" ,page)
  }

  // Modal handlers
  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  const openaAddVendorModal = () => {
    setAddVendorModal(true)
  }

  const closeAddVendorModal = () => {
    setAddVendorModal(false)
  }

  const closeEditModal = () => {
    setEditModal(false)
  }

  // Custom sort function
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }

  // Table columns configuration
  const columns = [
    {
      name: "Product ID",
      selector: '_id',
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Product Name',
      cell: row => row.displayName,
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    // {
    //   name: 'Description',
    //   cell: row => row.description,
    //   style: { cursor: 'pointer', minWidth: "300px" },
    // },
    {
      name: 'Aliases',
      cell: row => row.aliases.join(', ')
    },
    {
      name: 'Category Name',
      cell: row => row.category.name,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Catalog Name',
      cell: row => row.catalog.name
    },
    {
      name: 'Images ',
      cell: row => (
        <div>
          {row.images.length > 0 &&
            row.images.map((imag, index) => (
              <img key={index} src={imag} alt={`Image ${index + 1}`} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
            ))}
        </div>
      )
    },
    {
      name: t('Action'),
      cell: row => <ActionButtons row={row} toggleModal={toggleModal} />
    }
  ]

  // Separate ActionButtons component to prevent unnecessary re-renders
  const ActionButtons = React.memo(({ row, toggleModal }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    
    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-haspopup="true"
          onClick={handleClick}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Paper>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            <MenuItem
              onClick={() => {
                handleClose()
                toggleModal(row)
              }}
              style={{ height: 25 }}>
              <ListItemIcon>
                <EditIcon fontSize="small" style={{ color: 'green' }} />
              </ListItemIcon>
              <Typography color="green">{t('Edit')}</Typography>
            </MenuItem>
            <MenuItem
              disabled
              style={{ height: 25 }}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" style={{ color: 'red' }} />
              </ListItemIcon>
              <Typography color="red">{t('Delete')}</Typography>
            </MenuItem>
          </Menu>
        </Paper>
      </div>
    )
  })

  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button
            className={golbalClasses.dashbordBtn}
            onClick={openaAddVendorModal}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('AddNewProduct')}
          </Button>
        </Box>
        
        {addVendorModal && (
          <Modal
            open={addVendorModal}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClose={closeAddVendorModal}
          >
            <AddProduct onClose={closeAddVendorModal} reload={addVendorModal} refetch={refetch} />
          </Modal>
        )}

        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        
        <DataTable
          subHeader={true}
          subHeaderComponent={
            <SearchBar
              value={searchQuery}
              onChange={onChangeSearch}
              onClick={onClickRefetch}
            />
          }
          title={<TableHeader title={t('Manage Products')} />}
          columns={columns}
          data={tabledata}
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          paginationDefaultPage={currentPage}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowChange}
          onChangePage={handlePageChange}
          sortFunction={customSort}
          customStyles={customStyles}
        />

        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={closeEditModal}>
          <AddProduct vendor={vendors} onClose={closeEditModal} reload={addVendorModal} refetch={refetch} />
        </Modal>
      </Container>
    </>
  )
}

export default withTranslation()(Products)
