export const customStyles = {
  header: {
    style: {
      fontSize: '17px',
      fontWeight: 'bold',
      minHeight: '40px',
      paddingLeft: '16px',
      paddingRight: '8px',
      borderRadius: '20px 20px 0 0',
      textWrap: "nowrap"
    }
  },
  headRow: {
    style: {
      backgroundColor: '#637BFE',
      color: '#FFFFFF',
      fontSize: '16px',
      fontWeight: 'bold',
      minHeight: '40px',
      borderRadius: '0 0 20px 20px',
      borderBottom: 'none',
      alignItems: 'center',
      textAlign: 'center',
      textWrap: "nowrap"
    },
    denseStyle: {
      minHeight: '32px'
    }
  },
  subHeader: {
    style: {
      minHeight: '0px',
      marginTop: -14,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.09)'
    }
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: 'white',
      fontWeight: 'bold',
      '&:nth-of-type(2)': { // Target the first column (Product Name) header cell
        minWidth: '250px', // Set the width specifically for Product Name column
      },
    
      '&:nth-of-type(4)': { // Target the first column (Product Name) header cell
          minWidth: '250px', // Set the width specifically for Product Name column
        },
        // '&:nth-of-type(5)': { // Target the fourth column (Catalog Name) header cell
        //   minWidth: '300px', // Set the width specifically for Catalog Name column
        // },
        '&:nth-of-type(5)': { // Target the fourth column (Catalog Name) header cell
          minWidth: '300px', // Set the width specifically for Catalog Name column
        },
        '&:nth-of-type(6)': { // Target the fourth column (Catalog Name) header cell
          minWidth: '300px', // Set the width specifically for Catalog Name column
        },
    }
  },
  fixedHeader: { // New style for fixed header cells
    style: {
      backgroundColor: '#637BFE',
      color: '#FFFFFF',
      fontSize: '16px',
      fontWeight: 'bold',
      minHeight: '40px',
      minWidth: "100px !important", // Adjust based on your content
      borderBottom: 'none',
      textAlign: 'center',
      textWrap: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  rows: {
    style: {
      fontSize: '13px', 
      fontFamily: ['Euclid Circular A', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
      fontOpticalSizing: "auto",
      fontWeight: 400,
      fontStyle: "normal",
      color: "black",
      minHeight: '50px',
      minWidth: "100px !important", // Adjust based on your content
      textAlign: 'center',
      whiteSpace: 'normal', // Allow text wrapping
      wordWrap: 'break-word', // Break long words and wrap text to fit container
      wordBreak: 'break-word', // Break words at allowed break points to prevent overflow
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '0px'
      },
      '&:hover': {
        backgroundColor: '#f9fafc'
      }
    }
  },
  pagination: {
    style: {
      fontSize: '13px',
      minHeight: '50px',
      borderRadius: '0 0 20px 20px',
      textAlign: 'left'
    },
    pageButtonsStyle: {
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: 'red',
        fill: '#5A5858'
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#EBF1FEd'
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#3E79F7'
      }
    }
  }
};
