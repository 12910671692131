import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { useQuery, useMutation, gql, useApolloClient } from '@apollo/client'
import { deletecsadmin, getlsadmin } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableStyleForLocalUsers'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper,
   Box, Snackbar,
  Alert
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { Icon } from '@iconify/react';
import AddLocalAdmin from '../components/AddLocalAdmin/AddLocalAdmin'


const GET_ALL_LOCAL_ADMIN = gql`
${getlsadmin}
`
const DELETE_CSADMIN = gql`
${deletecsadmin}`
const ManageLocalAdmins = props => {
  const { t } = props
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)

  const [vendors, setVendor] = useState(null)
  const client = useApolloClient();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertOpen, setAlertOpen] = useState(false);
  const userType = JSON.parse(localStorage.getItem('user-native'))
  const { data, error: errorQuery, loading: loadingQuery, refetch } = useQuery(
    GET_ALL_LOCAL_ADMIN, {
    variables: {
      storeId: userType.storeId
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
    skip: false // Ensure the query is executed every time the component is mounted

  })
  const [mutate] = useMutation(DELETE_CSADMIN, {
    refetchQueries: [{ query: GET_ALL_LOCAL_ADMIN }],
    onCompleted: () => {
      setAlertMessage('Deleted successfully');
      setAlertSeverity('success');
      setAlertOpen(true);
    },
    onError: (error) => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      } else {
        setAlertMessage('Something went wrong');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  });
  const golbalClasses = useGlobalStyles()
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };

  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };

  const columns = [
    {
      name: "Sl No.",
      cell: (row, index) => <>{index + 1}</>,
    },
    {
      name: 'Name',
      sortable: true,
      selector: 'name',
    },
    {
      name: 'Email',
      sortable: true,
      selector: 'email',
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: 'Phone',
      sortable: true,
      selector: 'phone',
    },
    {
      name: 'Store Name',
      sortable: true,
      selector: 'storeName',
    },
    {
      name: 'Address',
      sortable: true,
      selector: 'contact_address',
    },

    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>,
    },
  ];



  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const handleSort = (column, sortDirection) =>{

  }




  const filtered =
  data && data.getLSAdminsForStore
     

  const globalClasses = useGlobalStyles()
  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    // Manually trigger refetch when the component mounts
    refetch();
  }, []);
  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  
                  setTimeout(() => {
                    
                  }, 5000)
                  // uncomment this for paud version
                  toggleModal(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
              <MenuItem
                disabled
                onClick={e => {
                  e.preventDefault()
                
                  setTimeout(() => {
                    
                  }, 5000)
                  // uncomment this for paud version
                  mutate({ variables: { adminId: row._id } });
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }
  return (
    <>
      <Header />

      <Container classNname={globalClasses.flex} fluid>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={openaAddVendorModal}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} />  {t('Add New local Admin User')}
          </Button>
        </Box>
        {addVendorModal ? <Grid container>
          {/* <Grid item xs={12} order={{ xs: 2, lg: 1 }}> */}
          <Grid
            sx={{ display: { xs: 'none', lg: 'block' } }}
            item
            mt={5}
            ml={-2}
            order={{ xs: 1, lg: 2 }}
          >
            <Modal
              open={addVendorModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClose={closeAddVendorModal}
            >
              <AddLocalAdmin onClose={closeAddVendorModal} refetch={refetch} />
            </Modal>
          </Grid>  </Grid> : null}
        {errorQuery ? (
          <span>
            `${t('Error')}! ${errorQuery.message}`
          </span>
        ) : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
            title={<TableHeader title={t('Manage Local Admin Users')} />}
            columns={columns}
            data={filtered}
            pagination
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            onSort={handleSort}
            sortFunction={customSort}
            customStyles={customStyles}
            // selectableRows
            paginationIconLastPage=""
            paginationIconFirstPage=""
          />
        )}

        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <AddLocalAdmin vendor={vendors} editModal={editModal} onClose={closeEditModal} refetch={refetch} />
        </Modal>
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
          <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Container>
    </>
  )
}
export default withTranslation()(ManageLocalAdmins)
