import React, { useRef, useState } from 'react';
import { useMutation, gql, useQuery, useApolloClient } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { GetAllCustomerTypes, GetCustomerWallet, GetCustomerWalletByOrder, UpdateCustomerType } from '../../apollo';
import { Button, Box, Typography, Grid, Paper, TextField, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import { Icon } from '@iconify/react';
import useStyles from '../ChangeCustomerType/styles';
import useGlobalStyles from '../../utils/globalStyles';

const GET_CUSTOMER_TYPE = gql`
  ${GetAllCustomerTypes}
`;
const GET_CUSTOMER_WALLET = gql`
  ${GetCustomerWalletByOrder}
`;
const CHANGE_CUSTOMER_TYPE = gql`
  ${UpdateCustomerType}
`;

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function ViewWalletBYOrder(props) {
  const { onClose } = props;
  const formRef = useRef();
  const mutation = CHANGE_CUSTOMER_TYPE;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const [selectedWorker, setSelectedWorker] = useState(props.vendor ? props.vendor.storeName : null);
  const [formErrors, setFormErrors] = useState({});
  const client = useApolloClient();
  const [workerError, setWorkerError] = useState(false);
  const [formData, setFormData] = useState({
    customerType: props.vendor ? props.vendor.customerType : '',
  });
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const [openSnackk, setopenSnackk] = useState(false);

  const { data: customerWalletDetails } = useQuery(GET_CUSTOMER_WALLET, {
    variables: {
      orderId: props.data ? props.data._id : null,
      skip: 0,
      limit: 10

    },
    onError: (error) => {
      const isForbiddenError = error && error.graphQLErrors.some((err) => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });


  const optionsworkerStatus = customerWalletDetails

  const validateFormData = () => {
    let errors = {};

    // Check for null or undefined, but not 0
    if (formData.customerType === null || formData.customerType === undefined || formData.customerType === "") {
      setWorkerError(true);
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const onCompleted = (data) => {
    if (data && data.updateCustomerType) {
      const message = t('Customer Type Updated Successfully');
      errorSetter('');
      successSetter(message);
      setTimeout(hideAlert, 3000);
      setopenSnackk(true);
      props.refetch()
    }
  };

  const onError = (error) => {
    successSetter('');
    if (error.graphQLErrors) {
      errorSetter(error.graphQLErrors ? error.graphQLErrors[0].message : '');
      setopenSnackk(true);
    } else if (error.networkError) {
      errorSetter(error.networkError.result.errors[0].message);
    } else {
      errorSetter('Something went wrong!');
    }
    setTimeout(hideAlert, 3000);
    setopenSnackk(true);
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false);
  };


  const handleWorkerChange = (event, value) => {
    setSelectedWorker(value);
    setFormData({ ...formData, customerType: value ? value.customerType : '' });
    if (formData.customerType !== undefined || formData.customerType !== null) {
      setWorkerError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      return;
    }
    mutate({
      variables: {
        "customerId": props.data && props.data._id,
        "type": formData.customerType,
      },
    });
    setTimeout(() => {
      if (typeof props.onClose === 'function') {
        props.onClose();
      }
    }, 2000);
  };
  const getCustomerTypeName = (customerTypeId) => {
    // const customerType = optionsworkerStatus.find(type => type.customerType === customerTypeId);
    // return customerType ? customerType.label : 'N/A';
  };
  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Edit Product') : t('Walet Details ')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {!props.vendor ?
              <>
                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                      Order ID :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                    <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                      {customerWalletDetails && customerWalletDetails.getCustomerWalletByOrder ? customerWalletDetails.getCustomerWalletByOrder.customerId : "N/A"}

                    </Typography>
                  </Grid>
                </Grid>


                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                      Wallet Balanace:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                    <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>
                      <Icon icon="mdi:rupee" width="14" height="14" style={{ color: " black", }} />
                      {customerWalletDetails && customerWalletDetails.getCustomerWalletByOrder ? customerWalletDetails.getCustomerWalletByOrder.balance : "N/A"}

                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                      Transaction Count :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                    <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                      {customerWalletDetails && customerWalletDetails.getCustomerWalletByOrder ? customerWalletDetails.getCustomerWalletByOrder.totalTxnCount ? customerWalletDetails.getCustomerWalletByOrder.totalTxnCount : "N/A" : "N/A"}

                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                      Transactions :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                    <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                      {customerWalletDetails && customerWalletDetails.getCustomerWalletByOrder ? customerWalletDetails.getCustomerWalletByOrder.transactions ? customerWalletDetails.getCustomerWalletByOrder.transactions : "N/A" : "N/A"}

                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                      Message :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                    <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                      {customerWalletDetails && customerWalletDetails.getCustomerWalletByOrder ? customerWalletDetails.getCustomerWalletByOrder.message : "N/A"}

                    </Typography>
                  </Grid>
                </Grid>
              </>
              :
              <>
                {/* Additional fields for vendor */}
              </>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
            {props.vendor ? 'Update Product' : 'Save'}
          </Button> */}
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}

export default withTranslation()(ViewWalletBYOrder);
