import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { gql, useApolloClient, useMutation, useLazyQuery } from '@apollo/client';
import Header from '../components/Headers/Header';
import CustomLoader from '../components/Loader/CustomLoader';
import { UpgradeAllCustomerToPremium } from '../apollo';
import DataTable from 'react-data-table-component';
import { customStyles } from '../utils/tableCustomStyles';
import {
  Container, Button, Grid, Box, Typography, TextField
} from '@mui/material';
import { Icon } from '@iconify/react';
import useGlobalStyles from '../utils/globalStyles';
import TableHeader from '../components/TableHeader';

// GraphQL mutation
const UPGRADECUSTOMER = gql`${UpgradeAllCustomerToPremium}`;

const UpgradeCustomer = (props) => {
  const { t } = props;
  const golbalClasses = useGlobalStyles();
  const [orderStatsData, setOrderStatsData] = useState([]); // To hold order stats data
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [EndDateError, setEndDateError] = useState(''); // State to hold end date error

  // Mutation to upgrade all customers to premium
  const [upgradeCustomers, { loading: upgradeLoading, data: upgradeData }] = useMutation(UPGRADECUSTOMER, {
    onCompleted: (data) => {
      if (data) {
        setOrderStatsData(data.upgradeAllCustomerToPremium); // Update the table with the upgraded customers
      }
    },
    onError: (error) => {
      console.error('Error upgrading customers:', error);
    },
  });

  // Format date to required ISO format
  const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    const isoString = date.toISOString(); // Format: "2024-09-04T00:00:00.000Z"
    return isoString.replace('Z', '+00:00'); // Replacing 'Z' with '+00:00' to match the required format
  };

  // Handle "Upgrade All Customer" button click
  const handleUpgradeCustomers = () => {
    if (EndDate) {
      const formattedEndDate = formatDateToISO(EndDate).split('T')[0] + 'T23:59:00.000+00:00'; // Format end date

      // Call the mutation with the formatted expiry date
      upgradeCustomers({
        variables: {
          expiryDate: formattedEndDate, // Pass formatted end date
        },
      });
    } else {
      alert('Please select an end date.');
    }
  };

  // Handle End Date change with validation
  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (new Date(newEndDate) < new Date(StartDate)) {
      setEndDateError('End date cannot be earlier than start date.');
    } else {
      setEndDateError('');
      setEndDate(newEndDate);
    }
  };

  // Define columns for the table
  const columns = [
    { name: 'Sl No.', cell: (row, index) => <>{index + 1}</> },
    { name: 'Customer Name', selector: 'name', sortable: true, style: { cursor: 'pointer', minWidth: '200px' } },
    { name: 'Customer Phone', selector: 'phone', sortable: true, style: { cursor: 'pointer', minWidth: '200px' } },
    { name: 'Customer ID', selector: '_id', sortable: true, style: { cursor: 'pointer', minWidth: '200px' } },
  ];

  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
        <Box sx={{ mb: 3, mt: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <TextField
                label="Select End Date"
                type="date"
                value={EndDate}
                onChange={handleEndDateChange}
                error={!!EndDateError}
                helperText={EndDateError}
                InputLabelProps={{ shrink: true }}
                sx={{
                  width: '100%',
                  input: { color: 'black' },
                  '.MuiInputBase-root': { color: 'black' },
                  '& label.Mui-focused': { color: 'black' },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Button onClick={handleUpgradeCustomers} className={golbalClasses.dashbordBtn}>
                <Icon icon="ic:twotone-plus" style={{ color: 'white' }} /> {t('Upgrade All Customers')}
              </Button>
            </Grid>
          </Grid>
        </Box>

        {upgradeLoading ? (
          <CustomLoader />
        ) : (
          <DataTable
            title={<TableHeader title={t('Upgraded Customers')} />}
            columns={columns}
            data={orderStatsData.upgraded || []} // Display upgraded customers
            pagination
            customStyles={customStyles}
          />
        )}
      </Container>
    </>
  );
};

export default withTranslation()(UpgradeCustomer);
