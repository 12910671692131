import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useLazyQuery } from '@apollo/client' // added useLazyQuery for fetching stats
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { getalllocalstore, deletelocalstore, GetOrderDeliveryStats, getorderFilter } from '../apollo' // importing GetOrderDeliveryStats
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableForSaleReport'
import { Icon } from '@iconify/react';
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Typography, Box, 
  TextField,
  Link
} from '@mui/material'
import TableHeader from '../components/TableHeader'

// GraphQL queries
const GET_ALL_ORDER_STATS = gql`${GetOrderDeliveryStats}`
const GET_ALL_ORDER_filter = gql`
  ${getorderFilter}
`

const DayWiseOrderStats = (props) => {
  const { t } = props;
  const [orderStatsData, setOrderStatsData] = useState([]) // To hold order stats data
  const golbalClasses = useGlobalStyles()
  const [endDateError, setEndDateError] = useState('');
  
  // Format dates
  const formatToYYYYMMDD = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];  // Convert to "YYYY-MM-DD" format
  };
  
  const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    const isoString = date.toISOString(); // This will give you the date in "2024-09-04T00:00:00.000Z" format
    return isoString.replace('Z', '+00:00'); // Replacing 'Z' with '+00:00' to match your required format
  };
  
  const [page, setPage] = useState(
    1
  );
  var [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [formData, setFormData] = useState({
    start: '',
    end: ''
  });
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [isFetchingCsvData, setIsFetchingCsvData] = useState(false);
  const client = useApolloClient();
  const userType = JSON.parse(localStorage.getItem('user-native'))

  // Fetch order stats based on date
  const [fetchOrderStats, { loading: statsLoading, error: statsError, data: statsData }] = useLazyQuery(GET_ALL_ORDER_STATS, {
    variables: {
      start: formData.start,
      end: formData.end,
    },
    onCompleted: (data) => {
      setOrderStatsData(data && data.getOrderDeliveryStats || []); // Update the table data
    },
    onError: (error) => {
      console.error("Error fetching stats:", error);
    }
  });
  const [refetchfilterData, { loading:loadingQuery }] = useLazyQuery(GET_ALL_ORDER_filter, {
    fetchPolicy: 'no-cache',

    onCompleted: (resultData) => {
      if (resultData && resultData.getAllOrdersByFilter) {
        tableData = resultData.getAllOrdersByFilter.orders || []
        setTableData(tableData);
        setTotalRows(resultData.getAllOrdersByFilter.totalCount || 0);
       
        // If fetching for CSV, update csvData
    if (isFetchingCsvData) {
      setCsvData(tableData);
      setIsFetchingCsvData(false);
    }
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  });

  const [refetchfilterDataForCSV] = useLazyQuery(GET_ALL_ORDER_filter, {
    fetchPolicy: 'no-cache',

    onCompleted: (resultData) => {
      if (resultData && resultData.getAllOrdersByFilter) {
        tableData = resultData.getAllOrdersByFilter.orders || []
        setCsvData(tableData);

        downloadCSV(tableData)
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  });
  function getOrderStatusColor(orderStatusCode) {
    switch (orderStatusCode) {
      case 0: return "grey"; // Initiated
      case 1: return "blue"; // Confirmed
      case 2: return "orange"; // Packing
      case 3: return "purple"; // Order billing
      case 4: return "darkblue"; // Ready for delivery
      case 5: return "teal"; // Rider Assigned
      case 6: return "green"; // Out for delivery
      case 7: return "lightgreen"; // Delivered
      case 8: return "red"; // Cancelled
      case 9: return "darkred"; // Out of stock
      case 10: return "brown"; // Invalid delivery address
      case 12: return "yellow"; // No Items Cart
      default: return "black"; // Failed
    }
  }
  const getWorkerStatus = (status) => {
    const statusMap = {
      0: 'Not Started',
      1: 'Pp Assigned',
      2: 'Pp In Progress',
      3: 'Pp Completed',
      4: 'Billing Assigned',
      5: 'Billing In Progress',
      6: 'Billing Completed',
      7: 'Rider Assign Manual',
      8: "Rider Assigned",
      9: 'Rider Out For Delivery',
      10: 'Rider Delivered'
    };
    return statusMap[status] || 'N/A';
  };

  function CheckOrderStatus(orderStatusCode) {

    if (orderStatusCode === 0) {
      return "Initiated"
    }
    else if (orderStatusCode === 1) {
      return "Confirmed"
    }
    else if (orderStatusCode === 2) {
      return "Packing"
    }
    else if (orderStatusCode === 3) {
      return "Order billing"
    }
    else if (orderStatusCode === 4) {
      return "Ready for delivery"
    }
    else if (orderStatusCode === 5) {
      return "Rider Assigned"
    }
    else if (orderStatusCode === 6) {
      return "Out for delivery"
    }
    else if (orderStatusCode === 7) {
      return "Delivered"
    }
    else if (orderStatusCode === 8) {
      return "Cancelled"
    }
    else if (orderStatusCode === 9) {
      return "Out of stock"
    }
    else if (orderStatusCode === 10) {
      return "Invalid delivery address"
    }
    else if (orderStatusCode === 12) {
      return "No Items Cart"
    }
    else {
      return "Failed"
    }

  }
  const getOrderWorkerEmail = (orderState) => {
    switch (orderState.workerStatus) {
      case 1:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "NA"
      case 2:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "NA"
      case 3:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "NA"
      case 4:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "NA";
      case 5:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "NA";
      case 6:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "NA";
      case 7:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "NA";
      case 8:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "NA";
      case 9:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "NA";
        case 10:
          return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "NA";
      default:
        return 'No Worker Assigned';
    }
  };

  const handleEndDateChange = (value) => {
    const newEndDate = value.target.value;
    if (newEndDate < StartDate) {
      setEndDateError('End date cannot be less than start date.');
    } else {
      setEndDateError('');
      setEndDate(newEndDate);
      const formattedEndDate = formatDateToISO(newEndDate);
      setFormData({ ...formData, end: formattedEndDate });
    }
  };

  const handleFetchStats = () => {
    if (formData.start && formData.end) {
      let startDate = formatDateToISO(formData.start);
      let endDate = formatDateToISO(formData.end);
      endDate = endDate.split('T')[0] + 'T23:59:00.000+00:00';
    
      refetchfilterData({
        variables: {
          filterInput: {
            startDate: startDate,
            endDate: endDate,
          },
          limit: perPage,
          skip: (page - 1) * perPage,
        }
      });
      // setIsFetchingCsvData(true);
      // refetchfilterData({
      //   variables: {
      //     filterInput: { startDate, endDate },
      //     limit: 100000, // Fetch all rows
      //     skip: 0,
      //   }
      // });
    } else {
      alert('Please select both start and end dates.');
    }
  };

  const handleFetchStatsCVS = () => {
    if (formData.start && formData.end) {
      let startDate = formatDateToISO(formData.start);
      let endDate = formatDateToISO(formData.end);
      endDate = endDate.split('T')[0] + 'T23:59:00.000+00:00';
    
    
      setIsFetchingCsvData(true);
      refetchfilterDataForCSV({
        variables: {
          filterInput: { startDate, endDate },
          limit: totalRows ?totalRows : 100000 , // Fetch all rows
          skip: 0,
        }
      });
    } else {
      alert('Please select both start and end dates.');
    }
  };
  const getPaymentMethod = (statusNumber) => {
    const statusMap = {
      0: "COD",
      1: "ONLINE",

    };

    return statusMap[statusNumber] || "N/A";
  };

// Function to convert the deliveryDate into ISO format
function convertToISO(deliveryDateString) {
  // Remove ordinal suffix using regex
  const cleanedDate = deliveryDateString.replace(/(\d+)(st|nd|rd|th)/, "$1");

  // Extract the date and time portions
  const [datePart, timeRange] = cleanedDate.split(",");
  const [startTime, endTime] = timeRange.split(" - ");

  // Parse the date and start time into a Date object
  const parsedDate = new Date(`${datePart} ${startTime}`);

  // Return the ISO string
  return parsedDate.toISOString();
}

// Function to convert the deliveryDate into 'dd/MM/yyyy hh:mm AM/PM - hh:mm AM/PM' format
function convertDeliveryDate(deliveryDateString) {
  // Remove ordinal suffix (st, nd, rd, th) using regex
  const cleanedDate = deliveryDateString.replace(/(\d+)(st|nd|rd|th)/, "$1");

  // Extract the date and time portions
  const [datePart, timeRange] = cleanedDate.split(",");
  const [startTime, endTime] = timeRange.split(" - ");

  // Parse the date
  const parsedDate = new Date(`${datePart} ${startTime}`);

  // Format the date as dd/MM/yyyy
  const formattedDate = parsedDate.toLocaleDateString('en-GB'); // This will give the 'dd/MM/yyyy' format

  // Format the start and end times in 12-hour format with AM/PM
  const formattedStartTime = new Date(`${datePart} ${startTime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  const formattedEndTime = new Date(`${datePart} ${endTime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

  // Return the formatted date and time range
  return `${formattedDate} ${formattedStartTime} - ${formattedEndTime}`;
}
// Function to convert Unix timestamp to a readable date and time
function convertTimestampToReadableDate(timestamp) {
  const date = new Date(parseInt(timestamp, 10));
  return date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true // For 12-hour format with AM/PM
  });
}

function convertTimestampToReadableDateCSV(timestamp) {
  const date = new Date(parseInt(timestamp, 10));

  // Format date and time separately without commas
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true // For 12-hour format with AM/PM
  });

  // Return date and time concatenated without commas
  return `${formattedDate} ${formattedTime}`;
}


  const columns = [
    {
      name: 'SL No',
      selector: 'slNo',
      cell: (row, index) => <>{index + 1}</>, // Use index + 1 for SL No
      style: { cursor: 'pointer'},
    },
    {
      name: 'Order ID',
      selector: '_id', // Assuming "_id" is the field containing the order ID
      cell: (row) => <>{row._id.slice(-5)}</>, // Display the full Order ID
      style: { cursor: 'pointer' },
    },
    {
      name: 'Customer Name',
      selector: 'customerName', // Use customerName from data
      cell: (row) => <>{row.customerName}</>, // Display the customer name
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Customer Phone',
      selector: 'customerPhone',
      cell: (row) => <>{row.customerPhone}</>, // Display customer phone
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Order Date',
      selector: 'orderDate',
      cell: (row) => <>{new Date(row.orderDate).toLocaleDateString()}</>, // Format Order Date
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Delivery Date',
      selector: 'deliveryDate',
      cell: (row) => <>{new Date(convertToISO(row.deliveryDate)).toLocaleDateString()}</>, // Convert and format Delivery Date
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Completion Time',
      selector: 'completionTime',
      cell: (row) => (
        <>
          {row.completionTime ? convertTimestampToReadableDate(row.completionTime) : "N/A"}
        </>
      ),
      style: { cursor: 'pointer', minWidth: "150px", textAlign:"left" },
    },
    { name: 'Order Status',
      selector: 'orderStatus',
      cell: (row) => (
        <Typography style={{
          color: getOrderStatusColor(row.orderStatus), fontWeight: 600, fontSize: '13px',
        }}>
          {CheckOrderStatus(row.orderStatus)} {/* Convert status number to status string */}
        </Typography>
      ),
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: 'Worker Status',
      selector: 'workerEmail',
      cell: (row) => <>{getWorkerStatus(row.orderState.workerStatus)}</>,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Worker Email',
      selector: 'workerEmail',
      cell: (row) => {
        const workerEmail = getOrderWorkerEmail(row.orderState);
        const handleClick = () => {
          localStorage.setItem('workerEmail', workerEmail);
        };
        return userType === 1 ? (
          <Link to={`/cs_admin/workerdetails`} onClick={handleClick}>
            {workerEmail}
          </Link>
        ) : (
          <>{workerEmail}</>
        );
      },
      style: { cursor: 'pointer', minWidth: "250px" },
    },
   
    {
      name: 'Net Sale',
      selector: 'billDetails.itemsTotal',
      cell: (row) => <>₹{row.billDetails && row.billDetails.itemsTotal || 0}</>, // Total sale amount
      style: { cursor: 'pointer' },
    },
    {
      name: 'Nest Cash',
      selector: 'billDetails.nestInfo.amount',
      cell: (row) => <>₹{row.billDetails && row.billDetails.nestInfo && row.billDetails.nestInfo.amount || 0}</>, // Nest cash applied
      style: { cursor: 'pointer',  },
    },
    {
      name: 'Coupon',
      selector: 'billDetails.couponInfo.amount',
      cell: (row) => <>₹{row.billDetails && row.billDetails.couponInfo && row.billDetails.couponInfo.amount || 0}</>, // Coupon discount applied
      style: { cursor: 'pointer' },
    },
    {
        name: 'Wallet',
        selector: 'billDetails.walletInfo.amount',
        cell: (row) => <>₹{row.billDetails && row.billDetails.walletInfo && row.billDetails.walletInfo.amount || 0}</>, // Coupon discount applied
        style: { cursor: 'pointer', },
      },
    {
      name: 'Net Amount',
      selector: 'billDetails.grandTotal',
      cell: (row) => <>₹{row.billDetails && row.billDetails.grandTotal || 0}</>, // Final amount after discounts
      style: { cursor: 'pointer',  },
    },
    {
      name: 'Type of Payment',
      selector: 'paymentMethod',
      cell: (row) => <>{getPaymentMethod(row.paymentMethod)}</>, // Convert payment method number to string
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: 'Local Store',
      selector: 'localStore',
      cell: row => <>{row.localStore.join(', ')}</>,
      style: { cursor: 'pointer' },
    },
  ];
  
  
  useEffect(() => {
   let endDate = formData.end.split('T')[0] + 'T23:59:00.000+00:00';
    
    if (formData.start && formData.end) {
 refetchfilterData({
        variables:  {
          filterInput: {
            startDate: formData.start,
            endDate: endDate,
          },
          limit: perPage,
          skip: (page - 1) * perPage,
       
    }

}) 
    }
 
  }, [page, perPage
    // , refetch
  ]);

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)
  };
  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }


  const downloadCSV = (tableDataCVS) => {
    const csvRows = [];
  
    // Adding the start and end date range as the first row
    const dateRange = `Start Date: ${formatToYYYYMMDD(formData.start)}, End Date: ${formatToYYYYMMDD(formData.end)}`;
    csvRows.push(dateRange);  // Add the date range to the CSV
  
    // Adding a blank row for spacing between date range and column headers
    csvRows.push('');
  
    // Define the CSV headers
    const headers = [
      'Order ID', 'Customer Name' , 'Customer Phone','Order Date',"Delivery Date", "Completion Time" ,'Order Status','Worker Status','Worker Email',  'Net Sale',
      'Nest Cash', 'Coupon', 'Wallet', 'Net Amount', 'Type of Payment',"Local Store"
    ];
    csvRows.push(headers.join(',')); 
    tableDataCVS.forEach((row, index) => {
      const rowData = [
        row._id.slice(-5), // Order ID
        row.customerName,
        
        row.customerPhone, // Customer ID
        new Date(row.orderDate).toLocaleDateString() ,
        convertDeliveryDate(row.deliveryDate),
        // getOrderStatusColor(row.orderStatus),
        row.completionTime ? convertTimestampToReadableDateCSV(row.completionTime) : "N/A",
       
        CheckOrderStatus(row.orderStatus), // Order Status
        getWorkerStatus(row.orderState.workerStatus),
        getOrderWorkerEmail(row.orderState),
      
        row.billDetails &&  row.billDetails.itemsTotal || 0, // Net Sale
        row.billDetails && row.billDetails.nestInfo && row.billDetails.nestInfo.amount || 0, // Nest Cash
        row.billDetails && row.billDetails.couponInfo&& row.billDetails.couponInfo.amount || 0, // Coupon
        row.billDetails &&  row.billDetails.walletInfo &&  row.billDetails.walletInfo.amount || 0, // Wallet
        row.billDetails.grandTotal || 0, // Net Amount
        getPaymentMethod(row.paymentMethod) ,// Type of Payment
        row.localStore
      ];
      csvRows.push(rowData.join(',')); // Add row data
    });
  
    // Creating the CSV Blob and triggering the download
    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Sales_Report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
      <Box sx={{ mb: 3, mt: 3 }}>

{/* Date Pickers and Fetch Button */}
<Grid container spacing={2} alignItems="center">

  {/* Start Date Picker */}
  <Grid item xs={3}>
    <TextField
      label="Select Start Date"
      type="date"
      value={StartDate}
      onChange={(value) => {
        const formattedStartDate = formatDateToISO(value.target.value);
        setStartDate(value.target.value);
        setFormData({ ...formData, start: formattedStartDate });
      }}
      InputLabelProps={{ shrink: true }}
      sx={{
        width: '100%',
       // White background for the date picker
        input: {
          color: 'black',  // Text color set to black
        },
        '.MuiInputBase-root': {
          color: 'black',  // Ensure the input text remains black
        },
        '& label.Mui-focused': {
          color: 'black', // Ensure the label text color is black when focused
        },
      }}
  // White background for date picker
    />
  </Grid>

  {/* End Date Picker */}
  <Grid item xs={3}>
    <TextField
      label="Select End Date"
      type="date"
      value={EndDate}
      onChange={handleEndDateChange}
      error={!!endDateError}
      helperText={endDateError}
      InputLabelProps={{ shrink: true }}
      sx={{
        width: '100%',
       // White background for the date picker
        input: {
          color: 'black',  // Text color set to black
        },
        '.MuiInputBase-root': {
          color: 'black',  // Ensure the input text remains black
        },
        '& label.Mui-focused': {
          color: 'black', // Ensure the label text color is black when focused
        },
      }}// White background for date picker
    />
  </Grid>

  {/* Fetch Stats Button */}
   {/* Fetch Button */}
   <Grid item xs={3}>
              <Button onClick={handleFetchStats} className={golbalClasses.dashbordBtn}>
                <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('Fetch Report')}
              </Button>
            </Grid>

            <Grid item xs={3}>
              <Button onClick={handleFetchStatsCVS} className={golbalClasses.dashbordBtn}>
                <Icon icon="ic:round-download" style={{ color: "white" }} /> {t('Download CSV')}
              </Button>
            </Grid>
       

</Grid>

</Box>
{statsLoading ? (
<CustomLoader />
) : (
    <DataTable
    customStyles={customStyles}
    
    subHeader={true}
    title={<TableHeader title={t('Sales Report')} />}
    columns={columns}
    data={tableData || []}
    progressPending={loadingQuery}
    progressComponent={<CustomLoader />}
    paginationDefaultPage={currentPage}
    pagination
    paginationServer
    paginationTotalRows={totalRows}
    onChangeRowsPerPage={handlePerRowChange}
    onChangePage={handlePageChange}
    sortFunction={customSort}
  />
)}
      </Container>
    </>
  )
}

export default withTranslation()(DayWiseOrderStats);
