import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import Header from '../components/Headers/Header';
import CustomLoader from '../components/Loader/CustomLoader';
import { GetAllReplenishRequests } from '../apollo';
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { customStyles } from '../utils/tableCustomStyles';
import useGlobalStyles from '../utils/globalStyles';
import { Icon } from '@iconify/react';
import {
  Container,
  Button,
  Paper,
  useTheme,
  Box,
  TextField,
  Autocomplete
} from '@mui/material';
import TableHeader from '../components/TableHeader';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const GET_REPLENTION_PRODUCTS = gql`${GetAllReplenishRequests}`;
const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);


const ManageReplenationForBillingAdmin = props => {
  const theme = useTheme();
  const { t } = props;
  const [editMode, setEditMode] = useState(null);
  const [editedHSN, setEditedHSN] = useState({});
  const [editedBarcode, setEditedBarcode] = useState({});
  const userDetailsStr = localStorage.getItem('user-native');
  const userDetails = JSON.parse(userDetailsStr);
  const golbalClasses = useGlobalStyles();
  const client = useApolloClient();
  const [page, setPage] = useState(1);
  const [tabledata, setTabledata] = useState(null);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(6); // Default to ALL
  let newId;
  try {
    newId = JSON.parse(localStorage.getItem("newId"));
  } catch (e) {
    newId = null;
  }

  // Use optional chaining to safely access the _id property
  const highlightId = newId ? newId.initiateReplenishRequest._id : null;

  const inputTextStyle = {
    color: 'black', height: '30px', // Adjust as needed
    padding: '0 14px'
  };

  const statusOptions = [
    { label: 'REQUESTED', value: 0 },
    { label: 'APPROVED', value: 1 },
    { label: 'APPROVED WITH CHANGE', value: 2 },
    { label: 'RECEIVED', value: 3 },
    { label: 'RECEIVED WITH CHANGE', value: 4 },
    { label: 'CANCELLED', value: 5 },
    { label: 'ALL', value: 6 },
  ];


  const openaAddVendorModal = () => {

    // Determine the route based on workerType
    const workerType = userDetails.workerType;
    const route = workerType === 0
      ? '/package_admin/requestrepletion'
      : '/billing_admin/requestrepletion';

    props.history.push({
      pathname: route,

    });
  };
  const variables = {
    status: selectedStatus,
    limit: perPage,
    skip: (page - 1) * perPage,
  };
  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_REPLENTION_PRODUCTS, {
    variables,
    onCompleted: (data) => {
      setTabledata(data && data.getAllReplenishRequests.repleshishRequestSummary ? data.getAllReplenishRequests.repleshishRequestSummary : []);
      setTotalRows(data.getAllReplenishRequests.totalCount);
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  );


  useEffect(() => {
    if (data && data.getAllReplenishRequests.repleshishRequestSummary) {
      setTabledata(data && data.getAllReplenishRequests.repleshishRequestSummary ? data.getAllReplenishRequests.repleshishRequestSummary : []);
      setTotalRows(data.getAllReplenishRequests.totalCount);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      variables: {
        status: selectedStatus,
        limit: perPage,
        skip: (page - 1) * perPage,
      },
    });
  }, [page, selectedStatus, perPage, refetch]);

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page);
  };

  const handleEditClick = (row) => {
    setEditMode(row._id);
    setEditedHSN(prev => ({ ...prev, [row._id]: row.hsn }));
    setEditedBarcode(prev => ({ ...prev, [row._id]: row.skus[0].barcode }));
  };

  const handleSaveClick = (row) => {
    setEditMode(null);
  };

  const handleCancelClick = () => {
    setEditMode(null);
  };

  const columns = useMemo(() => [

    {
      name: 'Order ID',
      selector: '_id',
      cell: (row) => (
        <span style={{ color: row._id === highlightId ? 'blue' : 'inherit' }}>
          {row._id.slice(-5)}
        </span>
      ),
      style: { cursor: 'pointer' },
    },
    {
      name: "Central Store Name",
      sortable: true,
      selector: 'centralStoreName',
      style: { cursor: 'pointer', },
    },
    {
      name: "Local Store Name",
      sortable: true,
      selector: 'localStoreName',
      style: { cursor: 'pointer', },
    },
    {
      name: "Requested By",
      sortable: true,
      selector: 'requestedByEmail',
      style: { cursor: 'pointer', },
    },
    {
      name: "Requested Date",
      sortable: true,
      selector: 'requestedDate',
      cell: row => new Date(row.requestedDate).toLocaleString(), // Format date
      style: { cursor: 'pointer', },
    },
    {
      name: "Status",
      sortable: true,
      selector: 'statusString',
      style: { cursor: 'pointer' },
    },
    {
      name: "Requested Count",
      sortable: true,
      selector: 'itemCount.requestedCount',
      style: { cursor: 'pointer', },
    },
    {
      name: "Approved Count",
      sortable: true,
      selector: 'itemCount.approvedCount',
      style: { cursor: 'pointer', },
    },
    {
      name: "Received Count",
      sortable: true,
      selector: 'itemCount.receivedCount',
      style: { cursor: 'pointer', },
    },
    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>,
    },
  ], [editMode, handleSaveClick, handleCancelClick]);


  const columnsPDF = useMemo(() => [
    {
      name: "Product Name",
      sortable: true,
      selector: 'displayName',
      style: { cursor: 'pointer', minWidth: "300px" },
    },
    {
      name: "Description",
      sortable: true,
      selector: 'description',
      style: { cursor: 'pointer', minWidth: "300px" },
    },
    {
      name: "Catalog Name",
      sortable: true,
      selector: 'catalog.name',
      style: { cursor: 'pointer', minWidth: "300px" },
    },
    {
      name: "Category",
      sortable: true,
      selector: 'category.name'
    },
    {
      name: "HSN",
      sortable: true,
      cell: row => editMode === row._id ? (
        <input
          type="text"
          value={editedHSN[row._id] || ''}
          onChange={e => setEditedHSN(prev => ({ ...prev, [row._id]: e.target.value }))}
        />
      ) : (
        row.hsn
      )
    },
    {
      name: 'Barcode',
      cell: row => editMode === row._id ? (
        <input
          type="text"
          value={editedBarcode[row._id] || ''}
          onChange={e => setEditedBarcode(prev => ({ ...prev, [row._id]: e.target.value }))}
        />
      ) : (
        row.skus[0].barcode
      ),
      sortable: false,
    },
    {
      name: 'Action',
      cell: row => editMode === row._id ? (
        <>
          <Button onClick={() => handleSaveClick(row)}>Save</Button>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </>
      ) : (
        <Button onClick={() => handleEditClick(row)}>Edit</Button>
      )
    }
  ], [editMode, editedHSN, editedBarcode]);
  const actionButtons = row => {
    return (
      <>
        <Button
          size="20px"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.common.white,
            padding: 0,
            height: '15px',
            fontSize: '7px',
            '&:hover': {
              border: '2px solid #637BFE',
              color: theme.palette.warning.dark,
              backgroundColor: "white"
            }
          }}
          onClick={e => {
            e.preventDefault()
            // handleReadyButtonClick(row)
            localStorage.setItem('ReplentionData', JSON.stringify(row))
            localStorage.setItem('Tab', JSON.stringify(0))
            // Determine the route based on workerType
            const workerType = userDetails.workerType;
            const route = workerType === 0
              ? '/package_admin/detailerepletion'
              : '/billing_admin/detilerepletion';

            props.history.push({
              pathname: route,
              state: { id: row._id, Tab: 0 }
            });
          }}>
          {t('View Details')}

        </Button>
      </>
    )
  }

  const customSort = useCallback((rows, selector, direction) => {
    const handleField = row => {
      if (typeof selector === 'function') {
        const data = selector(row);
        return data ? data.toString().toLowerCase() : "";
      }
      return "";
    };
    return orderBy(rows, handleField, direction);
  }, []);

  const handlePrint = () => {
    const doc = new jsPDF();
    const tableColumn = columnsPDF.map(col => col.name);
    const tableRows = selectedRows.map(row => [
      row.displayName,
      row.description,
      row.catalog.name,
      row.category.name,
      // row.defaultImage,
      row.hsn,
      row.skus[0].barcode
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: 'grid',
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 20 },
        2: { cellWidth: 20 },
        3: { cellWidth: 20 },
        4: { cellWidth: 20 },
        5: { cellWidth: 20 },
        6: { cellWidth: 20 },
        7: { cellWidth: 20 },
      },
    });

    doc.save('selected_products.pdf');
  };
  const handleRowSelected = state => {
    setSelectedRows(state.selectedRows);
  };

  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <>

            <Box sx={{ textAlign: 'right', mb: 3 }}>
              <Button
                className={golbalClasses.dashbordBtn}
                onClick={openaAddVendorModal}
              >
                <Icon icon="ic:twotone-plus" style={{ color: "white" }} />  {t('Create Stock Request')}
              </Button>
              <Button

                className={golbalClasses.dashbordBtn}
                onClick={handlePrint}
              >
                <Icon icon="gridicons:print" style={{ color: "white" }} />  {t('Print')}
              </Button>
            </Box>
            <DataTable
              subHeader={true}
              subHeaderComponent={

                <Box style={{ marginTop: -40 }}>
                  <Autocomplete
                    options={statusOptions}
                    getOptionLabel={(option) => option.label}
                    value={statusOptions.find(option => option.value === selectedStatus)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedStatus(newValue.value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Filter By Status"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: inputTextStyle
                        }}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '40px' // Adjust height
                          },
                          '& .MuiAutocomplete-inputRoot': {
                            padding: '0 0 0 14px !important' // Adjust padding
                          }
                        }}
                      />
                    )}
                    sx={{ width: 300, ml: 2 }}
                    PaperComponent={CustomPaperComponent}

                  />
                </Box>
              }
              title={<TableHeader title={t('Manage Replention')} />}
              columns={columns}
              data={tabledata || []}
              progressPending={loadingQuery}
              progressComponent={<CustomLoader />}
              paginationDefaultPage={currentPage}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowChange}
              onChangePage={handlePageChange}
              sortFunction={customSort}
              // selectableRows
              onSelectedRowsChange={handleRowSelected}
              customStyles={customStyles}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default withTranslation()(ManageReplenationForBillingAdmin);
