/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useQuery, gql, useApolloClient } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import CustomLoader from '../components/Loader/CustomLoader'
// core components
import Header from '../components/Headers/Header'
import {  getorderFilter } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { Container, Button, Box, } from '@mui/material'
import { customStyles } from '../utils/tableForRiderOutForDeliveryTable'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Icon } from '@iconify/react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const GET_ALL_ORDER_filter = gql`
  ${getorderFilter}
`


const RiderOutForDelivery = props => {
    const { t } = props;
    const history = useHistory();
    const client = useApolloClient();
    const globalClasses = useGlobalStyles()
    const userType = JSON.parse(localStorage.getItem('riderDetails'))
    const [page, setPage] = useState(
        1
    );
    var [tableData, setTableData] = useState([])
    const [currentPage, setCurrentPage] = useState(page);
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)

    const golbalClasses = useGlobalStyles()
  
  
    const handleBackClick = () => {
        history.push('/local_admin/riderlist'); // Navigate back based on user type
      };
    
    const { data, loading: loadingQuery } = useQuery(GET_ALL_ORDER_filter, {
        fetchPolicy: 'network-only',
        variables: {
            "filterInput": {

              
                "workerEmail": userType.email,
                "orderStatus": 6
            },
            limit: perPage,
            skip: (page - 1) * perPage,
        },
        onCompleted: (data) => {
            if (data && data.getAllOrders) {
                tableData = data.getAllOrdersByFilter.orders || []
                setTableData(tableData);
                setTotalRows(data.getAllOrdersByFilter.totalCount || 0);
                // LSName = data.getAllOrdersByFilter.orders.localStore[0]
            }
        },
        onError: error => {
            const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
            if (isForbiddenError) {
                localStorage.removeItem('user-native');
                localStorage.removeItem('restaurant_id');
                client.clearStore();
                props.history.push('/auth/login');
            }
        },
    });
    useEffect(() => {
        if (data) {
            setTotalRows(data.getAllOrdersByFilter.totalCount);
            tableData = data.getAllOrdersByFilter.orders
            setTableData(tableData)
        }
    }, [data]);
    // Update the query variables when the page value changes
    const handlePerRowChange = (newPerPage) => {
        setPerPage(newPerPage)
    }
    // Inside your handlePageChange function, update the local storage
    const handlePageChange = (page) => {
        setPage(page);
        setCurrentPage(page)
    };
    const customSort = (rows, field, direction) => {
        const handleField = row => {
            if (row[field]) {
                return row[field].toLowerCase()
            }
            return row[field]
        }
        return orderBy(rows, handleField, direction)
    }
 
    const columns = [
        {
            name: 'Order ID',
            selector: '_id', // Assuming "_id" is the field containing the order ID
            cell: (row) => <>{row._id.slice(-5)}</>, // Display only the last 5 characters of the Order ID
            style: { cursor: 'pointer' },
        },
        {
            name: 'Customer Name',
            selector: 'customerName', // Use customerName from data
            cell: (row) => <>{row.customerName}</>, // Display the customer name
            style: { cursor: 'pointer', minWidth: "150px" },
          },
          {
            name: 'Customer Phone',
            selector: 'customerPhone',
            cell: (row) => <>{row.customerPhone}</>, // Display customer phone
            style: { cursor: 'pointer', minWidth: "150px" },
          },
        {
            name: 'Order Date',
            selector: 'orderDate',
            cell: (row) => <>{new Date(row.orderDate).toLocaleDateString()}</>, // Show only the date part
            style: { cursor: 'pointer', minWidth: "110px" },
        },
        {
            name: 'Delivery Date',
            selector: 'deliveryDate',
            cell: (row) => {
                // Extract the date part from the delivery date string
                const datePart = row.deliveryDate.split(',')[0];
                // Parse the date part and format it
                const [day, month, year] = datePart.split(' ');
                const formattedDate = new Date(`${month} ${day.replace(/\D/g, '')}, ${year}`).toLocaleDateString();
                return <>{formattedDate}</>;
            },
            style: { cursor: 'pointer', minWidth: "110px" },
        },

        {
            name: "Delivery Address",
            sortable: false,
            cell: row => {
                const { details, deliveryAddress } = row.deliveryAddress || {}; 
                return details && deliveryAddress 
                    ? `${details}, ${deliveryAddress}` 
                    : "NA";
            }, 
            style: { cursor: 'pointer', minWidth: "300px", textAlign: 'left' }, 
        },
         {
                name: 'Items',
                selector: 'itemsCount',
                style: { cursor: 'pointer' ,minWidth: "110px"},
            },
            {
                name: 'Amount',
                selector: 'orderAmount',
                style: { cursor: 'pointer' },
            },
        // {
        //     name: t('Action'),
        //     cell: (row) => <>{actionButtons(row)}</>,
        // },

    ];

  
    const handlePrint = () => {
        const doc = new jsPDF();
        let currentY = 20;
    
        // Add worker details to the top right corner
        const addWorkerDetails = (worker) => {
            doc.setFontSize(12);
            doc.text(`Name: ${worker.name}`, 145, 10);
            doc.text(`Email: ${worker.email}`, 145, 15);
            doc.text(`Phone: ${worker.phone}`, 145, 20);
            doc.text(`Store Name: ${tableData[0].localStore}`, 145, 25);
        };
    
        const workerDetails = {
            name: userType.name,
            email: userType.email,
            phone: userType.phone
        };
        addWorkerDetails(workerDetails);
    
        // Helper function to add a table
        const addTable = (title, columns, data, totalCOD, totalOnline) => {
            doc.setFontSize(12); // Reduced title font size
            doc.text(title, 14, currentY);
            currentY += 20;
    
            doc.autoTable({
                head: [columns.map(col => col.name)],
                body: data.map(row => columns.map(col => {
                    if (col.cell) {
                        const cellValue = col.cell(row);
                        return cellValue && cellValue.props && cellValue.props.children
                            ? cellValue.props.children
                            : 'N/A';
                    }
                    return row[col.selector];
                })),
                startY: currentY,
                theme: 'grid',
                styles: { fontSize: 8 }, // Reduce the font size for the table
                columnStyles: {
                    0: { cellWidth: 20 }, // Order ID
                    1: { cellWidth: 20 }, // Customer Name
                    2: { cellWidth: 20 }, // Customer Phone
                    3: { cellWidth: 15}, // Order Date
                    4: { cellWidth: 20 }, // Delivery Date
                    5: { cellWidth: 30 }, // Delivery Address (Wider column)
                    6: { cellWidth: 10 }, // Items
                    7: { cellWidth: 15 }, // COD
                    8: { cellWidth: 15 }, // Online
                    9: { cellWidth: 20 }, // Payment Status
                },
                foot: [[
                    '', '', '', '', '', 'Total:', totalCOD, totalOnline, ''
                ]]
            });
    
            currentY = doc.lastAutoTable.finalY + 10;
        };
    
        // Define the columns
        const columns = [
            { name: 'Order ID', selector: '_id' },
            { name: 'Customer Name', selector: 'customerName' },
            { name: 'Customer Phone', selector: 'customerPhone' },
            { name: 'Order Date', selector: 'orderDate' },
            { name: 'Delivery Date', selector: 'deliveryDate' },
            { name: 'Delivery Address', selector: 'deliveryAddress' },
            { name: 'Items', selector: 'itemsCount' },
            { name: 'COD', selector: 'codAmount' },
            { name: 'Online', selector: 'onlineAmount' },
            { name: 'Payment Status', selector: 'paymentStatus' },
        ];
    
        // Transform data for table
        const transformedData = tableData.map(row => ({
            _id: row._id,
            customerName: row.customerName,
            customerPhone: row.customerPhone,
            orderDate: new Date(row.orderDate).toLocaleDateString(),
            deliveryDate: row.deliveryDate,
            deliveryAddress: row.deliveryAddress ? `${row.deliveryAddress.details}, ${row.deliveryAddress.deliveryAddress}` : 'NA', // Concatenate details and delivery address
   
            itemsCount: row.itemsCount,
            codAmount: row.paymentMethod === 0 ? row.orderAmount : '',
            onlineAmount: row.paymentMethod === 1 ? row.orderAmount : '',
            paymentStatus: row.paymentStatus === 0 ? 'Pending' : 'Completed',
        }));
    
        const totalCOD = tableData.reduce((sum, row) => row.paymentMethod === 0 ? sum + row.orderAmount : sum, 0);
        const totalOnline = tableData.reduce((sum, row) => row.paymentMethod === 1 ? sum + row.orderAmount : sum, 0);
    
        // Add the table to the PDF
        addTable('Out For Delivery List', columns, transformedData, totalCOD, totalOnline);
    
        // Add the totals to the PDF
        doc.setFontSize(12); // Adjust font size for totals
        doc.text(`Total COD: ${totalCOD}`, 14, currentY);
        currentY += 10;
        doc.text(`Total Online: ${totalOnline}`, 14, currentY);
        currentY += 10;
        doc.text(`Total Amount: ${totalCOD + totalOnline}`, 14, currentY);
    
        // Save the PDF as a blob and open it in a new tab
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
    };
    
      
      
    return (
        <>
            <Header />
            <Container className={globalClasses.flex} fluid>
            <Box sx={{ textAlign: 'right' }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={handlePrint}
          >
            <Icon icon="gridicons:print" style={{ color: "white" }} />  {t('Print')}
          </Button>
          <Button className={golbalClasses.dashbordBtn} onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
                {loadingQuery ? (
                    <CustomLoader />
                ) :
                    <DataTable
                        customStyles={customStyles}
                
                        subHeader={true}
                        title={<TableHeader title={"Out For Delivery List -" + userType.email   } />}
                        columns={columns}
                        data={tableData || []}
                        progressPending={loadingQuery}
                        progressComponent={<CustomLoader />}
                        paginationDefaultPage={currentPage}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowChange}
                        onChangePage={handlePageChange}
                        sortFunction={customSort}
                    />
                }
            </Container>
        </>
    )
}
export default withTranslation()(RiderOutForDelivery)
