
import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { getalllocalstore, AddNewCustomerType, updatelocaladmin, UpdateAdminPassword } from '../../apollo';
import { Button, Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';



const GET_LOCAL_STORE = gql`
  ${getalllocalstore}
`;
const CREATE_CUSTOMER_TYPE = gql`
  ${AddNewCustomerType}
`;
const EDIT__LOCAL_STORE_ADMIN = gql`
  ${updatelocaladmin}
`;

const EDIT__LOCAL_STORE_ADMIN_PASSWORD = gql`
  ${UpdateAdminPassword}
`;


function AddCustomerType(props) {
  const { onClose, filtered } = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT__LOCAL_STORE_ADMIN : CREATE_CUSTOMER_TYPE;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    id: props.vendor ? props.vendor._id : "",
    name: props.vendor ? props.vendor.name : '',
    description: props.vendor ? props.vendor.description : '',
    customerType: props.vendor ? props.vendor.customerType : null,
    charges: props.vendor ? props.vendor.charges : '',

  });
  const [passwordModified, setPasswordModified] = useState(false);
  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      errorSetter("Something Went Wrong");
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };
  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Customer Type Updated Successfully') : t('Customer Type Added Successfully');
    errorSetter('');
    props.refetch()
    successSetter(message);
    setTimeout(hideAlert, 3000);
    props.refetch()
    setopenSnackk(true)
  };
  const [updatePassword] = useMutation(EDIT__LOCAL_STORE_ADMIN_PASSWORD, {
    onError,
    onCompleted,
  });

  const [openSnackk, setopenSnackk] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update form data state
    if (name === "customerType") {
      const customerTypeExists = filtered.some((item) => item.customerType === parseInt(value));
      if (customerTypeExists) {
        setFormErrors({ ...formErrors, [name]: 'Customer Type already exists' });
        return;
      } else {
        setFormErrors({ ...formErrors, [name]: '' });
      }
    }
    setFormData({ ...formData, [name]: value });
    if (name === 'password' && props.vendor) {
      setPasswordModified(true);
    }
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };


  const validateFormData = () => {
    let errors = {};

    if (!formData.name) errors.name = 'Name is required';
    if (!formData.description) errors.description = 'Description is required';
    if (!formData.customerType) errors.customerType = 'Customer Type is required';


    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_LOCAL_STORE }],
    onError,
    onCompleted,
  });
  const clearFields = () => {
    formRef.current.reset();

    setFormData({
      name: props.vendor ? props.vendor.name : '',
      email: props.vendor ? props.vendor.email : '',
      contactAddress: props.vendor ? props.vendor.contactAddress : '',
      password: props.vendor ? props.vendor.password : '',
      phone: props.vendor ? props.vendor.phone : '',
      storeId: props.vendor ? props.vendor.storeId : '',

    })
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      // setOpenSnack(true);
      return;
    }
    if (true) {
      !props.vendor ?
        mutate({
          variables: {
            "name": formData.name,
            "description": formData.description,
            "customerType": parseInt(formData.customerType)
          }

        }) :
        mutate({
          variables: {
            adminId: formData.id,
            lsAdminUpdateInput: {
              contactAddress: formData.contactAddress,
              email: formData.email,
              name: formData.name,
              // password: formData.password,
              phone: formData.phone,
              storeId: formData.storeId
            }
          }

        })
        ;
      if (passwordModified && formData.password) {
        updatePassword({
          variables: {
            password: formData.password,
            adminId: formData.id,
          },
        });
      }
      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose(); // Close the modal
        }
      }, 4000);
    }
  };
  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Edit Customer Type') : t('Add Customer Type')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>

          <Grid container spacing={2}>
            {!props.editModal ?
              <>
                <Grid item xs={12} sm={6}>
                  <TextField name="name" label=" Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.name}
                    helperText={formErrors.name} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="description" label="Description" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.description}
                    helperText={formErrors.description} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField type='number' name="customerType" label="Customer Type" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    error={!!formErrors.customerType}
                    helperText={formErrors.customerType} />
                </Grid>

              </>
              :
              ( // Conditionally render the second form fields based on editModal prop
                <>

                </>
              )
            }
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
            {props.vendor ? t('Update') : t('Add')}
          </Button>
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(AddCustomerType);
