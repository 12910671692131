import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { FilterProductsByCategories, getproductsforcs, searchproductforcs } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import SearchBar from '../components/TableHeader/SearchBar'
import { customStyles } from '../utils/tableStyleForManageProduct'
import useGlobalStyles from '../utils/globalStyles'
import { useHistory } from 'react-router-dom';
import {
  Container,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper,
  Badge,
  Box, Button,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import Alert from '../components/Alert'
import AddProduct from '../components/AddProduct/AddProduct'
import TuneIcon from '@mui/icons-material/Tune';
import AllPRoductFIlter from '../utils/fIlters/AllPRoductFIlter'

const GET_PRODUCTS = gql`
  ${getproductsforcs}
`

const GET_SEARCH_PRODUCTS = gql`
  ${searchproductforcs}
`
const GET_ALL_PRODUCT_filter = gql`
${FilterProductsByCategories}
`
const CentralAdminProducts = props => {
  const history = useHistory();
  const { t } = props;
  const [editModal, setEditModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const userDetailsStr = localStorage.getItem('user-native');
  const userDetails = JSON.parse(userDetailsStr);
  const userStoreId = userDetails.storeId;
  const golbalClasses = useGlobalStyles()
  const client = useApolloClient();
  const [page, setPage] = useState(
    1
  );
  var [tabledata, setTabledata] = useState(null);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [relaod, setReload] = useState(false)
  const [badgeContent, setbadgeContent] = useState(false);
  const [OpenFilter, setOpenFilter] = useState(false)
  var [FilterPaload, setFilterPaload] = useState({})
  var [DefaultValue, setDefaultValue] = useState({})
  var dataSearchReset = undefined
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true)
  }
  const CloseFilter = () => {
    setOpenFilter(false)
  }
  const handleResetFilters = () => {
    setFilterPaload({});
    setDefaultValue({});
    setPage(1);
    setReload(!relaod)
    setbadgeContent(false)
  };
  const variables = {
    storeId: userDetails.storeId,
    limit: perPage,
    skip: (page - 1) * perPage,
    // skip: page,
  };


  const variablesSearch = {
    text: searchQuery, // Assuming `text` is the variable used in your search query
    limit: perPage,
    skip: (page - 1) * perPage,
  };

  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_PRODUCTS, {
    variables,
    fetchPolicy:"no-cache",
    onCompleted: (data) => {
      !searchQuery &&  setTabledata(data && data.getProductsForCS.products ? data.getProductsForCS.products : [])

      !searchQuery &&  setTotalRows(data.getProductsForCS.totalCount);
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  )

  const { data: dataSearch, refetch: refetchSearch } = useQuery(
    GET_SEARCH_PRODUCTS, {
    variables: variablesSearch,
    fetchPolicy:"no-cache",
    onCompleted: (dataSearch) => {
      dataSearchReset =dataSearch&& dataSearch.searchProductsCS.products
      setTabledata(dataSearch && dataSearch.searchProductsCS.products ? dataSearch.searchProductsCS.products : [])
      setTotalRows(dataSearch.searchProductsCS.totalCount);
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  )

  const [refetchfilterData] = useLazyQuery(GET_ALL_PRODUCT_filter, {
    fetchPolicy: 'no-cache',

    onCompleted: (resultData) => {
      if (resultData && resultData.filterProductsByCategories) {
        CloseFilter()
        tabledata = resultData.filterProductsByCategories.products || []
        setTabledata(tabledata);
        setTotalRows(resultData.filterProductsByCategories.totalCount || 0);
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  });

  const filterData = (filterInput) => {
    setDefaultValue(filterInput)
    FilterPaload = {

      "categoryId": filterInput.orderStatus,
      "subCategoryId": filterInput.workerStatus,
      "limit": perPage,
      "skip": (page - 1) * perPage,
    }
    //  setFilterPaload(filterInput)
    refetchfilterData({
      variables: FilterPaload,
    })
    setbadgeContent(true)
  }
  const onChangeSearch = e => {
    setSearchQuery(e.target.value)
    setTimeout(() => {
      refetchSearch()
    }, 1000)

  }
  const onClickRefetch = () => {
    setSearchQuery('')
    refetch()
    setbadgeContent(false)
    setReload(!relaod)
    setFilterPaload({});
    setDefaultValue({});
    dataSearchReset = undefined
  }

  useEffect(() => {
    if (data && data.getProductsForCS.products && dataSearchReset == undefined) {
      !searchQuery &&  setTabledata(data && data.getProductsForCS.products ? data.getProductsForCS.products : [])
      !searchQuery &&  setTotalRows(data.getProductsForCS.totalCount);
    } else if (dataSearch && dataSearch.searchProductsCS.products) {
      setTabledata(dataSearch && dataSearch.searchProductsCS.products ? dataSearch.searchProductsCS.products : [])
      setTotalRows(dataSearch.searchProductsCS.totalCount);

    }
  }, [data, relaod]);

  // Update the query variables when the page value changes
  useEffect(() => {
    refetch({
      variables: {
        storeId: userDetails.storeId,
        limit: perPage,
        skip: (page - 1) * perPage,
        //  skip: page ,
      },
    });
  }, [page, perPage, refetch]);


  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)

  };

  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const columns = [
    {
      name: "Product ID",
      selector: '_id'
    },
    {
      name: "Product Name",
    
      selector: 'name',
      style: { cursor: 'pointer', minWidth: "250px" },
    },

    {
      name: "Quantity",
      selector: 'skus',
      cell: row => {
        const matchingSku = row.skus.find(sku =>
          sku.Stock.some(stock => stock.storeId === userStoreId)
        );
        if (matchingSku) {
          const matchingStock = matchingSku.Stock.find(stock => stock.storeId === userStoreId);
          return matchingStock ? matchingStock.quantity : 'N/A';
        }
        return 'N/A';
      },
     
    },
    {
      name: "Aliases",
      cell: row => row.aliases.join(', '), // Join aliases array into a string
      style: { cursor: 'pointer', minWidth: "250px" },
    },

    // {
    //   name: "Description",
    //   selector: 'description',
    //   style: { cursor: 'pointer', minWidth: "300px", textAlign: 'left' },
    // },
    {
      name: "Catalog Name",
      selector: 'catalog.name',
      style: { cursor: 'pointer', minWidth: "300px" },
    },
    {
      name: "Category",
      selector: 'category.name', // Access category name from the nested object
      style: { cursor: 'pointer', minWidth: "300px" },
    },

    {
      name: "Default Image",
      cell: row => (
        <img
          src={row.defaultImage}
          alt={row.displayName}
          style={{ width: 50, height: 50 }}
        />
      )
    },

    {
      name: "HSN",
      selector: 'hsn'
    },

    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>
    }
  ];


  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>

              <MenuItem
                onClick={e => {
                  e.preventDefault()

                  localStorage.setItem('skuRow', JSON.stringify(row));
                  history.push('/cs_admin/sku'); // Navigate to the SKU route
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <VisibilityIcon fontSize="small" style={{ color: 'black' }} />
                </ListItemIcon>
                <Typography color="green">{t('Manage SKU')}</Typography>
              </MenuItem>
              {/*               
              <MenuItem
                onClick={e => {
                  e.preventDefault()
  setIsOpen(false)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  toggleModalSKU(row);
  localStorage.setItem('skuRow', JSON.stringify(row));
  // history.push('/cs_admin/sku'); // Navigate to the SKU route
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'black' }} />
                </ListItemIcon>
                <Typography color="black">{t('Manage Quantity')}</Typography>
              </MenuItem>
               */}
              <MenuItem
                disabled
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(true)
                  setTimeout(() => {
                    setIsOpen(false)
                  }, 5000)
                  // uncomment this for paud version
                  // mutate({ variables: { id: row._id } });
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />

      <Container className={golbalClasses.flex}>
        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
            subHeaderComponent={
              <>
                <SearchBar
                  value={searchQuery}
                  onChange={onChangeSearch}
                  onClick={() => onClickRefetch(refetch)}
                />
                <Box style={{ marginTop: -40 }}>
                  {badgeContent === true ? (
                    <Badge
                      badgeContent={''}
                      color="success"
                      variant="dot"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Button
                        onClick={handleOpenFilter}
                      >

                        <TuneIcon />
                      </Button>
                    </Badge>
                  ) : <Button
                    onClick={handleOpenFilter}
                  >

                    <TuneIcon />
                  </Button>}
                </Box>
              </>
            }
            title={<TableHeader title={t('CS Manage Products ')} />}
            columns={columns}
            data={tabledata || []}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            // sortFunction={customSort}

            customStyles={customStyles}
          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          BackdropProps={{
            onClick: (e) => {
              e.stopPropagation(); // Prevent the modal from closing
            }
          }}
          onClose={() => {
            toggleModal()
          }}>
          <AddProduct vendor={vendors} onClose={closeEditModal} />
        </Modal>
        {/* <Modal
          open={skuModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <EditProductQuantityCS vendor={vendors} onClose={closeSkuModal} />
        </Modal> */}


        {OpenFilter &&
          <AllPRoductFIlter open={OpenFilter} close={CloseFilter} filterData={filterData} DefaultValue={DefaultValue} handleResetFilters={handleResetFilters} />
        }
      </Container>
    </>
  )
}

export default withTranslation()(CentralAdminProducts)

