import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql, useApolloClient, useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  DialogActions,
  Alert,
  Snackbar,
  Container, Dialog, DialogTitle, DialogContent
} from '@mui/material';
import useGlobalStyles from '../../utils/globalStyles';
import { AddCustomerDeliverySlot, deleteCustomerDeliverySlot, GetStore, UpdatePremiumCustomerCronTimeSlot, } from '../../apollo';
import Header from '../Headers/Header';
import useStyles from './styles'
const UPDATE_CUSTOMER_TIME_SLOT = gql`${AddCustomerDeliverySlot}`;
const UPDATE_CUSTOMER_PRIME_TIME_SLOT = gql`${UpdatePremiumCustomerCronTimeSlot}`;
const DELETE = gql`${deleteCustomerDeliverySlot}`
const GET_STORE__CUSTOMER_TIME = gql`${GetStore}`;
const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function CustomerTimeSlotForLS(props) {
  const { onClose, refetch } = props;
  const formRef = useRef();
  const mutation = props.vendor ? UPDATE_CUSTOMER_PRIME_TIME_SLOT : UPDATE_CUSTOMER_TIME_SLOT;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const [openSnackk, setopenSnackk] = useState(false);
  const classes = useStyles()
  const userDetailsStr = localStorage.getItem('user-native');
  const userDetails = JSON.parse(userDetailsStr);
  const { t } = props;
  const [formData, setFormData] = useState({
    startHour: '',
    startMinute: '',
    endHour: '',
    endMinute: '',
    leadTime: 0
  });

  const client = useApolloClient();
  const { data } = useQuery(
    GET_STORE__CUSTOMER_TIME, {
    fetchPolicy: "no-cache",
    variables: { storeId: userDetails ? userDetails.storeId : null },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  );

  useEffect(() => {
    if (props.vendor) {
      const { start, end } = props.vendor;
      const [startHour, startMinute] = start.split(':');
      const [endHour, endMinute] = end.split(':');
      setFormData({
        startHour: startHour || '',
        startMinute: startMinute || '',
        endHour: endHour || '',
        endMinute: endMinute || '',
        leadTime: props.vendor.leadTime || 0

      });
    }
  }, [props.vendor]);

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value || '' });
  };
  const onCompleted = (data) => {
    const message = props.vendor ? t('Time Config Updated Successfully') : t('Time Config Added Successfully');
    errorSetter('');
    successSetter(message);
    // props.refetch();
    setopenSnackk(true);
    setTimeout(hideAlert, 3000);
  };


  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message;
      errorSetter(error);
      setopenSnackk(true);
    } else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true);
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false);
  };

  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  return (

    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography
            className={props.vendor ? classes.textWhite : classes.textWhite}>
            {props.vendor ? t('Edit Customer Delivery Slot') : t('Customer Delivery Slot ')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef} >



          <Grid container spacing={2}>
            {!props.editModal ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    Start Time
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="start-hour"
                    options={hours}
                    getOptionLabel={(option) => option}
                    value={formData.startHour}
                    onChange={(event, value) => handleInputChange('startHour', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Hour"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="start-minute"
                    options={minutes}
                    getOptionLabel={(option) => option}
                    value={formData.startMinute}
                    onChange={(event, value) => handleInputChange('startMinute', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Minute"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    End Time
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="end-hour"
                    options={hours}
                    getOptionLabel={(option) => option}
                    value={formData.endHour}
                    onChange={(event, value) => handleInputChange('endHour', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Hour"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="end-minute"
                    options={minutes}
                    getOptionLabel={(option) => option}
                    value={formData.endMinute}
                    onChange={(event, value) => handleInputChange('endMinute', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Minute"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
                {userDetails.userType === 2 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="leadTime"
                      label="Lead Time"
                      type="number"
                      value={formData.leadTime}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => handleInputChange('leadTime', e.target.value)}
                      inputProps={{ style: inputTextStyle }}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    Start Time
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="start-hour"
                    options={hours}
                    getOptionLabel={(option) => option}
                    value={formData.startHour}
                    onChange={(event, value) => handleInputChange('startHour', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Hour"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="start-minute"
                    options={minutes}
                    getOptionLabel={(option) => option}
                    value={formData.startMinute}
                    onChange={(event, value) => handleInputChange('startMinute', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Minute"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    End Time
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="end-hour"
                    options={hours}
                    getOptionLabel={(option) => option}
                    value={formData.endHour}
                    onChange={(event, value) => handleInputChange('endHour', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Hour"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="end-minute"
                    options={minutes}
                    getOptionLabel={(option) => option}
                    value={formData.endMinute}
                    onChange={(event, value) => handleInputChange('endMinute', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Minute"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
                {userDetails.userType === 2 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="leadTime"
                      label="Lead Time"
                      type="number"
                      value={formData.leadTime}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => handleInputChange('leadTime', e.target.value)}
                      inputProps={{ style: inputTextStyle }}
                    />
                  </Grid>
                )}
                {/* <Grid item xs={12} sm={6}>
                <TextField
                  name="leadTime"
                
                  label="Lead Time"
                  type="number"
                  value={formData.leadTime}
                  variant="outlined"
                  fullWidth
                  onChange={handleInputChange}
                  inputProps={{ style: inputTextStyle }}
                />
              </Grid> */}
              </>
            )}
          </Grid>

          <DialogActions>
            <Button
              className={globalClasses.dashbordBtn}
              disabled={mutateLoading}
              onClick={async (e) => {
                e.preventDefault();
                const startTime = formData.startHour && formData.startMinute ? `${formData.startHour}:${formData.startMinute}` : '';
                const endTime = formData.endHour && formData.endMinute ? `${formData.endHour}:${formData.endMinute}` : '';
                {
                  userDetails.userType === 2 ?
                  mutate({
                    variables: {
                      start: startTime,
                      end: endTime,
                      leadTime: parseInt(formData.leadTime)
                    },
                  }) : mutate({
                    variables: {
                      start: startTime,
                      end: endTime,
                      // leadTime: formData.leadTime
                    },
                  })
                }
                refetch()

                setTimeout(() => {
                  if (typeof props.onClose === 'function') {
                    props.onClose();
                  }
                }, 4000);
              }}
            >
              {props.vendor ? t('Update') : t('Add')}
            </Button>
            <Button
              className={globalClasses.modalCancleBtn}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </DialogActions>


        </form>
      </DialogContent>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}

export default withTranslation()(CustomerTimeSlotForLS);
