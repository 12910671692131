
import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar,
} from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';
import { AddRiderAssignTimeLS, updateworkerdata } from '../../apollo';

const CREATE_RIDER_TIME_SLOT = gql`${AddRiderAssignTimeLS}`;
const EDIT__LOCAL_STORE_ADMIN = gql`${updateworkerdata}`;

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function AddTimeSLot(props) {
  const { onClose } = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT__LOCAL_STORE_ADMIN : CREATE_RIDER_TIME_SLOT;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const [openSnackk, setopenSnackk] = useState(false);

  const { t } = props;
  const [formData, setFormData] = useState({
    startHour: '',
    startMinute: '',
    endHour: '',
    endMinute: '',
  });

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value || '' });
  };

  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Time Config Updated Successfully') : t('Time Config Added Successfully');
    errorSetter('');
    successSetter(message);
    props.refetch();
    setopenSnackk(true);
    setTimeout(hideAlert, 3000);
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      
      error = graphQLErrors[0].message
      errorSetter(error);
      setopenSnackk(true);
    } else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true);
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {

    onError,
    onCompleted,
  });

  const clearFields = () => {
    formRef.current.reset();
    setFormData({
      startHour: '',
      startMinute: '',
      endHour: '',
      endMinute: '',
    });
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false);
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          overflow: 'visible', // Prevents scrollbar inside the modal
        },
      }}
    >
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
            {props.vendor ? t('Edit Time Slot ') : t('Add Time Slot')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={2}>
            {!props.editModal ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    Start Time
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="start-hour"
                    options={hours}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => handleInputChange('startHour', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Hour"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="start-minute"
                    options={minutes}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => handleInputChange('startMinute', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Minute"
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                    fullWidth
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="start"
                    value={formData.start}
                    label="Start Time"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="end"
                    value={formData.end}
                    disabled
                    label="End Time"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className={globalClasses.dashbordBtn}
          disabled={mutateLoading}
          onClick={async (e) => {
            e.preventDefault();
            const startTime = formData.startHour && formData.startMinute ? `${formData.startHour}:${formData.startMinute}` : '';
            // const endTime = formData.endHour && formData.endMinute ? `${formData.endHour}:${formData.endMinute}` : '';
            mutate({
              variables: {
                start: startTime,
              }
            });
            setTimeout(() => {
              if (typeof props.onClose === 'function') {
                props.onClose();
              }
            }, 4000);
          }}
        >
          {props.vendor ? t('Update') : t('Save')}
        </Button>
        <Button
          className={globalClasses.modalCancleBtn}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}

export default withTranslation()(AddTimeSLot);
