import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import Header from '../components/Headers/Header';
import CustomLoader from '../components/Loader/CustomLoader';
import {  GetOrderDeliveryStats } from '../apollo';
import DataTable from 'react-data-table-component';
import { customStyles } from '../utils/tableStyleForSore';
import {
  Container, Button, Grid, Box, Typography, TextField
} from '@mui/material';
import { Icon } from '@iconify/react';
import useGlobalStyles from '../utils/globalStyles';
import TableHeader from '../components/TableHeader';

// GraphQL queries
const GET_ALL_ORDER_STATS = gql`${GetOrderDeliveryStats}`;

const OrderStats = (props) => {
  const { t } = props;
  const [orderStatsData, setOrderStatsData] = useState([]); // To hold order stats data
  const golbalClasses = useGlobalStyles();
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [EndDateError, setEndDateError] = useState(''); // State to hold end date error
  const client = useApolloClient();

  // Fetch order stats based on date
  const [fetchOrderStats, { loading: statsLoading, data: statsData }] = useLazyQuery(GET_ALL_ORDER_STATS, {
    variables: {
      start: StartDate,
      end: EndDate,
    },
    onCompleted: (data) => {
      setOrderStatsData(data && data.getOrderDeliveryStats || []); // Update the table data
    },
    onError: (error) => {
      console.error("Error fetching stats:", error);
    }
  });
  const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    const isoString = date.toISOString(); // This will give you the date in "2024-09-04T00:00:00.000Z" format
    return isoString.replace('Z', '+00:00'); // Replacing 'Z' with '+00:00' to match your required format
  }
  const handleFetchStats = () => {
    let startDate = formatDateToISO(StartDate);
    let endDate = formatDateToISO(EndDate);
    endDate = endDate.split('T')[0] + 'T23:59:00.000+00:00';
  
    if (StartDate && EndDate) {
      fetchOrderStats(
        {
          variables: {
            start: startDate,
            end: endDate,
          }
        }
      );
    } else {
      alert('Please select both start and end dates.');
    }
  };

  // Define columns for the table
  const columns = [
    { name: "Sl No.", cell: (row, index) => <>{index + 1}</> },
    { name: "Product Name", selector: 'name', sortable: true, style: { cursor: 'pointer', minWidth: "200px" } },
    { name: "Product ID", selector: 'productId', sortable: true, style: { cursor: 'pointer', minWidth: "200px" } },
    { name: "Total Quantity", selector: 'totalQuantity', sortable: true },
    { name: "Total Orders", selector: 'totalOrders', sortable: true },
  ];

// Function to download CSV without the "Sl No." column
// Function to download CSV with date range above the table data
const downloadCSV = () => {
  const csvRows = [];

  // Adding the start and end date range as the first row
  const dateRange = `Start Date: ${StartDate}, End Date: ${EndDate}`;
  csvRows.push(dateRange);  // Add the date range to the CSV

  // Adding a blank row for spacing between date range and column headers
  csvRows.push('');

  // Extracting column titles (excluding the "Sl No." column)
  const headers = columns
    .filter(col => col.name !== "Sl No.")  // Filter out the "Sl No." column
    .map((col) => col.name);
  csvRows.push(headers.join(','));  // Add headers

  // Extracting rows of data (excluding the "Sl No." column)
  orderStatsData.orderStat.forEach(row => {
    const rowData = columns
      .filter(col => col.name !== "Sl No.")  // Filter out the "Sl No." column
      .map(col => {
        if (typeof col.selector === 'function') {
          return col.selector(row);
        }
        return row[col.selector] || '';
      });
    csvRows.push(rowData.join(','));  // Add row data
  });

  // Creating the CSV Blob and triggering the download
  const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'order_status.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


  // Handle End Date change with validation
  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (new Date(newEndDate) < new Date(StartDate)) {
      setEndDateError('End date cannot be earlier than start date.');
    } else {
      setEndDateError('');
      setEndDate(newEndDate);
    }
  };

  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
        <Box sx={{ mb: 3, mt: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <TextField
                label="Select Start Date"
                type="date"
                value={StartDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{
                  width: '100%',
                  input: { color: 'black' },
                  '.MuiInputBase-root': { color: 'black' },
                  '& label.Mui-focused': { color: 'black' },
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Select End Date"
                type="date"
                value={EndDate}
                onChange={handleEndDateChange}
                error={!!EndDateError}
                helperText={EndDateError}
                InputLabelProps={{ shrink: true }}
                sx={{
                  width: '100%',
                  input: { color: 'black' },
                  '.MuiInputBase-root': { color: 'black' },
                  '& label.Mui-focused': { color: 'black' },
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Button onClick={handleFetchStats} className={golbalClasses.dashbordBtn}>
                <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('Fetch Status')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button onClick={downloadCSV} className={golbalClasses.dashbordBtn}>
                <Icon icon="ic:round-download" style={{ color: "white" }} /> {t('Download CSV')}
              </Button>
            </Grid>
          </Grid>

          {orderStatsData  && (
            <Box mt={3}>
              <Typography variant="h6">
                {t('Total Order Count')}: {orderStatsData.orderCount ?orderStatsData.orderCount  :'N/A' }
              </Typography>
              <Typography variant="h6">
                {t('Total Order Amount')}: {orderStatsData.orderAmount ?orderStatsData.orderAmount  :'N/A'}
              </Typography>
            </Box>
          )}
        </Box>

        {statsLoading ? (
          <CustomLoader />
        ) : (
          <DataTable
            title={<TableHeader title={t('Order Status')} />}
            columns={columns}
            data={orderStatsData.orderStat || []}
            pagination
            customStyles={customStyles}
          />
        )}
      </Container>
    </>
  );
};

export default withTranslation()(OrderStats);



