import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Button, Box } from '@mui/material';
import DataTable from 'react-data-table-component';
import Header from '../components/Headers/Header';
import { customStyles } from '../utils/tableStyleFOrAllOrders';
import useGlobalStyles from '../utils/globalStyles';
import { orderCustomStyles } from '../utils/tableForOutFOrDeliveryReportDetails';
import CustomLoader from '../components/Loader/CustomLoader';

const AasignRiderResultPage = (props) => {
  const { t } = props;
  const globalClasses = useGlobalStyles();
  const assignriderdetails = JSON.parse(localStorage.getItem("assignOrderDetails"));
  const [loading, setLoading] = useState(false); // State for loader

  const { assigned, unassigned } = assignriderdetails || {}; // Ensure data is safely accessed

  // Flatten the assigned orders across all riders
  const assignedOrders = assigned
    ? assigned.reduce((acc, rider) => {
        // Add riderEmail to each order
        const riderOrders = rider.orders.map(order => ({
          ...order,
          riderEmail: rider.riderEmail, // Add riderEmail to order data
        }));
        return acc.concat(riderOrders);
      }, [])
    : [];
  // Function to convert the deliveryDate into ISO format
  function convertToISO(deliveryDateString) {
    // Remove ordinal suffix using regex
    const cleanedDate = deliveryDateString.replace(/(\d+)(st|nd|rd|th)/, "$1");
  
    // Extract the date and time portions
    const [datePart, timeRange] = cleanedDate.split(",");
    const [startTime, endTime] = timeRange.split(" - ");
  
    // Parse the date and start time into a Date object
    const parsedDate = new Date(`${datePart} ${startTime}`);
  
    // Return the ISO string
    return parsedDate.toISOString();
  }
    
  const orderColumns = [
    {
      name: 'Rider Email',
      selector: 'riderEmail',
      cell: (row) => row.riderEmail || 'N/A',
      style: { cursor: 'pointer', minWidth: "200px", textAlign: 'left' },
   
    },
    { name: 'Order ID', selector: '_id', cell: (row) => <>{row._id.slice(-5)}</> },
    { name: 'Delivery Date', selector: 'deliveryDate' , cell: (row) => <>{new Date(convertToISO(row.deliveryDate)).toLocaleDateString()}</>, // Convert and format Delivery Date
  },
  {
    name: 'Customer Name',
    selector: 'customerName',
    cell: (row) => <>{row.customerName || 'N/A'}</>,
  },

  {
    name: 'Customer Phone',
    selector: 'customerPhone',
    cell: (row) => <>{row.customerPhone || 'N/A'}</>,
  },
    {
      name: 'Delivery Address',
      sortable: false,
      cell: (row) => {
        const deliveryAddress = row.deliveryAddress;
        const details = deliveryAddress ? deliveryAddress.details : "NA";
        const address = deliveryAddress ? deliveryAddress.deliveryAddress : "NA";
        return <>{`${details}, ${address}`}</>;
      },
      style: { cursor: 'pointer', minWidth: "300px", textAlign: 'left' },
    },
    { name: 'Amount', selector: 'orderAmount' },
  
  ];

  const downloadCSV = () => {
    const csvRows = [];
    const headers = ['Order ID', 'Customer Name','Customer Phone', 'Delivery Address', 'Order Amount', 'Rider Email'];

    const escapeCsvValue = (value) => {
      if (!value) return 'N/A';
      return `"${value.replace(/"/g, '""')}"`;
    };

    csvRows.push('Assigned Orders');
    csvRows.push(headers.join(','));

    if (assignedOrders.length) {
      assignedOrders.forEach(order => {
        const row = [
          order._id,
          order.customerName || 'N/A',
          order.customerPhone || 'N/A',
          escapeCsvValue(`${order.deliveryAddress.details}, ${order.deliveryAddress.deliveryAddress}`),
          order.orderAmount || 'N/A',
          order.riderEmail || 'N/A'
        ];
        csvRows.push(row.join(','));
      });
    }

    csvRows.push('');
    csvRows.push('Unassigned Orders');
    csvRows.push(headers.join(','));

    if (unassigned && unassigned.orders) {
      unassigned.orders.forEach(order => {
        const row = [
          order._id,
          order.customerName || 'N/A',
          order.customerPhone || 'N/A',
          escapeCsvValue(`${order.deliveryAddress.details}, ${order.deliveryAddress.deliveryAddress}`),
          order.orderAmount || 'N/A',
          order.riderEmail || 'N/A'
        ];
        csvRows.push(row.join(','));
      });
    }

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'assigned_and_unassigned_orders.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleback = () => {
    props.history.push('/local_admin/assignrider');
    localStorage.removeItem('assignOrderDetails');
  };

  return (
    <>
      <Header />
      <Container className={globalClasses.flex} fluid>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button onClick={downloadCSV} className={globalClasses.dashbordBtn}>Download CSV</Button>
          <Button onClick={handleback} className={globalClasses.dashbordBtn}>Back</Button>
        </Box>

        {loading ? (
          <CustomLoader />
        ) : assignriderdetails ? (
          <>
            <br />
            <DataTable
              customStyles={orderCustomStyles}
              title="Assigned Orders"
              columns={orderColumns}
              data={assignedOrders} // Use flattened assigned orders data
              pagination
            />
            <br />
            <DataTable
              customStyles={orderCustomStyles}
              title="Unassigned Orders"
              columns={orderColumns}
              data={unassigned.orders || []} // Safely access unassigned orders
              pagination
            />
          </>
        ) : (
          <p>No order details found.</p>
        )}
      </Container>
    </>
  );
};

export default withTranslation()(AasignRiderResultPage);
