/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useQuery, gql, useApolloClient } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import CustomLoader from '../components/Loader/CustomLoader'
// core components
import Header from '../components/Headers/Header'
import { GetOrdersByWorkerEmail } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import {
  Container, Button,
  Typography,
  Box,
} from '@mui/material'
import { customStyles } from '../utils/tableStyleFOrAllOrders'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const GET_WORKER_DETAILS = gql`
  ${GetOrdersByWorkerEmail}
`

const WorkerReportForCSAdmin = props => {
  const { t } = props;
  const client = useApolloClient();
  const globalClasses = useGlobalStyles()
  const userType = JSON.parse(localStorage.getItem('user-native')).userType
  const history = useHistory();
  const [page, setPage] = useState(
    1
  );
  const workerEmail = localStorage.getItem('workerEmail')
  var [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  let routePath = '';
  switch (userType) {
    case 0:
      routePath = '/super_admin/allorders';
      break;
    case 1:
      routePath = '/cs_admin/allorders';
      break;
    case 2:
      routePath = '/local_admin/allorders';
      break;
    default:
      routePath = '/super_admin/allorders'; // Default to super admin if userType is not defined
      break;
  }

  const { data, loading: loadingQuery, refetch } = useQuery(GET_WORKER_DETAILS, {
    fetchPolicy: 'network-only',
    variables: {
      // email: "pickandpack02@gmail.com",
      email: localStorage.getItem('workerEmail') || "",
      limit: perPage,
      skip: (page - 1) * perPage,
    },
    onCompleted: (data) => {
      if (data && data.getOrdersByWorkerEmail) {
        tableData = data.getOrdersByWorkerEmail.orderworkerstatuses || []
        setTableData(tableData);
        setTotalRows(data.getOrdersByWorkerEmail.totalCount || 0);

      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });

  useEffect(() => {
    if (data) {
      setTotalRows(data.getOrdersByWorkerEmail.totalCount);
      tableData = data.getOrdersByWorkerEmail.orderWorkerStatus
      setTableData(tableData)
    }
  }, [data]);

  // Update the query variables when the page value changes
  useEffect(() => {
    refetch({
      variables: {
        limit: perPage,
        skip: (page - 1) * perPage,
      },
    });
  }, [page, perPage, refetch]);


  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)

  };

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }

  const getWorkerStatus = (status) => {
    const statusMap = {
      0: 'Not Started',
      1: 'Pp Assigned',
      2: 'Pp In Progress',
      3: 'Pp Completed',
      4: 'Billing Assigned',
      5: 'Billing In Progress',
      6: 'Billing Completed',
      7: 'Rider Assign Manual',
      8: "Rider Assigned",
      9: 'Rider Out For Delivery',
      10: 'Rider Delivered'
    };

    return statusMap[status] || 'N/A';
  };

  const getOrderWorkerEmail = (orderState) => {
    switch (orderState.status) {
      case 1:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 2:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 3:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 4:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 5:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 6:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 7:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 8:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 9:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      default:
        return 'No Worker Assigned';
    }
  };

  function CheckOrderStatus(orderStatusCode) {

    if (orderStatusCode === 0) {
      return "Initiated"
    }
    else if (orderStatusCode === 1) {
      return "Confirmed"
    }
    else if (orderStatusCode === 2) {
      return "Packing"
    }
    else if (orderStatusCode === 3) {
      return "Order billing"
    }
    else if (orderStatusCode === 4) {
      return "Ready for delivery"
    }
    else if (orderStatusCode === 5) {
      return "Rider Assigned"
    }
    else if (orderStatusCode === 6) {
      return "Out for delivery"
    }
    else if (orderStatusCode === 7) {
      return "Delivered"
    }
    else if (orderStatusCode === 8) {
      return "Cancelled"
    }
    else if (orderStatusCode === 9) {
      return "Out of stock"
    }
    else if (orderStatusCode === 10) {
      return "Invalid delivery address"
    }
    else if (orderStatusCode === 12) {
      return "No Items Cart"
    }
    else {
      return "Failed"
    }

  }
  function getOrderStatusColor(orderStatusCode) {
    switch (orderStatusCode) {
      case 0: return "grey"; // Initiated
      case 1: return "blue"; // Confirmed
      case 2: return "orange"; // Packing
      case 3: return "purple"; // Order billing
      case 4: return "darkblue"; // Ready for delivery
      case 5: return "teal"; // Rider Assigned
      case 6: return "green"; // Out for delivery
      case 7: return "lightgreen"; // Delivered
      case 8: return "red"; // Cancelled
      case 9: return "darkred"; // Out of stock
      case 10: return "brown"; // Invalid delivery address
      case 12: return "yellow"; // No Items Cart
      default: return "black"; // Failed
    }
  }
  const columns = [
    {
      name: 'Order ID',
      selector: '_id', // Assuming "_id" is the field containing the order ID
      cell: (row) => <>{row._id.slice(-5)}</>, // Display only the last 5 characters of the Order ID
      style: { cursor: 'pointer' },
    },
    {
      name: 'Order Date',
      selector: 'orderDate',
      cell: (row) => <>{new Date(row.orderDate).toLocaleDateString()}</>, // Show only the date part
      style: { cursor: 'pointer', minWidth: "110px" },
    },
    {
      name: 'Delivery Date',
      selector: 'deliveryDate',
      cell: (row) => {
        // Extract the date part from the delivery date string
        const datePart = row.deliveryDate.split(',')[0];
        // Parse the date part and format it
        const [day, month, year] = datePart.split(' ');
        const formattedDate = new Date(`${month} ${day.replace(/\D/g, '')}, ${year}`).toLocaleDateString();
        return <>{formattedDate}</>;
      },
      style: { cursor: 'pointer', minWidth: "110px" },
    },

    {
      name: 'Amount',
      selector: 'orderAmount',
      style: { cursor: 'pointer' },
    },

    {
      name: 'Order Status',
      selector: 'orderStatus',
      cell: (row) => (
        <Typography style={{ color: getOrderStatusColor(row.orderStatus), fontWeight: 600 }}>
          {CheckOrderStatus(row.orderStatus)}
        </Typography>
      ),
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Worker Status',
      selector: 'workerEmail',
      cell: (row) => <>{getWorkerStatus(row.orderState.workerStatus)}</>,
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: 'Worker Email',
      selector: 'workerEmail',
      cell: (row) => {
        const workerEmail = getOrderWorkerEmail(row.orderState);
        return workerEmail !== 'No Worker Assigned' ? (
          userType === 1 ? (
            <Link to={`/worker-dashboard/${workerEmail}`}>{workerEmail}</Link>
          ) : (
            <>{workerEmail}</>
          )
        ) : (
          <>{workerEmail}</>
        );
      },
      style: { cursor: 'pointer', minWidth: '250px' },
    },
 
    {
      name: "Delivery Address",
      sortable: false,
      cell: row => row.deliveryAddress ? row.deliveryAddress.deliveryAddress : "N/A", // Safely access delivery address

      style: { cursor: 'pointer', minWidth: "300px" },
    },
    {
      name: 'Local Store',
      cell: row => row.localStore ? row.localStore : "N/A", // Safely access delivery address
      style: { cursor: 'pointer' },
    },


  ];


  const handleBackClick = () => {
    localStorage.removeItem('workerEmail');
    history.push(routePath); // Navigate back based on user type
  };
  return (
    <>
      <Header />

      <Container className={globalClasses.flex} fluid>
        <Box sx={{ textAlign: 'right' }}>

          <Button className={globalClasses.dashbordBtn} onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
        {loadingQuery ? (
          <CustomLoader />
        ) :
          <DataTable
            customStyles={customStyles}
            subHeader={true}

            title={<TableHeader title={workerEmail ? ` ${workerEmail} Order Details` : t('Worker Order Details')} />}
            columns={columns}
            data={tableData || []}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            sortFunction={customSort}
          // selectableRows
          />
        }
      </Container>
    </>
  )
}
export default withTranslation()(WorkerReportForCSAdmin)
