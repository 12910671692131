const de = {
  translation: {
    'Sign in credentials': 'Anmeldeinformationen',
    'Sign in': 'Einloggen',
    'Add Category': 'Kategorie hinzufügen',
    Title: 'Titel',
    Description: 'Beschreibung',
    Add: 'Hinzufügen',
    Remove: 'Löschen',
    'Add/Remove': 'Hinzufügen/Entfernen',
    Success: 'Erfolg',
    Danger: 'Achtung',
    Categories: 'Kategorien',
    Image: 'Bild',
    Header: 'Header',
    Loading: 'Wird geladen',
    Error: 'Error',
    Edit: 'Bearbeiten',
    Delete: 'Löschen',
    'Edit Category': 'Kategorie bearbeiten',
    Save: 'sparen',
    'OrderID prefix': 'OrderID-Präfix',
    Order: 'Auftrag',
    Email: 'Email',
    Password: 'Passwort',
    Enable: 'Aktivieren',
    Disable: 'Deaktivieren',
    'Enable/Disable': 'Aktivieren deaktivieren',
    Saving: 'Speichern',
    Paypal: 'Paypal',
    'Client ID': 'Kunden ID',
    'Client Secret': 'Kundengeheimnis',
    Sandbox: 'Sandkasten',
    'Publishable Key': 'Veröffentlichbarer Schlüssel',
    'Secret Key': 'Geheimer Schlüssel',
    'Delivery Charges': 'Versandkosten',
    Price: 'Preis',
    'Add Food': 'Lebensmittel hinzufügen',
    Category: 'Kategorie',
    Select: 'Wählen',
    Variations: 'Variationen',
    Type: 'Art',
    'Food Image': 'Food Image',
    Foods: 'Lebensmittel',
    Actions: 'Aktionen',
    'Edit Food': 'Essen bearbeiten',
    OrderID: 'Auftragsnummer',
    Orders: 'Aufträge',
    Name: 'Name',
    Items: 'Artikel',
    Payment: 'Zahlung',
    Status: 'Status',
    Review: 'Rezension',
    'Mark as': 'markieren als',
    Users: 'Benutzer',
    Phone: 'Telefon',
    Address: 'Adresse',
    2019: '2019',
    'About Us': 'Über uns',
    Blog: 'Blog',
    Welcome: 'Herzlich willkommen',
    Logout: 'Ausloggen',
    Dashboard: 'Instrumententafel',
    Configuration: 'Aufbau',
    Login: 'Anmeldung',
    Food: 'Essen',
    'Character limit of max length 50': 'Zeichenbegrenzung von maximaler Länge 50',
    'Character limit of max length 15': 'Zeichenbegrenzung von maximaler Länge 15',
    'Character limit of max length 60': 'Zeichenbegrenzung von maximaler Länge 60',
    'Character limit of max length 20': 'Zeichenbegrenzung von maximaler Länge 20',
    'Character limit of max length 140': 'Zeichenbegrenzung von maximaler Länge 140',
    Currency: 'Währung',
    Delivery: 'Lieferung',
    'Currency Code': 'Währungscode',
    'Delivery Rate': 'Lieferpreis',
    'Currency Symbol': 'Währungssymbol',
    enterYourDetailsBelow: 'Geben Sie Ihre Details unten ein',
    LogintoEnatega: 'Einloggen bei NativeNest',
    RememberMe: 'Erinnere dich an mich',
    ForgotYourPassword: 'Passwort vergessen?',
    errorWhileSaving: 'Ein Fehler ist aufgetreten beim Speichern, Versuchen Sie es erneut',
    Saved: 'Gespeichert',
    Addons: 'Erweiterungen',
    AddRemoveAddon: 'Erweiterung hinzufügen oder entfernen',
    MinQuantity: 'Mindestmenge',
    MinimumQuantity: 'Mindestmenge',
    MaxQuantity: 'Maximale Menge',
    MaximumQuantity: 'Maximale Menge',
    Options: 'Optionen',
    NewOption: 'Neue Option',
    CategoryUpdatedSuccessfully: 'Kategorie erfolgreich aktualisiert',
    CategoryAddedSuccessfully: 'Kategorie erfolgreich hinzugefügt',
    ActionFailedTryAgain: 'Aktion fehlgeschlagen. Bitte versuchen Sie es erneut',
    PHCategory: 'Kategorie, z.B. Frühstück',
    ChooseCurrency: 'Währung wählen',
    ChooseSymbol: 'Symbol wählen',
    PHMail: 'z.B. something@email.com',
    EmailName: 'E-Mail-Name',
    PHEnatega: 'z.B. Enatega',
    PHFood: 'z.B. ESSEN',
    ClientSecretKey: 'Geheimer Client-Schlüssel',
    Stripe: 'Stripe',
    PublishKey: 'Veröffentlichungsschlüssel',
    CouponUpdated: 'Gutschein aktualisiert',
    CouponAdded: 'Gutschein hinzugefügt',
    Code: 'Code',
    PHCode: 'Code, z.B. SALE50',
    PHDiscount: 'Rabatt %, d.h. 1-99',
    FoodUpdatedSuccessfully: 'Essen erfolgreich aktualisiert',
    FoodAddedSuccessfully: 'Essen erfolgreich hinzugefügt',
    SelectCategory: 'Kategorie auswählen',
    UploadAnImage: 'Ein Bild hochladen',
    UniqueTitle: 'Titel (muss einzigartig sein)',
    Discounted: 'Reduziert',
    LoadingDots: 'Laden ...',
    ErrorDots: 'Fehler ...',
    NewAddon: 'Neue Erweiterung',
    EnategaMultivendor: 'Enatega Multivendor',
    ResetPassword: 'Passwort zurücksetzen',
    EnategaDashboard: 'Enatega-Dashboard',
    UpdateOption: 'Option aktualisieren',
    AddOption: 'Option hinzufügen',
    OrderStatusUpdated: 'Bestellstatus aktualisiert',
    SpecialInstructions: 'Besondere Anweisungen',
    Charges: 'Gebühren',
    Subtotal: 'Zwischensumme',
    DeliveryFee: 'Liefergebühr',
    TaxCharges: 'Steuergebühren',
    Tip: 'Trinkgeld',
    Total: 'Gesamt',
    PaymentMethod: 'Zahlungsmethode',
    PaidAmount: 'Bezahlter Betrag',
    Accepted: 'Akzeptiert',
    Accept: 'Akzeptieren',
    Cancelled: 'Abgebrochen',
    Cancel: 'Abbrechen',
    PHReasonIfRejected: 'Grund, wenn abgelehnt',
    Datetime: 'Datum und Uhrzeit',
    PasswordChangedSuccessfully: 'Passwort erfolgreich geändert',
    ConfirmPassword: 'Passwort bestätigen',
    Reset: 'Zurücksetzen',
    RestaurantAdded: 'Restaurant hinzugefügt',
    NetworkError: 'Netzwerkfehler',
    FieldsRequired: 'Felder erforderlich',
    Username: 'Benutzername',
    RestaurantUsername: 'Benutzername des Restaurants',
    RestaurantPassword: 'Passwort des Restaurants',
    RestaurantName: 'Name des Restaurants',
    RestaurantAddress: 'Adresse des Restaurants',
    DeliveryTime: 'Lieferzeit',
    MinOrder: 'Mindestbestellung',
    SalesTax: 'Umsatzsteuer',
    RiderUpdatedSuccessfully: 'Fahrer erfolgreich aktualisiert',
    RiderAddedSuccessfully: 'Fahrer erfolgreich hinzugefügt',
    EditRider: 'Fahrer bearbeiten',
    AddRider: 'Fahrer hinzufügen',
    Available: 'Verfügbar',
    PHRiderName: 'Name des Fahrers',
    Number: 'Nummer',
    PhoneNumber: 'Telefonnummer',
    RiderZone: 'Fahrerzone',
    SectionUpdatedSuccessfully: 'Abschnitt erfolgreich aktualisiert',
    SectionAddedSuccessfully: 'Abschnitt erfolgreich hinzugefügt',
    EditSection: 'Abschnitt bearbeiten',
    AddSection: 'Abschnitt hinzufügen',
    SectionName: 'Name des Abschnitts',
    Update: 'Aktualisieren',
    GENERAL: 'ALLGEMEIN',
    MANAGEMENT: 'MANAGEMENT',
    RESTAURANT: 'RESTAURANT',
    BackToAdmin: 'Zurück zum Admin',
    Search: 'Suche',
    TippingUpdated: 'Trinkgeld aktualisiert',
    EditTipping: 'Trinkgeld bearbeiten',
    AddTipping: 'Trinkgeld hinzufügen',
    Tip1: 'Trinkgeld 1',
    Tip2: 'Trinkgeld 2',
    Tip3: 'Trinkgeld 3',
    PHTip1: 'Trinkgeld 1, z.B. 10',
    PHTip2: 'Trinkgeld 2, z.B. 20',
    PHTip3: 'Trinkgeld 3, z.B. 30',
    EditVendor: 'Verkäufer bearbeiten',
    AddVendor: 'Verkäufer hinzufügen',
    PHNumber: 'z.B. 123',
    ZoneUpdatedSuccessfully: 'Zone erfolgreich aktualisiert',
    ZoneAddedSuccessfully: 'Zone erfolgreich hinzugefügt',
    SetZoneOnMap: 'Zone auf Karte festlegen',
    EditZone: 'Zone bearbeiten',
    AddZone: 'Zone hinzufügen',
    Minimum: 'Minimum',
    Maximum: 'Maximum',
    Action: 'Aktion',
    AvailableAfterPurchasing: 'Diese Funktion ist nach dem Kauf des Produkts verfügbar',
    CommissionRates: 'Provisionsraten',
    PHCommission: 'Prozentsatz der Provision',
    Discount: 'Rabatt %',
    Coupons: 'Gutscheine',
    StartDate: 'Startdatum',
    GraphFilter: 'Grafikfilter',
    EndDate: 'Enddatum',
    Apply: 'Anwenden',
    UpdatingLocationError: 'Ein Fehler ist aufgetreten beim Aktualisieren von Standort und Begrenzungen',
    LocationMarkerRequired: 'Standortmarker ist erforderlich',
    DeliveryAreaRequired: 'Lieferbereich ist erforderlich',
    LocationUpdatedSuccessfully: 'Standort und Begrenzungen erfolgreich aktualisiert',
    SetLocation: 'Standort festlegen',
    DrawDeliveryBounds: 'Liefergrenzen zeichnen',
    SetRestaurantLocation: 'Restaurantstandort festlegen',
    RemoveDeliveryBounds: 'Liefergrenzen entfernen',
    RemoveRestaurantLocation: 'Restaurantstandort entfernen',
    Reject: 'Ablehnen',
    Delivered: 'Geliefert',
    OrderInformation: 'Bestellinformation',
    RestaurantCol: 'Restaurant',
    Rider: 'Fahrer',
    OrderTime: 'Bestellzeit',
    Dispatch: 'Versand',
    PasswordUpdated: 'Passwort wurde aktualisiert',
    ForgotPassword: 'Passwort vergessen',
    NotificationSentAuccessfully: 'Benachrichtigung erfolgreich gesendet',
    Notifications: 'Benachrichtigungen',
    Body: 'Text',
    Zones: 'Zonen',
    RequestID: 'Anfrage-ID',
    Amount: 'Betrag',
    Date: 'Datum',
    REQUESTED: 'ANGEFORDERT',
    TRANSFERRED: 'ÜBERTRAGEN',
    CANCELLEDStatus: 'ABGEBROCHEN',
    WithdrawRequests: 'Auszahlungsanfragen',
    TotalRestaurants: 'Gesamtzahl der Restaurants',
    Restaurants: 'Restaurants',
    Vendors: 'Verkäufer',
    RestaurantUpdatedSuccessfully: 'Restaurant erfolgreich aktualisiert',
    UpdateProfile: 'Profil aktualisieren',
    PHRestaurantPassword: 'Passwort des Restaurants',
    PHRestaurantName: 'Name des Restaurants',
    PHRestaurantAddress: 'Adresse des Restaurants',
    OrderPrefix: 'Bestellpräfix',
    Days: 'Tage',
    OpenTimes: 'Öffnungszeiten',
    TimeSavedSuccessfully: 'Zeit erfolgreich gespeichert',
    ErrorWhileSavingTime: 'Fehler beim Speichern der Zeit',
    SavingDots: 'Speichern...',
    January: 'Januar',
    February: 'Februar',
    March: 'März',
    April: 'April',
    May: 'Mai',
    June: 'Juni',
    July: 'Juli',
    MainPageText: 'Eine plattformübergreifende Software',
    MainPageText1: 'Eine umfassende Lösung, die sich hervorragend zum Aufbau eines Restaurants eignet.',
    ViewSite: 'Website anzeigen',
    TotalUsers: 'Gesamtzahl der Benutzer',
    TotalVendors: 'Gesamtzahl der Verkäufer',
    TotalRiders: 'Gesamtzahl der Fahrer',
    RestaurantSections: 'Restaurantabschnitte',
    ImageMenu: 'Bildermenü',
    Vendor: 'Verkäufer',
    AddNewRestaurant: 'Neues Restaurant hinzufügen',
    Ratings: 'Bewertungen',
    StripeDetailsAttached: 'Stripe-Details angefügt',
    EditStripeDetails: 'Stripe-Details bearbeiten',
    SubmitStripeDetails: 'Stripe-Details senden',
    "Restaurant Sections": 'Restaurant Abschnitte',
    Riders: 'Fahrer',
    Tipping: 'Trinkgeld',
    Zone: 'Zone',
    "Commission Rates": 'Provisionsraten',
    "Withdraw Requests": 'Auszahlungsanfragen',
    Home: 'Startseite',
    Profile: 'Profil',
    Option: 'Option',
    Timings: 'Öffnungszeiten',
    Location: 'Ort',
    "Back to Admin": 'Zurück zum Admin',
    TotalOrders: 'Gesamtbestellungen',
    TotalSales: 'Gesamtumsatz',
    ClosedAllDay: 'Den ganzen Tag geschlossen',
    AddRestaurant: 'Restaurant hinzufügen',
    EditCoupon: 'Gutschein bearbeiten',
    AddCoupon: 'Gutschein hinzufügen',
    SalesAmount: 'Verkaufsbetrag',
    OrderCount: 'Bestellanzahl',
    MON: "Mo",
    TUE: "Di",
    WED: "Mi",
    THU: "Do",
    FRI: "Fr",
    SAT: "Sa",
    SUN: "So",
    Open: "Geöffnet"
  }
}

export default de
