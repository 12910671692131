import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql, useApolloClient, useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { AddBanner, AddLandingPageDisplay, getAllBanner } from '../../apollo';
import {
    Button, Box, Typography, Grid, TextField, DialogActions, Alert, Snackbar, Switch,
    IconButton,
    Container
} from '@mui/material';
import useGlobalStyles from '../../utils/globalStyles';
import DeleteIcon from '@mui/icons-material/Delete'; // 
import Header from '../Headers/Header';
import CustomLoader from '../Loader/CustomLoader';

const GET_STORE_BANNER = gql`
  ${getAllBanner}
`;
const CREATE_STORE_DISPLAY_TYPE = gql`
  ${AddLandingPageDisplay}
`;

function ManageLandingPage(props) {
    const golbalClasses = useGlobalStyles()
    const { onClose, vendor } = props;
    const formRef = useRef();
    const mutation = CREATE_STORE_DISPLAY_TYPE;
    const [error, errorSetter] = useState('');
    const client = useApolloClient();
    const [success, successSetter] = useState('');
    const [openSnackk, setopenSnackk] = useState(false);
    const { t } = props;
    const initialBanners =  [{
        _id:"",  displayName: '', displayType: '', rows: null, columns: null,displayPosition: null 
    }];
    const [banners, setBanners] = useState(initialBanners);
    const [formData, setFormData] = useState({
        name: vendor ? vendor.name : '',
        deviceType: vendor ? vendor.isActive : ''
    });
    const [reload ,setReload] =useState(false)

    const { loading: loadingQuery,  data, refetch } = useQuery(
        GET_STORE_BANNER,
        {
            onError: error => {
                const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
                if (isForbiddenError) {
                    localStorage.removeItem('user-native');
                    localStorage.removeItem('restaurant_id');
                    client.clearStore();
                    props.history.push('/auth/login');
                }
            },
        }
    );

    // useEffect(() => {
    //     if (data && data.getBanner) {
    //         const { getBanner } = data;
    //         setFormData({ name: getBanner.name, isActive: getBanner.isActive });
    //         setBanners(getBanner.contents);
    //     }
    // }, [data ,reload]);

  
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        setBanners(prevBanners => {
            const newBanners = [...prevBanners];
            newBanners[index] = { ...newBanners[index], [name]: value };
            return newBanners;
        });
    };

    const handleFormInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const addBanner = () => {
        setBanners([...banners, { _id:"",  displayName: '', displayType: '', rows: null, columns: null,displayPosition: null }]);
    };

    const onCompleted = (data) => {
        if (!props.vendor) clearFields();
        const message = props.vendor ? t('Store Updated Successfully') : t('Display Type Added Successfully');
        errorSetter('');
        successSetter(message);
        setopenSnackk(true);
        refetch()
        // client()
        setReload(!reload)
        setTimeout(hideAlert, 1000);
    };

    const onError = ({ graphQLErrors, networkError }) => {
        successSetter('');
        if (graphQLErrors) {
            errorSetter(graphQLErrors[0].message);
        } else if (networkError) {
            errorSetter(networkError.result.errors[0].message);
        } else {
            errorSetter('Something went wrong!');
        }
        setopenSnackk(true);
        refetch();
        setTimeout(hideAlert, 1000);
    };

    const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
        refetchQueries: [{ query: GET_STORE_BANNER }],
        onError,
        onCompleted,
    });

    const clearFields = () => {
        formRef.current.reset();
        setFormData({ name: '', isActive: false });
        setBanners([{_id:"",  displayName: '', displayType: '', rows: null, columns: null,displayPosition: null  }]);
    };

    const hideAlert = () => {
        errorSetter('');
        successSetter('');
        setopenSnackk(false);
    };

    const globalClasses = useGlobalStyles();
    const inputTextStyle = { color: 'black' };
    const [formErrors, setFormErrors] = useState({});
    const validateFormData = () => {
        let errors = {};
        // if (!formData.name) errors['name'] = 'Name is required';
        // banners.forEach((banner, index) => {
        //     if (!banner.action) errors[`action_${index}`] = 'Action is required';
        //     if (!banner.image) errors[`image_${index}`] = 'Image URL is required';
        // });
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        // if (!validateFormData()) {
        //     return;
        // }

        const bannerInput = banners.map(banner => ({
            _id:banner._id,
            displayName: banner.displayName,
            displayType: parseInt(banner.displayType),
            rows:parseInt(banner.rows),
            columns:parseInt(banner.columns),
            displayPosition: parseInt(banner.displayPosition)
        }));

        mutate({
            variables: {
                "landingPageInput": {
                    "name": formData.name,
                    "deviceType":parseInt(formData.deviceType) ,
                    "landingPageDisplays": bannerInput
                }
            }
        });

        setTimeout(() => {
            if (typeof props.onClose === 'function') {
                props.onClose();
            }
        }, 1000);
    };
    const handleDeleteBanner = (index) => {
        const newBanners = banners.filter((banner, idx) => idx !== index);
        setBanners(newBanners);
    };
    return (

        <>
            <Header />
         {  loadingQuery ?
<>
<CustomLoader />
</>
         :
         <>
         <Container className={golbalClasses.flex} >

                <Grid sx={{ backgroundColor: 'white', py: 4, margin: "1rem", padding: "1rem" }} >



                    <form ref={formRef} onSubmit={onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="name"
                                    helperText={formErrors['name']}
                                    error={Boolean(formErrors['name'])}
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.name}
                                    onChange={handleFormInputChange}
                                    inputProps={{ style: inputTextStyle }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="deviceType"
                                    helperText={formErrors['deviceType']}
                                    error={Boolean(formErrors['deviceType'])}
                                    label="Device Type"
                                    type='number'
                                    variant="outlined"
                                    fullWidth
                                    value={formData.deviceType}
                                    onChange={handleFormInputChange}
                                    inputProps={{ style: inputTextStyle }}
                                />
                            </Grid>
                           
                        </Grid>

                        {banners.map((banner, index) => (
                            <> <br></br>
                                <Grid container spacing={2} key={index}>
                               < Grid item xs={12} sm={3}>
                                        <TextField
                                            name="_id"
                                            helperText={formErrors[`_id_${index}`]}
                                            error={Boolean(formErrors[`_id_${index}`])}
                                            label="ID"
                                            variant="outlined"
                                            fullWidth
                                            value={banner._id}
                                            onChange={(e) => handleInputChange(e, index)}
                                            inputProps={{ style: inputTextStyle }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            name="displayName"
                                            helperText={formErrors[`displayName_${index}`]}
                                            error={Boolean(formErrors[`displayName_${index}`])}
                                            label="Display Name"
                                            variant="outlined"
                                            fullWidth
                                            value={banner.displayName}
                                            onChange={(e) => handleInputChange(e, index)}
                                            inputProps={{ style: inputTextStyle }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            name="displayType"
                                            helperText={formErrors[`displayType_${index}`]}
                                            error={Boolean(formErrors[`displayType_${index}`])}
                                            label="Display Type"
                                            variant="outlined"
                                             type='number'
                                            fullWidth
                                            value={banner.displayType}
                                            onChange={(e) => handleInputChange(e, index)}
                                            inputProps={{ style: inputTextStyle }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            name="rows"
                                            helperText={formErrors[`rows_${index}`]}
                                            error={Boolean(formErrors[`rows_${index}`])}
                                            label="Rows"
                                            variant="outlined"
                                             type='number'
                                            fullWidth
                                            value={banner.rows}
                                            onChange={(e) => handleInputChange(e, index)}
                                            inputProps={{ style: inputTextStyle }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            name="columns"
                                            helperText={formErrors[`columns_${index}`]}
                                            error={Boolean(formErrors[`columns_${index}`])}
                                            label="Columns"
                                             type='number'
                                            variant="outlined"
                                            fullWidth
                                            value={banner.columns}
                                            onChange={(e) => handleInputChange(e, index)}
                                            inputProps={{ style: inputTextStyle }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            name="displayPosition"
                                            helperText={formErrors[`displayPosition_${index}`]}
                                            error={Boolean(formErrors[`displayPosition_${index}`])}
                                            label="Display Position"
                                             type='number'
                                            variant="outlined"
                                            fullWidth
                                            value={banner.displayPosition}
                                            onChange={(e) => handleInputChange(e, index)}
                                            inputProps={{ style: inputTextStyle }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <IconButton onClick={() => handleDeleteBanner(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </>
                        ))}

                        <br></br>
                        <Button
                            onClick={addBanner}
                            className={globalClasses.dashbordBtn}
                        >
                            Add More
                        </Button>
                        <DialogActions>
                            <Button
                                type="submit"
                                className={globalClasses.dashbordBtn}
                                disabled={mutateLoading}
                            >
                                {t('Save')}
                            </Button>
                            <Button
                                className={globalClasses.modalCancleBtn}
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Grid>
            </Container>
            </>
            }

            <Box mt={2}>
                {success && (
                    <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                            {success}
                        </Alert>
                    </Snackbar>
                )}
                {error && (
                    <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                )}
            </Box>
        </>

    );
}

export default withTranslation()(ManageLandingPage);
