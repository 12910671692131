import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import Header from '../components/Headers/Header';
import CustomLoader from '../components/Loader/CustomLoader';
import { GetReplenishRequest, ApproveReplenishRequest } from '../apollo';
import DataTable from 'react-data-table-component';
import { customStyles } from '../utils/tableForViewReplentionItems';
import useGlobalStyles from '../utils/globalStyles';
import { useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import {
  Container,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  TextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, Alert, Snackbar,
  Divider
} from '@mui/material';
import TableHeader from '../components/TableHeader';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useStyles from '../components/Section/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment'; // Import moment library


const GET_REPLENTION_PRODUCTS_ID = gql`${GetReplenishRequest}`;
const APPROVE_REPELENTION = gql`${ApproveReplenishRequest}`;


const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);


const CSAdminViewDetailsRepllenation = props => {
  const history = useHistory();
  const { t } = props;
  const [editMode, setEditMode] = useState(null);
  const ReplentionData = JSON.parse(localStorage.getItem('ReplentionData'));
  const golbalClasses = useGlobalStyles();
  const classes = useStyles();
  const client = useApolloClient();
  const [tabledata, setTabledata] = useState(null);
  const inputTextStyle = { color: 'black' };
  const [reasons, setReasons] = useState({});
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [comment, setComment] = useState('');

  const [receivedQuantities, setReceivedQuantities] = useState({});

  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const [openSnackk, setopenSnackk] = useState(false);

  const handleOpenCommentModal = () => {
    setOpenCommentModal(true);
  };

  const handleCloseCommentModal = () => {
    setOpenCommentModal(false);
  };
  const reasonOptions = [
    { label: "NEED REPLENISH", id: 0 },
    { label: "QTY SAME", id: 1 },
    { label: "QTY CHANGE CS", id: 2 },
    { label: "QTY NOT AVAILABLE", id: 3 },
    { label: "QTY DAMAGED TRANSIT", id: 4 },
    { label: "QTY MISSING TRANSIT", id: 5 },
    { label: "ITEM REMOVED", id: 6 },
    { label: "REPLENISHED", id: 7 }
  ];

  const formatDate = date => {
    const formattedDate = moment(date).format('Do MMMM YYYY, hh:mm a');
    return formattedDate;
  };

  const variables = useMemo(() => ({
    replenishRequestId: ReplentionData ? ReplentionData._id : null,
  }), [ReplentionData]);

  const { loading: loadingQuery, data, refetch } = useQuery(
    GET_REPLENTION_PRODUCTS_ID, {
    variables,
    onCompleted: (data) => {
      setTabledata(data && data.getReplenishRequest ? data.getReplenishRequest.requestedItems : []);
    },
    onError: (error) => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  );

  const getReasonForStatus = (item) => {
    const lastItemState = item.requestedItemStates[item.requestedItemStates.length - 1];
    if (lastItemState) {
      return lastItemState.reason;
    }
    return 1; // Default value if no valid itemState is found
  };
  const handleConfirm = async () => {
    const approvedItems = tabledata.map(item => ({
      productId: item.productId,
      approvedQty: parseFloat(receivedQuantities[item.productId] || item.requestedQty),
      reason: reasons[item.productId] ? reasons[item.productId].id : getReasonForStatus(item), // Use optional chaining to handle undefined reasons
      skuId: item.skuId,
      stockId: item.stockId,
    }));

    const payload = {
      replenishRequestApprove: {
        approvedItems: approvedItems,
        comments: comment, // Assuming comment is defined in your component's state
        replenishRequestId: ReplentionData._id,
      },
    };

    try {
      const { data, errors } = await reciviedReplention({
        variables: payload,
      });

      if (data) {

        // Assuming this function closes a modal or dialog
        successSetter("Replenish Successfully");
        setopenSnackk(true)
        setTimeout(hideAlert,

          3000);
      } else if (errors) {
        errorSetter("Something Went Wrong!");
        setopenSnackk(true)
        setTimeout(hideAlert, 3000);
      }
    } catch (error) {
      errorSetter("Something Went Wrong!");
      setopenSnackk(true)
      setTimeout(hideAlert, 3000);
    }
    // handleCloseCommentModal();
  };

  const hideAlert = () => {
    handleCloseCommentModal();
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
    history.push('/cs_admin/repletion')
  };
  const [reciviedReplention, { loading: mutationLoading }] = useMutation(
    APPROVE_REPELENTION
  );
  useEffect(() => {
    if (ReplentionData) {
      // setIsReplentionDataLoaded(true);
    }
    refetch()
  }, [ReplentionData]);

  useEffect(() => {
    if (data && data.getReplenishRequest) {
      setTabledata(data && data.getReplenishRequest ? data.getReplenishRequest.requestedItems : []);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      variables: {
        replenishRequestId: ReplentionData.id,
      },
    });
  }, [refetch]);

  const handleEditClick = (row) => {
    setEditMode(row.productId);
  };
  const handleBackClick = () => {
    localStorage.removeItem('selectedRowIds'); // Remove selectedRowIds from local storage

    localStorage.removeItem('editedRows');
    history.push('/cs_admin/repletion'); // Navigate back to ManageCategory component
  };

  const handleSaveClick = row => {
    const updatedReceivedQty = parseFloat(receivedQuantities[row.productId] || row.receivedQty);
    const updatedReason = reasons[row.productId] ? reasons[row.productId].label : "REPLENISHED";

    // Update the table data with the edited values
    const updatedTableData = tabledata.map(item => {
      if (item.productId === row.productId) {
        return {
          ...item,
          approvedQty: updatedReceivedQty,
          reason: updatedReason,
        };
      }
      return item;
    });

    setTabledata(updatedTableData);
    setEditMode(null);
  };

  const handleReasonChange = (productId, value) => {
    setReasons(prevReasons => ({
      ...prevReasons,
      [productId]: value,
    }));
  };
  const handleReceivedQtyChange = (e, productId) => {
    const { value } = e.target;
    setReceivedQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: value,
    }));
  };
  const getReason = (row) => {

    const itemStates = row.requestedItemStates;
    if (itemStates && itemStates.length > 0) {
      const lastState = itemStates[itemStates.length - 1];
      return lastState.reasonString.replace(/_/g, ' ');
    }

    return "REPLENISHED";
  };
  const columns = [
    {
      name: "Stock Name",
      selector: row => row.Stock[0] ? row.Stock[0].name : '',
      style: { cursor: 'pointer' },
    },
    {
      name: "Stock Qty",
      selector: row => row.Stock[0] ? row.Stock[0].quantity : 0,
      style: { cursor: 'pointer' },
    },
    {
      name: "Product ID",
      sortable: true,
      selector: 'productId',
      style: { cursor: 'pointer' },
    },
    {
      name: "SKU ID",
      sortable: true,
      selector: 'skuId',
      style: { cursor: 'pointer', minWidth: "100px !important" },
    },
    {
      name: "Requested Qty",
      sortable: true,
      selector: 'requestedQty',
      style: { cursor: 'pointer', minWidth: "150px" },
    },
    {
      name: "Approved Qty",
      style: { cursor: 'pointer', minWidth: "150px" },
      selector: row => (
        editMode === row.productId ? (
          <TextField
            value={receivedQuantities[row.productId] || ''}
            onChange={e => handleReceivedQtyChange(e, row.productId)}
            inputProps={{ style: inputTextStyle }}
            autoFocus
          />
        ) : (
          row.requestedQty
        )
      ),
    },
    {
      name: "Received Qty",
      sortable: true,
      selector: 'receivedQty',
      style: { cursor: 'pointer', minWidth: "150px" },
    },

    {
      name: "Reason",
      cell: row => editMode === row.productId ? (
        <Autocomplete
          style={{ minWidth: "100%" }}
          options={reasonOptions}
          getOptionLabel={(option) => option.label}
          value={reasons[row.productId]}
          onChange={(e, value) => handleReasonChange(row.productId, value)}
          renderInput={(params) => <TextField {...params}
            variant="outlined"
            inputProps={{ ...params.inputProps, style: inputTextStyle }}
          />}
          PaperComponent={CustomPaperComponent}
        />
      ) : (
        row.reason ? reasons[row.productId].label : getReason(row, 1)
      ),
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Actions",
      cell: row => (
        editMode === row.productId ? (
          <>
            <Button onClick={() => handleSaveClick(row)}>Save</Button>
            <Button onClick={() => setEditMode(null)}>Cancel</Button>
          </>
        ) : (
          data && data.getReplenishRequest.approvedBy ? <Button disabled onClick={() => handleEditClick(row)}>Edit</Button> : <Button onClick={() => handleEditClick(row)}>Edit</Button>
        )
      ),
    }
  ];
  const columnsPDF = [
    {
      name: "Product ID",
      sortable: true,
      selector: 'productId',
      style: { cursor: 'pointer' },
    },
    {
      name: "SKU ID",
      sortable: true,
      selector: 'skuId',
      style: { cursor: 'pointer' },
    },
    {
      name: "Requested Quantity",
      sortable: true,
      selector: 'requestedQty',
      style: { cursor: 'pointer' },
    },
    {
      name: "Approved Quantity",
      sortable: true,
      selector: 'approvedQty',
      style: { cursor: 'pointer' },
    },
    {
      name: "Received Quantity",
      sortable: true,
      selector: 'receivedQty',
      style: { cursor: 'pointer' },
    },
    {
      name: "Request Status",
      sortable: true,
      selector: row => row.requestedItemStates[0].requestedItemStatusString,
      style: { cursor: 'pointer', },
    },


  ]
  const handlePrint = () => {
    const doc = new jsPDF();
    const tableColumn = columnsPDF.map(col => col.name);
    const tableRows = tabledata.map(row => [
      row.productId,
      row.skuId,
      row.requestedQty,
      row.approvedQty,
      row.receivedQty,
      row.requestedItemStates[0].requestedItemStatusString,
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: 'grid',
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 20 },
        2: { cellWidth: 20 },
        3: { cellWidth: 20 },
        4: { cellWidth: 20 },
        5: { cellWidth: 20 },
        6: { cellWidth: 20 },
        7: { cellWidth: 20 },
      },
    });

    doc.save('selected_products.pdf');
  };

  const formatStatusString = (str) => {
    return str ? str.replace(/_/g, ' ') : '';
  };
  const splitSring = str => {
    let parts = "-"
    if (str) {
      parts = str.split(" : ")
    }

    return parts[1] ? parts[1] : "-";
  };
  return (

    <>
      <Header />
      <Container className={golbalClasses.flex}>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            className={golbalClasses.dashbordBtn}
            onClick={handlePrint}
          >
            <Icon icon="gridicons:print" style={{ color: "white" }} />  {t('Print')}
          </Button>
          <Button className={golbalClasses.dashbordBtn} onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
        <Box container className={classes.container}>
          <Box className={classes.flexRow}>
            <Box
              item
              className={
                classes.heading
              }>
              <Typography
                variant="h6"
                className={
                  classes.textWhite
                }>
                {t('Replenish Request Details  ')}
              </Typography>
            </Box>

          </Box>
          <Box className={classes.form} style={{ height: "537px" }}>
            <Grid container spacing={1} mt={1} className={classes.section}>
              <Grid container item xs={12} md={6}>
              <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Order  ID

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getReplenishRequest) ? data.getReplenishRequest._id : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Central Store Name

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getReplenishRequest) ? data.getReplenishRequest.centralStoreName : null}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Local Store  Number

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getReplenishRequest) ? data.getReplenishRequest.localStoreName : "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Requested By

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getReplenishRequest) ? data.getReplenishRequest.requestedByEmail : "N/A"}
                  </Typography>
                </Grid>
                

                <Grid item xs={6} md={6}  >
                  <Typography className={classes.typographyOrder}  >
                    Requested Date

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getReplenishRequest) ? formatDate(data.getReplenishRequest.requestedDate) : "N/A"}

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Order Date

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getReplenishRequest) ? formatDate(data.getReplenishRequest.orderDate) : "N/A"}

                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}  >
                  <Typography className={classes.typographyOrder}  >
                    Receive Date

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getReplenishRequest) ? data.getReplenishRequest.receivedDate : "N/A"}

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} > <Typography className={classes.typographyOrder}>
                  Requested Status
                </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {(data && data.getReplenishRequest) ? formatStatusString(data.getReplenishRequest.statusString) : "N/A"}

                  </Typography>

                </Grid>

              </Grid>

              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4, // Increase thickness
                    // borderColor: 'blue' // Change color
                  }} />
              </Grid>
              <Grid item xs={12} md={12}>
                  <Typography className={classes.typographyOrder} >
                   <b>Request Items</b> 
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>

                  <Typography className={classes.typographyOrder} >
                    Approved Count : {data && data.getReplenishRequest ? data.getReplenishRequest.itemCount.approvedCount : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>

                  <Typography className={classes.typographyOrder} >
                    Received Count : {data && data.getReplenishRequest ? data.getReplenishRequest.itemCount.receivedCount : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>

                  <Typography className={classes.typographyOrder} >
                    Requested Count :  {data && data.getReplenishRequest ? data.getReplenishRequest.itemCount.requestedCount : "N/A"}
                  </Typography>
                </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4, // Increase thickness
                    // borderColor: 'blue' // Change color
                  }} />
              </Grid>
              <Grid item xs={12} md={12} > <Typography className={classes.typographyOrder}>
                 <b>Comments</b> 
                </Typography>
                </Grid>


                {data && data.getReplenishRequest.comments[0] ? <>
                  <Grid item xs={12} md={12} > <Typography className={classes.typographyOrder} >
                    Requester :  {data && data.getReplenishRequest.comments ? splitSring(data && data.getReplenishRequest.comments[0]) : "N/A"}

                  </Typography>
                  </Grid>
                </> : null}

                {data && data.getReplenishRequest.comments[1] ? <>
                  <Grid item xs={12} md={12} > <Typography className={classes.typographyOrder} >
                    Approver :  {data && data.getReplenishRequest.comments ? splitSring(data && data.getReplenishRequest.comments[1]) : "N/A"}

                  </Typography>
                  </Grid>
                </> : null}

                {data && data.getReplenishRequest.comments[2] ? <>
                  <Grid item xs={12} md={12} > <Typography className={classes.typographyOrder} >
                    Receiver :  {data && data.getReplenishRequest.comments ? splitSring(data && data.getReplenishRequest.comments[2]) : "N/A"}

                  </Typography>
                  </Grid>
                </> : null}
            </Grid>

          </Box>

        </Box>
        <DataTable
          subHeader={true}

          title={<TableHeader title={t('Requested Items')} />}
          columns={columns}
          data={tabledata || []}
          pagination
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          // sortFunction={customSort}
          // defaultSortField="email"
          customStyles={customStyles}
        // selectableRows
        />
        <Grid container >
          <Grid item xs={6} md={6}>

          </Grid>

        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          {data && data.getReplenishRequest.approvedBy ?
            <Button
              disabled
              onClick={handleOpenCommentModal}
              className={golbalClasses.dashbordBtn}
            >
              Approve
            </Button> : <Button

              onClick={handleOpenCommentModal}
              className={golbalClasses.dashbordBtn}
            >
              Approve
            </Button>}

        </Box>

      </Container>

      <Dialog
        open={openCommentModal}
        onClose={handleCloseCommentModal}
      >
        <DialogTitle>  <Box className={classes.heading}>
          <Typography className={classes.textWhite}>
            {t('Write Your   Comment')}
          </Typography>
        </Box></DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            multiline
            rows={4}
            inputProps={{ style: inputTextStyle }}
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary" disabled={!comment || mutationLoading} className={golbalClasses.dashbordBtn} >
            {t('Confirm')}
          </Button>
          <Button onClick={handleCloseCommentModal} color="primary" className={golbalClasses.modalCancleBtn} >
            {t('Cancel')}
          </Button>

        </DialogActions>

        <Box mt={2}>
          {success && (
            <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                {success}
              </Alert>
            </Snackbar>
          )}
          {error && (
            <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                {error}
              </Alert>
            </Snackbar>
          )}
        </Box>
      </Dialog>

    </>
  );
};

export default withTranslation()(CSAdminViewDetailsRepllenation);
