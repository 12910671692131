/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useQuery, gql, useApolloClient, useLazyQuery } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import CustomLoader from '../components/Loader/CustomLoader'
// core components
import Header from '../components/Headers/Header'
import { gettallcustomers, GetAllCustomerTypes } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import {
  Container, Button, useTheme,
  Box,
  Icon,

} from '@mui/material'
import { customStyles } from '../utils/tableCustomStyles'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'

const GET_CUSTOMER_TYPE = gql`
  ${GetAllCustomerTypes}
`;
const GET_ALL_CUSTOMERS = gql`
  ${gettallcustomers}
`

const AllCustomerList = props => {
  const { t } = props;
  const globalClasses = useGlobalStyles()
  const [data, setData] = useState(null); // Initialize data state as null
  const client = useApolloClient();
  const [page, setPage] = useState(
    1
  );
  const [currentPage, setCurrentPage] = useState(page);
  var [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [csvData, setCsvData] = useState([]);
  const variables = {
    limit: perPage,
    skip: (page - 1) * perPage,
  };
  const { data: workerStatus } = useQuery(GET_CUSTOMER_TYPE, {
    onError: (error) => {
      const isForbiddenError = error && error.graphQLErrors.some((err) => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });
  const optionsworkerStatus = workerStatus
    ? workerStatus.getAllCustomerTypes.map((type) => ({
      label: type.name,
      customerType: type.customerType
    }))
    : [];
  const getCustomerTypeName = (customerTypeId) => {
    const customerType = optionsworkerStatus.find(type => type.customerType === customerTypeId);
    return customerType ? customerType.label : 'N/A';
  };


  const { loading: loadingQuery, refetch, } = useQuery(GET_ALL_CUSTOMERS, {
    fetchPolicy: 'network-only',
    variables,
    onCompleted: (data, errors) => {
      // Set data state when query completes
      setData(data && data.getCustomersForCentralStore ? data.getCustomersForCentralStore.customers : []); // Check if data exists before setting state
      setTotalRows(data.getCustomersForCentralStore.totalCount)

    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  });


  const [refetchCustomerDataForCSV] = useLazyQuery(GET_ALL_CUSTOMERS, {
    fetchPolicy: 'network-only',

    onCompleted: (data, errors) => {
      // Set data state when query completes
      let dataCvs = data && data.getCustomersForCentralStore ? data.getCustomersForCentralStore.customers : []
      setCsvData(data && data.getCustomersForCentralStore ? data.getCustomersForCentralStore.customers : []); // Check if data exists before setting state
      setTotalRows(data.getCustomersForCentralStore.totalCount)
      downloadCSV(dataCvs)
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  });
  const handleFetchStatsCVS = () => {

    refetchCustomerDataForCSV({
      variables: {

        limit: totalRows ? totalRows : 100000, // Fetch all rows
        skip: 0,
      }
    });

  };
  useEffect(() => {
    if (data && data.getCustomersForCentralStore) {
      setTotalRows(data.getCustomersForCentralStore.totalCount);
    }
  }, [data]);
  // Update the query variables when the page value changes
  useEffect(() => {
    refetch({
      variables: {
        // storeId: userDetails.storeId,
        limit: perPage,
        skip: (page - 1) * perPage,
        // skip: page
      },
    });
  }, [page, perPage, refetch]);


  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)

  };

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }
      return row[field]
    }
    return orderBy(rows, handleField, direction)
  }


  const columns = [
    {
      name: 'Serial Number',
      cell: (row, index) => <>{index + 1}</>,
      style: { cursor: 'pointer' },
    },
    {
      name: 'Customer Name',
      selector: 'name', // Changed from 'customerName' to 'name'
      style: { cursor: 'pointer' },
    },
    {
      name: 'Customer Type', // Added 'Phone Number' column
      cell: (row) => <>{getCustomerTypeName(row.customerType)}</>,// Added 'phoneNumber' selector
      style: { cursor: 'pointer' },
    },
    {
      name: 'Order Count', // Changed from 'Order Date' to 'Order Count'
      selector: 'orderCount', // Changed from 'orderDate' to 'orderCount'
      cell: (row) => <>{row.orderCount ? row.orderCount : 0}</>,// Added 'phoneNumber' selector
     
      style: { cursor: 'pointer' },
    },
    {
      name: 'Phone Number', // Added 'Phone Number' column
      selector: 'phoneNumber', // Added 'phoneNumber' selector
      style: { cursor: 'pointer' },
    },
    {
      name: 'Delivery Address', // Added 'Delivery Address' column
      cell: (row) =>
        row.addresses && row.addresses.length > 0 ? (
          <>
          {row.addresses[0].details} ,
            {row.addresses[0].deliveryAddress}
          
            
          </>
        ) : (
          ''
        ),
      style: { cursor: 'pointer', minWidth: "300px", textAlign: 'left' },
    },

    {
      name: t('Action'),
      cell: (row) => <>{actionButtons(row)}</>,
    },
  ];

  const theme = useTheme();

  const downloadCSV = (tableDataCVS) => {
    if (!Array.isArray(tableDataCVS)) {
      return;
    }

    const csvRows = [];

    // Add the date range to the CSV
    csvRows.push('');

    // Define the CSV headers
    const headers = ['Customer Name', 'Customer Type', 'Phone Number','Order Count'];
    csvRows.push(headers.join(','));

    tableDataCVS.forEach((row, index) => {
      const rowData = [
        row.name,
        getCustomerTypeName(row.customerType),
        row.phoneNumber,
        row.orderCount ?row.orderCount : 0 ,
      ];
      csvRows.push(rowData.join(',')); // Add row data
    });

    // Creating the CSV Blob and triggering the download
    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'customer_details.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const actionButtons = row => {

    return (
      <>
        <Button
          size="20px"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.common.white,
            padding: 0,
            height: '15px',
            fontSize: '7px',
            '&:hover': {
              border: '2px solid #637BFE',
              color: theme.palette.warning.dark,
              backgroundColor: "white"
            }
          }}
          onClick={e => {
            e.preventDefault()
            const customerTypeLabel = getCustomerTypeName(row.customerType)
            localStorage.setItem('customerTypeLabel', JSON.stringify(customerTypeLabel))
            localStorage.setItem('customerDetails', JSON.stringify(row))
            const userType = JSON.parse(localStorage.getItem('user-native')).userType; // Assuming you have a function to get the user type
            let routePath = '';
            switch (userType) {
              case 0:
                routePath = '/super_admin/customerdetails';
                break;
              case 1:
                routePath = '/cs_admin/customerdetails';
                break;
              case 3:
                routePath = '/local_admin/customerdetails';
                break;
              default:
                routePath = '/cs_admin/customerdetails'; // Default to super admin if userType is not defined
                break;
            }
            props.history.push({
              pathname: routePath,
              state: { data: row }
            });
          }}
        >
          {t('ViewDetails')}
        </Button>
      </>
    )
  }
  return (
    <>
      <Header />

      <Container className={globalClasses.flex} fluid>
        <Box sx={{ textAlign: 'right', mb: 3 }}>

          <Button
            onClick={handleFetchStatsCVS}
            className={globalClasses.dashbordBtn}>
            <Icon icon="ic:round-download" style={{ color: "white" }} /> {t('Download CSV')}
          </Button>

        </Box>
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            customStyles={customStyles}
            subHeader={true}

            title={<TableHeader title='All Customers' />}
            columns={columns}
            data={data || []}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            sortFunction={customSort}
          />
        )}
      </Container>
    </>
  )
}
export default withTranslation()(AllCustomerList)
