

import React, { useEffect, useState } from 'react';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { GetAllCustomerTypes, getskuforprodict } from '../../apollo';
import { Button, Box, Typography, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import useGlobalStyles from '../../utils/globalStyles';
import CustomLoader from '../../components/Loader/CustomLoader'
import DataTable from 'react-data-table-component'
import { customStyles } from '../../utils/tableStyleForSKU'
import {
  Container,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Paper,

} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../../components/TableHeader'
import AddSku from './AddSku';
import EditProductQuantityCS from '../AddProduct/EditProductQuantityCS';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const GET_ALL_CUSTOMER_TYPES = gql`
  ${GetAllCustomerTypes}
`

const GET_SKU_FOR_PRODUCTS = gql`
  ${getskuforprodict}
`
function CSSku(props) {
  const { t } = props;
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const [skuModal, setSkuModal] = useState(false)
  const productDetails = JSON.parse(localStorage.getItem('skuRow'));
  const userDetailsStr = localStorage.getItem('user-native');
  const userDetails = JSON.parse(userDetailsStr);
  const golbalClasses = useGlobalStyles()
  const client = useApolloClient();
  const [tabledata, setTabledata] = useState(null);
  const history = useHistory();
  const [customerTypesMap, setCustomerTypesMap] = useState({});
  const [selectedRowPrices, setSelectedRowPrices] = useState([]);


  const variables = {
    storeId: userDetails.storeId,
    productId: productDetails._id,
  };

  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_SKU_FOR_PRODUCTS, {
    variables,
    onCompleted: (data, errors) => {
      setTabledata(data && data.getSkusForProduct ? data.getSkusForProduct : [])
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  )

  const { data: customerTypeResponse, loading: customerTypeLoading } = useQuery(GET_ALL_CUSTOMER_TYPES, {
    onCompleted: (data) => {
      const customerTypeMap = {};
      data.getAllCustomerTypes.forEach((type) => {
        customerTypeMap[type.customerType] = type.name;
      });
      setCustomerTypesMap(customerTypeMap);
    },
  });
  const getCustomerTypePrices = (row) => {
    return row.prices.map(price => ({
      customerType: customerTypesMap[price.customerType], // Get the name of the customer type from the map
      sellingPrice: price.sellingPrice,// The price for this customer type,
      id: price.customerType
    }));
  };
  const columns = [
    {
      name: 'SKU ID',
      selector: '_id', // Use '_id' directly from the SKU object
      sortable: true,
    },
    {
      name: 'Barcode',
      selector: 'barcode', // Use 'barcode' directly from the SKU object
      sortable: true,
    },
    {
      name: 'MRP',
      selector: 'mrp', // Use 'mrp' directly from the SKU object
      sortable: true,
    },

    // {
    //   name: 'Normal Selling Price',
    //   cell: row => {
    //     const normalPrice = row.prices.find(price => price.customerType === 0);
    //     return normalPrice ? normalPrice.sellingPrice : 'N/A';
    //   },
    //   sortable: true,
    //   style: { cursor: 'pointer', minWidth: "150px" },
    // },
    // {
    //   name: 'Premium Customer Price',
    //   cell: row => {
    //     const premiumPrice = row.prices.find(price => price.customerType === 1);
    //     return premiumPrice ? premiumPrice.sellingPrice : 'N/A';
    //   },
    //   sortable: true,
    //   style: { cursor: 'pointer', minWidth: "200px" },
    // },
    // Dynamically add customerType columns
    ...Object.keys(customerTypesMap).map((customerType) => ({
      name: `${customerTypesMap[customerType]} Price`,
      selector: (row) => {
        const priceInfo = row.prices.find(price => price.customerType === parseInt(customerType));
        return priceInfo ? priceInfo.sellingPrice : 'N/A';
      },
      sortable: true,
      // style: { cursor: 'pointer', minWidth: "200px" }
    })),
    {
      name: 'Stock Quantity',
      cell: row => row.Stock.map(stock => stock.quantity).join(', '), // Combine all stock quantities into a string
      sortable: true,
    },

    {
      name: 'Unit',
      selector: 'unit', // Use 'unit' directly from the SKU object
      sortable: true,
    },
    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>, // You can keep the 'actionButtons' cell as it is
    },
  ];
  useEffect(() => {
    refetch(); // Refetch data when dependencies change
  }, [data]); // Include data in dependencies array

  const toggleModal = vendor => {
    setEditModal(!editModal)
    const customerTypePrices = getCustomerTypePrices(vendor);
    setSelectedRowPrices(customerTypePrices);
    setVendor(vendor)
  }
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };
  const closeSkuModal = () => {
    setSkuModal(false)
    // setAddVendorModal(false);
  };

  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };

  const handleBackClick = () => {
    history.push('/cs_admin/products'); // Navigate back to ManageCategory component
  };
  const toggleModalSKU = vendor => {
    setSkuModal(!editModal)
    setVendor(vendor)
  }
  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                  // uncomment this for paud version
                  toggleModalSKU(row);
                  localStorage.setItem('skuRow', JSON.stringify(row));
                  // history.push('/cs_admin/sku'); // Navigate to the SKU route
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'black' }} />
                </ListItemIcon>
                <Typography color="black">{t('Manage Quantity')}</Typography>
              </MenuItem>

              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                  // uncomment this for paud version
                  toggleModal(row);

                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>


              <MenuItem
                disabled
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  return (
    <>

      <Container className={golbalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={openaAddVendorModal}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('Add New SKU ')}
          </Button>
          <Button className={golbalClasses.dashbordBtn} onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
        {addVendorModal ? <Grid container>
          <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
            <Grid
              sx={{ display: { xs: 'none', lg: 'block' } }}
              item
              mt={5}
              ml={-2}
              order={{ xs: 1, lg: 2 }}
            >
              <Modal
                open={addVendorModal}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClose={closeAddVendorModal}
              >
                <AddSku onClose={closeAddVendorModal} refetch={refetch} productId={productDetails._id} />
              </Modal>
            </Grid>
          </Grid>
        </Grid>
          :
          null
        }
        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}

            title={<TableHeader title={t('SKU List ')} />}
            columns={columns}
            data={tabledata || []}
            pagination
            customStyles={customStyles}
          // selectableRows
          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <AddSku vendor={vendors} onClose={closeEditModal} refetch={refetch} customerTypePrices={selectedRowPrices} />
        </Modal>
        <Modal
          open={skuModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <EditProductQuantityCS vendor={vendors} onClose={closeSkuModal} refetch={refetch} />
        </Modal>

      </Container>

    </>
  );
}
export default withTranslation()(CSSku);
